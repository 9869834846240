<script setup>
import ActivitiesList from "@/components/activity/ActivitiesList.vue";
import ActivitiesListModal from "@/components/activity/ActivitiesListModal.vue";
import {ref} from "vue";
import InteractionEditModal from "@/components/interactions/InteractionEditModal.vue";
import {getNoun} from "@/helpers/string";
import {useStore} from "vue2-helpers/vuex";

const {latestActivities, lastVisits = 0} = defineProps(['latestActivities','lastVisits']);
const emit = defineEmits(['activitiesChanged']);

const store = useStore();
const activitiesListModal = ref(null);
const interactionEditModal = ref(null);

function activitiesChangedInline() {
    emit('activitiesChanged');
    activitiesListModal.value.updateActivities();
}

function activitiesChangedFromModal() {
    emit('activitiesChanged');
}
</script>

<template>
    <div class="main-startup__tabs__item">
        <template v-if="store.getters.isEnterprise">
            <header class="main-startup__tabs__header">
                <h4 class="heading4 heading4--tertiary">Latest Interactions</h4>

                <div class="main-startup__tabs__header__side">
                    
                    <div class="main-startup__tabs__header__side__inner">
                        <a class="main-startup__tabs__header__side__log" @click="interactionEditModal.openAdd()">Log Interaction</a>
                        <a class="main-startup__tabs__header__side__activity" @click="activitiesListModal.open()">All Activity</a>
                    </div>
                </div>
            </header>

            <div v-if="lastVisits" class="main-startup__tabs__top flex-row">
                <p>{{lastVisits[0]}} {{getNoun('visit', lastVisits[0])}} {{lastVisits[1]}}</p>

                <a class="link link--view" @click="activitiesListModal.open('views')">View</a>
            </div>

            <ActivitiesList
                v-if="latestActivities"
                :activities="latestActivities"
                :is-preview="true"
                @activitiesChanged="activitiesChangedInline"
            />

            <InteractionEditModal
                ref="interactionEditModal"
                @saved="activitiesChangedInline"
            />
        </template>

        <ActivitiesListModal
            ref="activitiesListModal"
            @activitiesChanged="activitiesChangedFromModal"
        />
    </div>
</template>

<style scoped>

</style>
