<script setup>
import {ref, inject} from "vue";
import useGrid from "@/composables/useGrid";
import TablePager from "@/components/TablePager.vue";
import useCurrentPageData from "@/composables/useCurrentPageData";
import {getLogo} from "@/helpers/logo";
import constants from "../../constants/constants";
import VueClamp from 'vue-clamp';

const {gridParams, gridMethods} = useGrid(getEcosystems, 5);
const getPageItems = useCurrentPageData();
const ecosystems = ref([]);
const showModal = ref(false);
const ecosystemsLoaded = ref(false);
const getEcosystemsIndexList = inject('getEcosystemsIndexList');

async function getEcosystems() {
    ecosystems.value = await getPageItems(getEcosystemsIndexList, gridParams);
    ecosystemsLoaded.value = true;
}

function open() {
    if (!ecosystemsLoaded.value) {
        gridParams.currentPage = 1;
        getEcosystems();
    }

    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <div>
        <b-modal v-model="showModal" title="All Ecosystems" hide-footer modal-class="modal-ecosystems modal-w-xxl">

            <ul class="list-bordered list-bordered--complex">
                <li v-for="ecosystem in ecosystems" :key="ecosystem.id">
                    <div class="list-bordered__inner">
                        <div class="image-block">
                            <img :src="getLogo(ecosystem.shortLogoURL)" :alt="ecosystem.name"/>
                        </div>

                        <div class="list-bordered__side">
                            <header class="list-bordered__header">
                                <a class="list-bordered__header__name" :href="`/ecosystems/${ecosystem.id}`" target="_blank">{{ecosystem.name}}</a>

                                <div v-if="ecosystem.type" class="button-light button-light--ecosystem">
                                    {{constants.ecosystemTypes[ecosystem.type]}}
                                </div>

                                <div v-if="ecosystem.location" class="button-light button-light--location">
                                    {{ecosystem.location}}
                                </div>
                            </header>

                            <VueClamp :max-lines="2">
                                {{ecosystem.description}}

                                <template #after="{ toggle, expanded, clamped }">
                                    <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                    <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                </template>
                            </VueClamp>
                        </div>
                    </div>
                </li>
            </ul>

            <TablePager
                v-if="ecosystems.length"
                :pages-count="gridParams.pagesCount"
                :init-page="gridParams.currentPage"
                @changePage="gridMethods.changePage"
            />
        </b-modal>
    </div>
</template>

<style scoped>

</style>
