<template>
    <div>
        <div class="tracker-section tracker-section--inline pr-5">
            <h2 class="tracker-name heading2 w-100 mb-3">
                Trackers
            </h2>
            
            <input class="form-search-primary mb-0" v-model="search" v-debounce:500="updateSearch" placeholder="Search for a folder or tracker" type="text"/>
            
            <div class="topbar__key">
                <span class="topbar__key__label">Filter by:</span>

                <b-dropdown class="dropdown-link dropdown-blue" :text="filterTypes1[filterType1]" boundary="viewport">
                    <b-dropdown-item v-for="(label, value) in filterTypes1" :key="value" @click="setFilterType1(value)">
                        <span>{{label}}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </div>

            <b-dropdown v-if="userCanAddTracker" class="dropdown-folder" text="New Folder" boundary="viewport">
                <b-dropdown-item @click="$refs.editFolder.open(0, 'private')">
                    Internal Folder
                </b-dropdown-item>

                <b-dropdown-item v-if="$store.getters.isPremiumEnterprise" @click="$refs.editFolder.open(0, 'public')">
                    Public Collection
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <div class="main-inner main-inner--scroll">
            <template v-if="hasFolders">

                <header class="main-inner__header">
                    <h2 class="heading2">Trackers by Folder</h2>
                    
                    <p class="main-inner__header__info" v-if="foldersStatus === 'active'">
                        Showing {{ foldersCount.active }} active {{stringHelper.getNoun('folder', foldersCount.active)}} <a v-if="foldersTotalCount.archived > 0" @click="foldersStatus = 'archived'" class="link-inherit ml-3">Show archived folders</a>
                    </p>

                    <p class="main-inner__header__info" v-if="foldersStatus === 'archived'">
                        Showing {{ foldersCount.archived }} archived {{stringHelper.getNoun('folder', foldersCount.archived)}} <a v-if="foldersTotalCount.active > 0" @click="foldersStatus = 'active'" class="link-inherit ml-3">Show active folders</a>
                    </p>
                        
                </header>

                <b-row class="main-inner__content">
                    <b-col md="6" lg="4" v-for="folder in folders[foldersStatus]" :key="folder.id">

                        <div class="card card--bordered">
                            <header class="card__header">
                                <h4 class="heading4 heading4--secondary"><router-link :to="'/trackers/folder/' + folder.id">{{ folder.name }}</router-link></h4>

                                <b-dropdown v-if="arrayHelper.inArray(folder.user_permissions, 'tracker-folder-manage')" class="dropdown-original icons-list dropdown-small ml-auto" boundary="viewport">
                                    <b-dropdown-item class="icons-list__edit" @click="$refs.editFolder.open(folder.id)">
                                        <!--<font-awesome-icon :icon="['fa', 'tags']" />-->
                                        Edit Folder
                                    </b-dropdown-item>

                                    <b-dropdown-item class="icons-list__delete" @click="removeFolder(folder.id)">
                                        <font-awesome-icon :icon="['fa', 'trash']" />
                                        Delete
                                    </b-dropdown-item>

                                    <b-dropdown-item class="icons-list__archive" v-if="!folder.archived" @click="archiveFolder(folder.id)">
                                        Archive
                                    </b-dropdown-item>

                                    <b-dropdown-item class="icons-list__archive" v-else @click="unarchiveFolder(folder.id)">
                                        Unarchive
                                    </b-dropdown-item>

                                </b-dropdown>
                            </header>

                            <div class="card__collapse">
                                <header class="card__collapse__header">
                                    <h5 class="heading5 heading5--secondary">
                                        <template v-if="folder.type === trackerTypes.PUBLIC">
                                            <span class="heading5__folder heading5__folder--public">Public Collection</span>
                                        </template>
                                        <template v-else>
                                            <span class="heading5__folder">Folder</span>
                                        </template>
                                            &bull; {{folder.trackers_count}} {{stringHelper.getNoun('tracker', folder.trackers_count)}}
                                    </h5>
                                    <b-button v-if="folder.permissions.length || folder.description.length" v-b-toggle="'folder-details-' + folder.id" class="card__collapse__button">Details</b-button>
                                </header>

                                <b-collapse v-if="folder.permissions.length || folder.description.length" :id="'folder-details-' + folder.id">
                                    <p>{{ folder.description }}</p>
                                    <b-dropdown class="dropdown--secondary" variant="link" toggle-class="text-decoration-none" no-caret>
                                        <template #button-content>
                                            <div class="section-users section-users--limited">
                                                <div class="section-users__images">
                                                    <div v-for="(avatar, index) in stringHelper.getAvatarsForDisplay(folder.permissions)" :key="index" class="section-users__images__item">
                                                        <img :src="avatar" alt=""/>
                                                    </div>
                                                </div>

                                                <p>
                                                    <span class="section-users__data">
                                                        <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(folder.permissions)" :key="index">
                                                            {{item}}
                                                        </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </template>

                                        <b-dropdown-header>
                                            <header class="dropdown__header-inner">
                                                <h4 class="heading4">Users in {{ folder.name }}</h4>

                                                <a v-if="false" href="#" class="link link--lg">Edit</a>
                                            </header>

                                            <ul class="rows-list">
                                                <li  v-for="(permission, index) in folder.permissions" :key="index">
                                                    <div class="rows-list__avatar">
                                                        <Avatar
                                                            :username="permission.name"
                                                            :src="permission.avatar"
                                                            :size="30"
                                                        />
                                                    </div>

                                                    <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                                                    <!-- Please add here the logic first -->
                                                    <p class="ml-auto">{{ permission.role.name }}</p>
                                                    <!--<a class="link-info">
                                                        <span class="link-info__content">{{ permission.role.description }}</span>
                                                    </a>-->
                                                    <a class="link-info" v-b-popover.hover.top="permission.role.description" title="" hideArrow={true}></a>
                                                </li>
                                            </ul>
                                        </b-dropdown-header>
                                    </b-dropdown>
                                </b-collapse>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </template>

            <TrackersList
                ref="trackersList"
                :filter-type1="filterType1"
                :folder-id="0"
                :search="search"
                :user-can-add-tracker="userCanAddTracker"
                @add="$refs.editTracker.open(0)"
                @edit="(id) => $refs.editTracker.open(id)"
                @duplicate="({id, name, type}) => $refs.duplicateTracker.open(id, name, type)"
                @setFavorite="setFavorite"
                @remove="removeTracker"
                @archive="archiveTracker"
                @unarchive="unarchiveTracker"
                @addToFolder="selectFolder"
            />
        </div>

        <edit-folder
            ref="editFolder"
            v-on:folderSaved="getData"
        >

        </edit-folder>

        <TrackerEdit
            ref="editTracker"
            :folder-id="0"
            @trackerSaved="getData(); fetchTrackers(true)"
        />

        <duplicate-tracker
            ref="duplicateTracker"
            v-on:trackerSaved="getData"
        >
        </duplicate-tracker>

        <select-folder
            ref="selectFolder"
            v-on:folderSelected="addToFolder"
        >
        </select-folder>

        <helper v-if="$store.getters.isEnterprise"
            page="trackers"
        />
    </div>
</template>

<script>
import trackers from "@/services/trackers.service"
import trackersFolders from "@/services/trackers-folders.service"
import user from "@/services/user.service"
import editFolder from "@/components/trackers/EditFolder"
import selectFolder from "@/components/manage/SelectTrackerFolder"
import Avatar from "vue-avatar";
import Vue from "vue";
import DuplicateTracker from "@/components/trackers/DuplicateTracker.vue";
import {mapActions} from "vuex";
import TrackersList from "@/components/trackers/TrackersList";
import {trackerTypes} from "@/constants/trackers";
import Helper from "@/components/Helper.vue";
import TrackerEdit from "@/components/trackers/trackerEdit/TrackerEdit.vue";

export default {
    data() {
        return {
            folders: {
                active: [],
                archived: [],
            },

            foldersCount: {
                active: 0,
                archived: 0,
            },

            foldersTotalCount:{
                active: 0,
                archived: 0,
            },

            foldersLoaded: false,

            foldersStatus: 'active',

            filterType1: "all",

            filterTypes1: {
                'all': 'All Trackers',
                'created-by-me': 'Created By Me',
                'related-to-me': 'Related To Me',
            },

            search: "",
            currentFolder: 0,
            currentTracker: null,
            updateForAdmin: false,
        }
    },

    components: {
        TrackerEdit,
        Helper,
        editFolder,
        Avatar,
        selectFolder,
        DuplicateTracker,
        TrackersList,
    },

    mounted() {
        this.$store.watch(state => {
            if (state.user.settings.trackers_filter) {
                this.filterType1 = state.user.settings.trackers_filter
            }
            else if (state.user.is_admin) {
                this.filterType1 = "related-to-me"
            }

            if (state.needUpdateTrackers) {
                this.getTrackersList()
            }
        })

        this.getData()

        if (this.$route.query.create_tracker) {
            this.$store.watch(
                state => state.user.permissions,
            (permissions) => {
                if (permissions.includes('tracker-create')) {
                    this.$refs.editTracker.open(0)
                }
            })
        }
    },

    watch: {
        filterType1() {
            this.getFoldersList()
        },
    },

    computed: {
        trackerTypes() {
            return trackerTypes
        },

        filterType1Text() {
            return this.filterTypes1.find(item => item.value === this.filterType1).label;
        },

        filterType2Text() {
            let text = ''

            if (this.filterType1 !== 'all') {

                if (this.filterType2 !== 'all') {
                    text +=  this.filterTypes2[this.filterType2]
                } else {
                    text +=  'Trackers '
                }

                text += ' ' + this.filterTypes1[this.filterType1]
            }
            else {
                text += this.filterTypes2[this.filterType2]
            }

            return text
        },

        userCanAddTracker() {
            return this.$store.state.user.permissions.includes('tracker-create')
        },

        hasFolders() {
            return this.foldersTotalCount.active > 0 || this.foldersTotalCount.archived > 0
        }
    },

    methods: {
        ...mapActions(['fetchTrackers']),

        getData() {
           this.getNumbers()
        },

        updateSearch() {
            this.getFoldersList()
            this.getTrackersList()
        },

        getNumbers() {
            trackersFolders.getNumber().then(response => {
                this.foldersTotalCount = response.data

                if (this.hasFolders) {
                    if (this.foldersTotalCount.active === 0 && this.foldersTotalCount.archived > 0) {
                        this.foldersStatus = 'archived'
                    }

                    this.getFoldersList()
                }
                else {
                    this.foldersLoaded = true
                    this.foldersCount.active = 0
                    this.foldersCount.archived = 0
                }
            })

            this.$refs.trackersList.getTrackersNumber()
        },

        getFoldersList() {
            if (this.hasFolders) {
                trackersFolders.getIndex(this.filterType1, this.search).then(response => {
                    this.folders = response.data.folders
                    this.foldersLoaded = true

                    this.foldersCount = response.data.count
                })
            }
        },

        getTrackersList() {
            this.$refs.trackersList.getTrackersList()
        },

        removeTracker(id) {
            if (confirm("Delete tracker?")) {
                trackers.delete(id).then(() => {
                    this.getData()
                    this.fetchTrackers(true)
                })
            }
        },

        archiveTracker(id) {
            trackers.update(id, {status: 'archive'}).then(() => {
                this.getData()
            })
        },

        unarchiveTracker(id) {
            trackers.update(id, {status: 'draft'}).then(() => {
                this.getData()
            })
        },

        removeFolder(id) {
            if (confirm("Delete folder and all its trackers?")) {
                trackersFolders.delete(id).then(() => {
                    this.getData()
                })
            }
        },

        archiveFolder(id) {
            if (confirm("Archive folder and all it's trackers?")) {
                trackersFolders.archive(id).then(() => {
                    this.getData()
                })
            }
        },

        unarchiveFolder(id) {
            if (confirm("Unarchive folder and all it's trackers?")) {
                trackersFolders.unarchive(id).then(() => {
                    this.getData()
                })
            }
        },

        addToFolder(folder) {
            if (this.currentTracker.folder && !confirm(`This tracker already associated with folder "${this.currentTracker.folder.name}". Would you like to move tracker to "${folder.name}"?`)) {
                return;
            }

            trackersFolders.addTrackers(folder.id, [this.currentTracker]).then(() => {
                this.currentTracker = 0
                this.getTrackersList()
                this.getFoldersList()

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: "Tracker added to " + folder.name + " folder."
                })
            })
        },

        setFavorite({id, favorite}) {
            trackers.setFavorite(id, favorite).then(() => {
                this.getTrackersList()
            })
        },

        setFilterType1(filterType) {
            this.filterType1 = filterType
            user.saveTrackerFilter(this.filterType1).then(response => {
                this.$store.commit('setUser', response.data)
            })
        },

        selectFolder({tracker, type}) {
            this.currentTracker = tracker;

            if (type === 'public') {
                type = '';
            } else {
                type = 'private';
            }

            this.$refs.selectFolder.open(type, tracker.folder)
        }
    }
}
</script>

<style scoped>
    .link-info {
        padding: 0;
        background-color: transparent !important;
        border: none;
    }

    .popover {
        top: 50px !important;
        left: auto !important;
        margin: auto !important;
        max-width: 160px !important;
        padding: 5px 10px;
        background: #fff;
        border-radius: 4px;
        color: #7b7b7b !important;
        filter: drop-shadow(1px 3px 4px rgba(#000, 0.3));
        box-shadow: none !important;
        font-size: 10px !important;
        font-weight: 400;
        line-height: 1.5;
    }
    .popover.bs-popover-bottom,
	.popover.bs-popover-top {
		margin-left: 0 !important;
        left: 0 !important;
	}
    .popover.bs-popover-bottom .arrow {
        display: none !important;
        opacity: 0;
        visibility: hidden !important;
    }
    div.popover.b-popover .arrow {display: none !important; visibility:hidden !important;}
</style>
