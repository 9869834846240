export const activitiesTypes = {
    views: 'Views',
    notes: 'Notes',
    calls: 'Calls',
    demos: 'Demos',
    emails: 'Emails',
    meetings: 'Meetings',
    cb_changes: 'Startup Updates',
    users_activity: 'User Activity',
}

export const interactionTypes = {
    note: 'Note',
    email: 'Email',
    call: 'Call',
    meeting: 'Meeting',
    demo: 'Demo',
}

export const interactionTypesRef = {
    email: 'email',
    call: 'phone_call',
    meeting: 'meeting',
    demo: 'demo',
}
