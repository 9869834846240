<template>
    <div v-if="$store.state.user.permissions.indexOf('team-manage') >= 0 || $store.state.user.admin_groups.length">
        <div class="tracker-section tracker-section--inline">
            <h2 class="tracker-name heading2 w-100 mb-3">
                Team
            </h2>

            <input class="form-search-primary" v-model="search" v-debounce:500="getLists" placeholder="Search for a user or group" type="text"/>
        </div>

        <div class="main-inner">

            <header class="main-inner__header">
                <h2 class="heading2">Groups</h2>

                <a class="link" v-if="$store.state.user.permissions.indexOf('team-manage') >= 0" @click="$refs.editGroup.open(0)">+ New Group</a>
            </header>

            <b-row class="main-inner__content">
                <b-col md="6" lg="4" v-for="group in groups" :key="group.id">

                    <div class="card card--bordered">
                        <header class="card__header">
                            <h4 class="heading4 heading4--secondary"><router-link :to="'/groups/' + group.id">{{ group.name }}</router-link></h4>

                            <b-dropdown class="drag-n-drop icons-list dropdown-simple" boundary="viewport">
                                <b-dropdown-item class="icons-list__edit" @click="$refs.editGroup.open(group.id)">
                                    Edit Group
                                </b-dropdown-item>

                                <b-dropdown-item class="icons-list__delete" v-if="$store.state.user.permissions.indexOf('team-manage') >= 0" @click="deleteGroup(group.id)">
                                    <font-awesome-icon :icon="['fa', 'trash']" />
                                    Remove Group
                                </b-dropdown-item>
                            </b-dropdown>
                        </header>

                        <div class="card__collapse">
                            <h5 class="heading5 heading5--secondary mb-0">{{group.users_count}} {{stringHelper.getNoun('user', group.users_count)}}</h5>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <header class="main-inner__header">
                <h2 class="heading2">Admins</h2>

                <a
                    v-if="$store.state.user.permissions.includes('team-manage') && !$store.getters.settings.disable_add_users"
                    class="link"
                    @click="$refs.editUser.open(0, true)"
                >+ Add Admin</a>
            </header>

            <div class="main-inner__content">
                <ul class="list-contacts list-contacts--full">
                    <li v-for="user in users.admins" :key="user.id">
                        <div class="card card--bordered card--centered">

                            <b-dropdown
                                v-if="$store.state.user.permissions.indexOf('team-manage') >= 0 && !user.is_proxy_admin"
                                class="drag-n-drop icons-list dropdown-simple dropdown-small position-absolute"
                                boundary="viewport"
                            >
                                <b-dropdown-item
                                    class="icons-list__edit"
                                    @click="$refs.editUser.open(user.id, true)"
                                >
                                    Edit User
                                </b-dropdown-item>

                                <b-dropdown-item
                                    v-if="!user.is_main_admin"
                                    class="icons-list__delete2"
                                    @click="deleteUser(user.id)"
                                >
                                    Remove User
                                </b-dropdown-item>

                                <b-dropdown-item
                                    v-if="!user.confirmed"
                                    class="icons-list__invite"
                                    @click="sendActivationEmail(user.id)"
                                >
                                    Re-send Invite
                                </b-dropdown-item>
                            </b-dropdown>

                            <div class="image-block image-block--rounded--lg">
                                <Avatar
                                    :username="user.name"
                                    :src="user.avatar"
                                    :size="75"
                                />
                            </div>

                            <h5 class="heading5">{{ user.name }}</h5>
                            <p>{{ user.job_title }}</p>
                        </div>
                    </li>
                </ul>
            </div>

            <header class="main-inner__header">
                <h2 class="heading2">Users</h2>

                <a
                    v-if="!$store.getters.settings.disable_add_users"
                    class="link"
                    @click="$refs.editUser.open(0, false)"
                >+ Add User</a>
            </header>

            <div class="main-inner__content">
                <ul class="list-contacts list-contacts--full">
                    <li v-for="user in users.common" :key="user.id">
                        <div class="card card--bordered card--centered">

                            <b-dropdown class="drag-n-drop icons-list dropdown-simple dropdown-small position-absolute" boundary="viewport">
                                <b-dropdown-item class="icons-list__edit" @click="$refs.editUser.open(user.id, false)">
                                    Edit User
                                </b-dropdown-item>

                                <b-dropdown-item class="icons-list__delete2" v-if="$store.state.user.permissions.indexOf('team-manage') >= 0" @click="deleteUser(user.id)">
                                    Remove User
                                </b-dropdown-item>

                                <b-dropdown-item v-if="!user.confirmed" class="icons-list__invite" @click="sendActivationEmail(user.id)">
                                    Re-send Invite
                                </b-dropdown-item>
                            </b-dropdown>

                            <div class="image-block image-block--rounded--lg">
                                <Avatar
                                    :username="user.name"
                                    :src="user.avatar"
                                    :size="75"
                                />
                            </div>
                            <h5 class="heading5">{{ user.name }}</h5>
                            <p>{{ user.job_title }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <edit-group
            ref="editGroup"
            v-on:groupSaved="getGroupsList"
        >
        </edit-group>

        <edit-user
            ref="editUser"
            v-on:userSaved="getLists"
        >
        </edit-user>
    </div>
</template>

<script>
import team from "@/services/team.service";
import editGroup from "@/components/team/EditGroup"
import editUser from "@/components/team/EditUser"
import Avatar from "@/components/ui/Avatar.vue";
import Vue from "vue";

export default {
    components: {
        editGroup,
        editUser,
        Avatar,
    },

    data() {
        return {
            groups: [],

            users: {
                admins: [],
                common: [],
            },

            search: "",
        }
    },

    mounted() {
        this.getLists()

        this.$store.watch(state => {
            if (state.needUpdateUsers) {
                this.getLists()
            }
        })
    },

    methods: {
        getLists() {
            this.getGroupsList()
            this.getUsersList()
        },

        getGroupsList() {
            team.getGroupsIndex(this.search).then(response => {
                this.groups = response.data
            })
        },

        getUsersList() {
            team.getUsersIndex(this.search).then(response => {
                this.users = response.data
            })
        },

        deleteGroup(id) {
            if (confirm("Remove group?")) {
                team.deleteGroup(id).then(() => {
                    this.getGroupsList()
                })
            }
        },

        deleteUser(id) {
            if (confirm("Remove user?")) {
                team.deleteUser(id).then(() => {
                    this.getUsersList()
                })
            }
        },

        sendActivationEmail(id) {
            team.sendActivationEmail(id).then((response) => {

                let notifyText

                if (response.data) {
                    notifyText = "Email sent"
                }
                else {
                    notifyText = "Error occurred while sending message"
                }

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: notifyText
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
