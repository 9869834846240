import axios from '@/axios';

const RESOURCE_NAME = '/trackers';

export default {

    getList(type = '') {
        return axios.get(`${RESOURCE_NAME}/get-list?type=${type}`)
    },

    getListForCompanyAdd() {
        return axios.get(`${RESOURCE_NAME}/get-list-for-company-add`)
    },

    getListForSavedSearch() {
        return axios.get(`${RESOURCE_NAME}/get-list-for-saved-search`)
    },

    getInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-info?id=${id}`)
    },

    getSuggestedKeywords(id) {
        return axios.get(`${RESOURCE_NAME}/get-suggested-keywords?id=${id}`)
    },

    getSuggestedStartups(id, keywords) {
        return axios.post(`${RESOURCE_NAME}/get-suggested-startups?id=${id}`, {keywords})
    },

    getApplicationInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-application-info?id=${id}`)
    },

    getQuestions(id) {
        return axios.get(`${RESOURCE_NAME}/get-questions?id=${id}`)
    },

    getMessageRecipientsList(id) {
        return axios.get(`${RESOURCE_NAME}/get-message-recipients-list?id=${id}`)
    },

    getUserPermissions(id) {
        return axios.get(`${RESOURCE_NAME}/get-user-permissions?id=${id}`)
    },

    getNotes(id) {
        return axios.get(`${RESOURCE_NAME}/get-notes?id=${id}`)
    },

    addNote(id, data) {
        return axios.post(`${RESOURCE_NAME}/add-note?id=${id}`, data)
    },

    getTrackerEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    getNewTrackerInfo() {
        return axios.get(`${RESOURCE_NAME}/get-new-tracker-info`)
    },

    getCount() {
        return axios.get(`${RESOURCE_NAME}/get-count`)
    },

    update(id, data) {
        return axios.post(`${RESOURCE_NAME}/update-tracker?id=${id}`, data)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-tracker?id=${id}`)
    },

    getTrackersIndex(folderId, status, filterType1, filterType2, search, sorting, page) {
        return axios.get(`${RESOURCE_NAME}/get-trackers-index`, {
            params: {folderId, status, filterType1, filterType2, search, sorting, page}
        })
    },

    getOpportunitiesIndex(params) {
        return axios.get(`${RESOURCE_NAME}/get-opportunities-index`, { params })
    },

    getTopOpportunities() {
        return axios.get(`${RESOURCE_NAME}/get-top-opportunities`)
    },

    getNumber(folderId) {
        return axios.get(`${RESOURCE_NAME}/get-number?folder_id=${folderId}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    saveAccessSettings(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-access-settings?id=${id}`, data)
    },

    duplicate(id, data) {
        return axios.post(`${RESOURCE_NAME}/duplicate?id=${id}`, data)
    },

    getPipelinesList() {
        return axios.get(`${RESOURCE_NAME}/get-pipelines-list`)
    },

    getTimelinePresets() {
        return axios.get(`${RESOURCE_NAME}/get-timeline-presets-list`)
    },

    saveSubmitterFiles(trackerId, fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/save-submitter-files?id=${trackerId}&field_id=${fieldId}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    getRoles() {
        return axios.get(`${RESOURCE_NAME}/get-roles`)
    },

    setFavorite(id, favorite) {
        return axios.post(`${RESOURCE_NAME}/set-favorite?id=${id}`, {favorite: favorite})
    },

    getTrackersStages(ids) {
        return axios.post(`${RESOURCE_NAME}/get-trackers-stages`, {ids: ids})
    },

    shareWithEcosystems(id, ecosystemIds) {
        return axios.post(`${RESOURCE_NAME}/share-with-ecosystems?id=${id}`, {ecosystem_ids: ecosystemIds})
    },

    getSharedEcosystems(id) {
        return axios.post(`${RESOURCE_NAME}/get-shared-ecosystems?id=${id}`)
    },

    revokeShareWithEcosystems(id, ecosystemId) {
        return axios.post(`${RESOURCE_NAME}/revoke-share-with-ecosystems?id=${id}`, {ecosystem_id: ecosystemId})
    },

    getDiligenceFieldsForFilter(id) {
        return axios.get(`${RESOURCE_NAME}/get-diligence-fields-for-filter?id=${id}`)
    },

    join(id) {
        return axios.post(`${RESOURCE_NAME}/join?id=${id}`)
    },

    leave(id) {
        return axios.post(`${RESOURCE_NAME}/leave?id=${id}`)
    },

    hasScout(id) {
        return axios.get(`${RESOURCE_NAME}/has-scout?id=${id}`)
    },

    getFoldersAndTrackersList() {
        return axios.get(`${RESOURCE_NAME}/get-folders-and-trackers-list`)
    },

    getTrackersForApply(filters) {
        return axios.post(`${RESOURCE_NAME}/get-trackers-for-apply`, filters)
    },

    checkApplicationStatus(id) {
        return axios.get(`${RESOURCE_NAME}/check-application-status?id=${id}`)
    },

    apply(id, data) {
        return axios.post(`${RESOURCE_NAME}/apply?id=${id}`, data)
    },

    saveNotificationsSettings(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-notifications-settings?id=${id}`, data)
    },

    checkEnterpriseAccess(id) {
        return axios.get(`${RESOURCE_NAME}/check-enterprise-access?id=${id}`)
    },
}
