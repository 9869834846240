<script setup>
import Application from "@/components/trackers/Application.vue";
import {useRoute} from "vue2-helpers/vue-router";
import {ref, watch} from "vue";
import {useStore} from "vue2-helpers/vuex";
import trackersService from "@/services/trackers.service";

const route = useRoute();
const store = useStore();
const id = route.params.id;
const showDetails = ref(false);

watch(() => store.state.user, async (user) => {
    if (user.company_id) {
        const {data} = await trackersService.checkEnterpriseAccess(id, user.company_id);

        if (data) {
            showDetails.value = true;
        } else {
            store.state.pageNotFound = true;
        }
    }
}, {immediate: true});
</script>

<template>
    <Application
        v-if="showDetails"
        :tracker-id="id"
        :from-preview="true"
    />
</template>

<style scoped>

</style>
