<script setup>
import {trackerTypes} from "@/constants/trackers";
import {useStore} from "vue2-helpers/vuex";

const emit = defineEmits(['setType']);

const store = useStore();
</script>

<template>
    <div class="modal-sub modal-sub--dark">
        <h1 class="modal__title modal__title--primary pt-sm-5 mt-0">Create New Tracker</h1>
        <ul class="create-tracker-list">
            <li class="create-tracker-list__blank">
                <a @click="emit('setType', trackerTypes.PUBLIC)">
                    <h4 class="heading4">Public Challenge</h4>
                    <p>Startups can find your project and apply</p>
                </a>
            </li>

            <li class="create-tracker-list__template">
                <a @click="emit('setType', trackerTypes.PRIVATE)">
                    <h4 class="heading4">Private Tracker</h4>
                    <p>Users within your company add and evaluate startups</p>
                </a>
            </li>
        </ul>

        <div v-if="store.getters.isEnterprise" class="create-tracker-alternative">
            <div class="create-tracker-alternative__or">- Or -</div>

            <b-dropdown text="More Tracker Types" class="dropdown-tracker">
                <b-dropdown-item @click="emit('setType', trackerTypes.ECOSYSTEMS)">
                    <h4 class="heading4">Ecosystem Tracker</h4>

                    <p>Follow VCs, universities, etc, & their startups</p>
                </b-dropdown-item>
                <b-dropdown-item class="dropdown-tracker__poc" @click="emit('setType', trackerTypes.POC)">
                    <h4 class="heading4">POC Tracker</h4>

                    <p>View POCs in a time-based layout</p>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<style scoped>

</style>
