<script setup>
import Multiselect from "vue-multiselect";
import {trackerTypes} from "@/constants/trackers";
import {computed, inject, onMounted, ref, watch} from "vue";
import Draggable from "vuedraggable";
import trackersService from "@/services/trackers.service";
import customFieldsService from "@/services/custom-fields.service";
import CustomFieldEdit from "@/components/customFields/CustomFieldEdit.vue";

const tracker = inject('tracker');
const trackerId = inject('trackerId');
const canEditInfo = inject('canEditInfo');

const showEditModal = ref(false);
const showScoutModal = ref(false);
const questionsList = ref([]);
const reviewerFieldsList = ref([]);
const diligenceFieldsList = ref([]);
const currentFieldIndex = ref(null);
const addQuestionModal = ref(null);
const addReviewModal = ref(null);

const customFieldsCount = computed(() => tracker.questions.length + tracker.reviewerFields.length + tracker.diligenceFields.length);
const editCustomFieldsLabel = computed(() => customFieldsCount.value ? canEditQuestions.value || canEditReviewFields.value || canEditInfo.value ? "Edit" : "View" : "+ Add");

const canEditQuestions = computed(() =>
    tracker.type === trackerTypes.PRIVATE
        ? tracker.userPermissions.includes('tracker-questions-edit-private')
        : tracker.userPermissions.includes('tracker-questions-edit-public')
);

const canEditReviewFields = computed(() => tracker.userPermissions.includes('tracker-reviewerFields-edit'));
const canEditCustomFields = computed(() => canEditQuestions.value || canEditReviewFields.value || canEditInfo.value);
const actualDiligenceFields = computed(() => diligenceFieldsList.value.filter(item => !tracker.diligenceFields.some(field => field && field.id === item.id)));
const actualReviewerFieldsList = computed(() => reviewerFieldsList.value.filter(item => !tracker.reviewerFields.some(field => field.field && field.field.id === item.id)));
const actualQuestionsList = computed(() => questionsList.value.filter(item => !tracker.questions.some(field => field.field && field.field.id === item.id)));

watch(() => tracker.questions, () => {
    tracker.questions.forEach((item, index) => {
        if (item.field && item.field.id === -1) {
            currentFieldIndex.value = index;
            tracker.questions[index].field = null;
            addQuestionModal.value.open(0);
        }
    })
}, {deep: true});

watch(() => tracker.reviewerFields, () => {
    tracker.reviewerFields.forEach((item, index) => {
        if (item.field && item.field.id === -1) {
            currentFieldIndex.value = index;
            tracker.reviewerFields[index].field = null;
            addReviewModal.value.open(0);
        }
    })
}, {deep: true});

onMounted(() => {
    getFieldsList();
});

async function getFieldsList() {
    getQuestionsList();
    getReviewsList();
    getDiligenceFields();
}

async function getQuestionsList() {
    const {data} = await customFieldsService.getFieldsArray('tracker_question');
    questionsList.value = data;

    questionsList.value.push({
        id: -1,
        name: "Create New Question",
    });

    if (tracker.type === trackerTypes.PUBLIC && !trackerId.value && !tracker.questions.length) {
        tracker.questions = questionsList.value.filter(item => item.default_question).map(item => ({field: item, required: false}));
    }
}

async function getReviewsList() {
    const {data} = await customFieldsService.getFieldsArray('tracker_review');
    reviewerFieldsList.value = data;

    reviewerFieldsList.value.push({
        id: -1,
        name: "Create New Field",
    });
}

async function getDiligenceFields() {
    const {data} = await customFieldsService.getFieldsArray('tracker_diligence');
    diligenceFieldsList.value = data;
}

async function addQuestion() {
    let canAddQuestion = true;

    if (trackerId.value) {
        const {data} = await trackersService.hasScout(trackerId.value);

        if (data) {
            showScoutModal.value = true;
            canAddQuestion = false;
        }
    }

    if (canAddQuestion) {
        tracker.questions.push({field: null, required: false})
    }
}

async function updateQuestion(id) {
    await getQuestionsList();
    tracker.questions[currentFieldIndex.value].field = questionsList.value.find(item => item.id === id);
}

async function updateReview(id) {
    await getReviewsList();
    tracker.reviewerFields[currentFieldIndex.value].field = reviewerFieldsList.value.find(item => item.id === id);
}
</script>

<template>
    <div v-if="tracker.type !== trackerTypes.ECOSYSTEMS && (customFieldsCount || canEditCustomFields)" class="d-flex align-items-center mb-4">

        <h5 class="heading5 heading5--secondary mr-4 mb-0">Custom Fields</h5>
        <template v-if="customFieldsCount"><span class="fw-bold mr-2">{{customFieldsCount}} Added</span></template>

        <div class="link-wrapper m-0 p-0">
            <a
                class="link m-0"
                :class="{'link-edit link-wrapper__edit': customFieldsCount && canEditCustomFields}"
                @click="showEditModal = true"
            >
                {{editCustomFieldsLabel}}
            </a>
        </div>

        <b-modal v-model="showEditModal" title="Customizations">
            <template v-if="canEditQuestions">
                <p v-if="tracker.type === trackerTypes.PUBLIC" class="modal-text">
                    Add fields startups and reviewers fill out in order to apply tracker / evaluate startups.
                </p>

                <p v-else class="modal-text">
                    Add fields reviewers fill out in order to better evaluate startups.
                </p>
            </template>

            <template v-if="tracker.type === trackerTypes.PUBLIC && (canEditQuestions || tracker.questions.length)">
                <h5 class="heading5 heading5--secondary">Questions</h5>
                <p class="modal-text tracker-hint">Fields startups will need to fill out when applying to this tracker</p>

                <Draggable
                    v-model="tracker.questions"
                    class="list-drag-n-drop--full list-drag-n-drop--complex"
                    :class="{'list-drag-n-drop': canEditQuestions}"
                    handle=".draggable"
                >
                    <div
                        v-for="(question, index) in tracker.questions"
                        :key="'q'+index"
                        class="w-100"
                        :class="{draggable: canEditQuestions}"
                    >
                        <div class="mb-2 pr-0" :class="{'list-drag-n-drop__item': canEditQuestions}">
                            <span></span>

                            <Multiselect
                                class="multiselect-mid select-questions"
                                v-model="tracker.questions[index].field"
                                :options="actualQuestionsList"
                                :disabled="!canEditQuestions"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                            >
                                <!-- Customize selected option to add title. Show full question name on mouse over (when question is long) -->
                                <template slot="singleLabel" slot-scope="props"><span class="p-0" :title="props.option.name">{{ props.option.name }}</span></template>
                            </Multiselect>
                        </div>


                        <div v-if="canEditQuestions" class="d-flex align-items-center justify-content-between mb-4">
                            <div class="input-checkbox" v-if="tracker.questions[index].field && ['static_text','section_text'].indexOf(tracker.questions[index].field.field_type) < 0">
                                <input :id="'question-mandatory-'+index" type="checkbox" v-model="tracker.questions[index].required">
                                <label :for="'question-mandatory-'+index">Make mandatory</label>
                            </div>

                            <div class="text-right ml-auto">
                                <a @click="tracker.questions.splice(index, 1)" class="link-remove"></a>
                            </div>
                        </div>
                    </div>
                </Draggable>

                <div class="link-wrapper" v-if="canEditQuestions">
                    <a @click="tracker.questions.push({field: null, required: 0})" class="link">+ Add Question</a>
                </div>
            </template>

            <template v-if="tracker.reviewsEnabled && (canEditReviewFields || tracker.reviewerFields.length)">
                <h5 class="heading5 heading5--secondary">Reviewer Fields</h5>
                <p class="modal-text tracker-hint">Fields your team members will fill out in order to review startups</p>

                <Draggable v-model="tracker.reviewerFields" class="list-drag-n-drop--full list-drag-n-drop--complex" :class="{'list-drag-n-drop': canEditReviewFields}" handle=".draggable">
                    <div v-for="(field, index) in tracker.reviewerFields" :key="'r'+index" class="w-100" :class="{draggable: canEditQuestions}">

                        <div class="mb-2 pr-0" :class="{'list-drag-n-drop__item': canEditQuestions}">
                            <span></span>

                            <Multiselect
                                v-model="tracker.reviewerFields[index].field"
                                class="multiselect-mid mb-2"
                                :options="actualReviewerFieldsList"
                                :disabled="!canEditReviewFields"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                            />
                        </div>

                        <div v-if="canEditReviewFields" class="d-flex align-items-center justify-content-between mb-4">
                            <div class="input-checkbox">
                                <input :id="'mandatory-'+index" type="checkbox" v-model="tracker.reviewerFields[index].required">
                                <label :for="'mandatory-'+index">Make mandatory</label>
                            </div>

                            <div v-if="canEditReviewFields" class="text-right">
                                <a @click="tracker.reviewerFields.splice(index, 1)" class="link-remove"></a>
                            </div>
                        </div>
                    </div>
                </Draggable>

                <div class="link-wrapper" v-if="canEditReviewFields">
                    <a @click="tracker.reviewerFields.push({field: null, required: 0})" class="link">+ Add Field</a>
                </div>
            </template>

            <h5 class="heading5 heading5--secondary">Startup Diligence Fields</h5>
            <p class="modal-text tracker-hint">Fields that can be used as columns in your tracker that can be filled in by users</p>

            <div v-for="(field, index) in tracker.diligenceFields" :key="'d'+index">
                <multiselect class="multiselect-mid mb-2"
                             v-model="tracker.diligenceFields[index]"
                             :options="actualDiligenceFields"
                             :disabled="!canEditInfo"
                             select-label=""
                             deselect-label=""
                             label="name"
                             track-by="name"
                >
                </multiselect>
                <div class="text-right mb-2" v-if="canEditInfo">
                    <a @click="tracker.diligenceFields.splice(index, 1)" class="link link-normal">Remove</a>
                </div>
            </div>

            <div class="link-wrapper" v-if="canEditInfo">
                <a @click="tracker.diligenceFields.push(null)" class="link">+ Add Field</a>
            </div>

            <template v-if="tracker.type === trackerTypes.PRIVATE && $store.getters.isPremiumEnterprise && (canEditQuestions || tracker.questions.length)">
                <h5 class="heading5 heading5--secondary">Startup Questions</h5>
                <p class="modal-text tracker-hint">Fields startups will be prompted to fill out in order to be evaluated.<br>
                    When startup questions are present, adding a startup to your tracker will send them a form with your fields to fill out.</p>

                <Draggable v-model="tracker.questions" class="list-drag-n-drop--full list-drag-n-drop--complex" :class="{'list-drag-n-drop': canEditQuestions}" handle=".draggable">
                    <div v-for="(question, index) in tracker.questions" :key="'q'+index" class="w-100" :class="{draggable: canEditQuestions}">
                        <div class="mb-2 pr-0" :class="{'list-drag-n-drop__item': canEditQuestions}">
                            <span></span>

                            <Multiselect
                                v-model="tracker.questions[index].field"
                                class="multiselect-mid"
                                :options="actualQuestionsList"
                                :disabled="!canEditQuestions"
                                select-label=""
                                deselect-label=""
                                label="name"
                                track-by="name"
                            />
                        </div>

                        <div v-if="canEditQuestions" class="d-flex align-items-center justify-content-between mb-4">
                            <div class="text-right ml-auto">
                                <a @click="tracker.questions.splice(index, 1)" class="link">Remove</a>
                            </div>
                        </div>
                    </div>
                </Draggable>

                <div class="link-wrapper" v-if="canEditQuestions">
                    <a @click="addQuestion" class="link">+ Add Field</a>
                </div>
            </template>

            <template #modal-footer>
                <b-button size="lg" variant="primary" @click="showEditModal = false">
                    Close
                </b-button>
            </template>
        </b-modal>

        <b-modal v-model="showScoutModal" title="Before You Enable Startup Questions...">
            <p>Startup questions and auto-scout can't be turned on at the same time. Disable auto-scout for this tracker in the
                startups tab by selecting Saved Searches > Manage Saved Searches</p>

            <template #modal-footer>
                <b-button variant="primary" size="lg" @click="showScoutModal = false">Okay</b-button>
            </template>
        </b-modal>

        <CustomFieldEdit
            ref="addQuestionModal"
            type="tracker_question"
            @saved="updateQuestion"
        />

        <CustomFieldEdit
            ref="addReviewModal"
            type="tracker_review"
            @saved="updateReview"
        />
    </div>
</template>

<style scoped>

</style>
