<template>
    <b-modal id="export-settings-modal" title="Export PDF" modal-class="modal-w-lg">
        <div v-if="tableType !== 'profile'" class="form-control-wrapper mb-3">
            <div class="input-radio">
                <input id="export-type-profile" type="radio" v-model="exportType" value="profile">
                <label for="export-type-profile">Full Profiles</label>
            </div>

            <div class="input-radio">
                <input id="export-type-table" type="radio" v-model="exportType" value="table">
                <label for="export-type-table">Table View</label>
            </div>
        </div>

        <template v-if="exportType === 'profile'">
            <p>
                Select the fields you want to include in your exported file.
            </p>

            <template v-if="trackerId">
                <h5 class="heading5 heading5--secondary">Tracker Details</h5>

                <ul class="list-2-cols">
                    <li>
                    <span class="input-checkbox">
                        <input id="exportTrackerDetails" v-model="exportTrackerDetails" type="checkbox">
                        <label for="exportTrackerDetails">Include tracker details in the export</label>
                    </span>
                    </li>
                </ul>
            </template>

            <h5 class="heading5 heading5--secondary">About</h5>

            <ul class="list-2-cols">
                <li v-for="(field, index) in infoFields" :key="field.name">
                    <span class="input-checkbox">
                        <input :id="'info-' + field.name" v-model="infoFields[index].checked" type="checkbox">
                        <label :for="'info-' + field.name">{{ field.label }}</label>
                    </span>
                </li>
            </ul>

            <template v-if="showContacts">
                <h5 class="heading5 heading5--secondary">Contacts</h5>
                <div class="your-custom-fields">
                    <div class="your-custom-fields__inner">
                        <div class="input-radio">
                            <input id="contacts-none" type="radio" v-model="contacts" value="none">
                            <label for="contacts-none">None</label>
                        </div>
                        <div class="input-radio">
                            <input id="contacts-all" type="radio" v-model="contacts" value="all">
                            <label for="contacts-all">All</label>
                        </div>
                        <div class="input-radio">
                            <input id="contacts-primary" type="radio" v-model="contacts" value="primary">
                            <label for="contacts-primary">Only Primary and Custom Contacts</label>
                        </div>
                    </div>
                </div>
            </template>

            <h5 class="heading5 heading5--secondary">Company Details</h5>

            <ul class="list-2-cols">
                <li v-for="(field, index) in actualDetailsFields" :key="field.name">
                    <span class="input-checkbox">
                        <input :id="'details-' + field.name" v-model="detailsFields[index].checked" type="checkbox">
                        <label :for="'details-' + field.name">{{ field.label }}</label>
                    </span>
                </li>
            </ul>

            <template v-if="interactionTypesLoaded && interactions.length">
                <h5 class="heading5 heading5--secondary">Interactions</h5>

                <ul class="list-2-cols">
                    <li v-for="(field, index) in interactions" :key="field.name">
                    <span class="input-checkbox">
                        <input :id="'interaction-' + field.name" v-model="interactions[index].checked" type="checkbox">
                        <label :for="'interaction-' + field.name">{{ field.label }}</label>
                    </span>
                    </li>
                </ul>
            </template>

            <template v-if="publicTracker">
                <h5 class="heading5 heading5--secondary">Submitted answers</h5>

                <ul class="list-2-cols">
                    <li>
                    <span class="input-checkbox">
                        <input id="answers" v-model="answers" type="checkbox">
                        <label for="answers">Show Answers</label>
                    </span>
                    </li>
                </ul>
            </template>

            <template v-if="$store.getters.isEnterprise && profileFields.length">
                <h5 class="heading5 heading5--secondary">Your Custom Fields</h5>

                <div class="mb-4" v-for="(section, sectionIndex) in profileFields" :key="section.id">
                    <div class="your-custom-fields">
                        <h5 class="heading5 heading5--secondary">{{ section.name }}</h5>

                        <div class="your-custom-fields__inner">
                            <div class="input-radio">
                                <input :id="'all-' + section.id" type="radio" v-model="profileFields[sectionIndex].selected" value="all" @click="() => {
                                    profileFields[sectionIndex].expanded = false
                                    profileFields[sectionIndex].fields = profileFields[sectionIndex].fields.map(item => ({
                                        ...item,
                                        checked: true
                                    }))
                                }">
                                <label :for="'all-' + section.id">Include all</label>
                            </div>
                            <div class="input-radio">
                                <input :id="'none-' + section.id" type="radio" v-model="profileFields[sectionIndex].selected" value="none" @click="() => {
                                    profileFields[sectionIndex].expanded = false
                                    profileFields[sectionIndex].fields = profileFields[sectionIndex].fields.map(item => ({
                                        ...item,
                                        checked: false,
                                    }))
                                }">
                                <label :for="'none-' + section.id">Include none</label>
                            </div>
                            <div class="input-radio">
                                <input :id="'selected-' + section.id" type="radio" v-model="profileFields[sectionIndex].selected" value="selected" @click="() => {
                                    profileFields[sectionIndex].expanded = true
                                }">
                                <label :for="'selected-' + section.id">Select fields to include</label>
                            </div>

                            <div class="ml-auto text-right">
                                <a class="your-custom-fields__link" v-if="profileFields[sectionIndex].expanded" @click="() => {profileFields[sectionIndex].expanded = false}">Collapse</a>
                                <a class="your-custom-fields__link" v-else @click="() => {profileFields[sectionIndex].expanded = true}">Expand</a>
                            </div>
                        </div>
                    </div>
                    <template v-if="section.expanded">
                        <div class="your-custom-fields__checkbox">
                            <div v-for="(field, fieldIndex) in section.fields" :key="field.id">
                                <span class="input-checkbox">
                                    <input :id="'field-' + field.id" v-model="profileFields[sectionIndex].fields[fieldIndex].checked" type="checkbox" @click="() => {
                                        profileFields[sectionIndex].selected = 'selected'
                                    }">
                                    <label :for="'field-' + field.id">{{ field.name }}</label>
                                </span>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </template>

        <template v-else>
            <p>Select the columns you want to display on the table and rearrange their order.</p>

            <SelectColumns
                ref="selectColumns"
                :table-type="tableType"
                use-type="personal"
                :tracker-type="trackerType"
                :tracker-id="trackerId"
                @changed="setColumns"
            />
        </template>

        <template #modal-footer>
            <PrimaryButton caption="Export" :loading="loading" loading-caption="Exporting..." @click="apply"/>
        </template>

    </b-modal>
</template>

<script>

import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import exportsService from "@/services/exports.service";
import customFields from "@/services/custom-fields.service";
import startups from "@/services/startups.service";
import SelectColumns from "@/components/manage/SelectColumns.vue";

export default {
    props: {
        tableType: {
            type: String,
            default: 'search-startups',
        },

        publicTracker: {
            type: Boolean,
            default: false,
        }
    },

    components: {SelectColumns, PrimaryButton},

    data() {
        return {
            startupId: 0,
            fileName: '',
            trackerId: 0,
            trackerType: '',
            startupsIds: [],
            filters: {},
            query: {},
            loading: false,
            exportTrackerDetails: false,
            exportType: 'profile',
            columns: {},
            showContacts: false,

            infoFields: [
                {
                    name: 'website',
                    label: 'Website',
                    checked: true
                },
                {
                    name: 'total_raised',
                    label: 'Raised',
                    checked: true
                },
                {
                    name: 'employees',
                    label: 'Employees',
                    checked: true
                },
                {
                    name: 'location',
                    label: 'Location',
                    checked: true
                },
                {
                    name: 'stage',
                    label: 'Stage',
                    checked: true
                },
                {
                    name: 'founded',
                    label: 'Founded',
                    checked: true
                },
                {
                    name: 'social',
                    label: 'Social',
                    checked: true
                },
                {
                    name: 'categories',
                    label: 'Categories',
                    checked: true
                },
                {
                    name: 'description',
                    label: 'Description',
                    checked: true
                },
                {
                    name: 'funding_rounds',
                    label: 'Funding History',
                    checked: true
                },
                {
                    name: 'tags',
                    label: 'Tags',
                    checked: true
                },
            ],

            contacts: 'none',

            detailsFields: [
                {
                    name: 'clients',
                    label: 'Clients',
                    checked: true
                },
                {
                    name: 'features',
                    label: 'Features',
                    checked: true
                },
                {
                    name: 'swot_report',
                    label: 'SWOT Analysis',
                    checked: false
                },
            ],

            interactions: [],

            allInteractions: [
                {
                    name: 'note',
                    label: 'Notes',
                    checked: true
                },
                {
                    name: 'phone_call',
                    label: 'Calls',
                    checked: true
                },
                {
                    name: 'email',
                    label: 'Emails',
                    checked: true
                },
                {
                    name: 'meeting',
                    label: 'Meetings',
                    checked: true
                },
                {
                    name: 'demo',
                    label: 'Demos',
                    checked: true
                },
            ],

            answers: true,
            profileFields: [],
            interactionTypesLoaded: false,
        }
    },

    computed: {
        actualDetailsFields() {
            if (this.$store.getters.isEnterprise) {
                return this.detailsFields;
            } else {
                return this.detailsFields.filter(item => item.name !== 'swot_report')
            }
        }
    },

    methods: {
        open(startupId, fileName, trackerId = 0) {
            this.startupId = startupId
            this.trackerId = trackerId
            this.fileName = fileName
            this.init()
        },

        openForTracker(fileName, trackerId, trackerType, startupsIds, filters) {
            this.trackerId = trackerId
            this.trackerType = trackerType
            this.fileName = fileName
            this.startupsIds = startupsIds
            this.filters = filters
            this.init()
        },

        openForMultiple(fileName, startupsIds, filters) {
            this.fileName = fileName
            this.startupsIds = startupsIds
            this.filters = filters
            this.init()
        },

        openForQuery(fileName, query) {
            this.fileName = fileName
            this.query = query
            this.init()
        },

        init() {
            this.interactionTypesLoaded = false;
            this.profileFields = [];
            this.showContacts = !this.$store.getters.watchEmbargoed;

            customFields.getFieldsWithSections('company_diligence', 1, this.startupId).then(response => {
                let sections = response.data

                for (let sectionId in sections) {
                    this.profileFields.push({
                        id: sectionId,
                        name: sections[sectionId].name,
                        selected: 'all',
                        expanded: false,
                        fields: sections[sectionId].fields.map(field => ({
                            ...field,
                            checked: true,
                        })),
                    });
                }
            })

            if (this.$store.getters.isEnterprise) {
                if (this.startupId || this.startupsIds.length === 1) {
                    let startupId = this.startupId ? this.startupId : this.startupsIds[0];

                    startups.getExistingInteractionTypes(startupId).then(response => {
                        this.interactions = this.allInteractions.filter(item => response.data.includes(item.name))
                        this.interactionTypesLoaded = true
                    })

                    if (this.$store.getters.watchEmbargoed) {
                        startups.getIsEmbargoed(startupId).then(response => {
                            this.showContacts = !response.data
                        })
                    }
                } else {
                    this.interactions = this.allInteractions
                    this.interactionTypesLoaded = true
                    this.showContacts = true;
                }
            }

            this.$bvModal.show('export-settings-modal')
        },

        setColumns(columns) {
            this.columns = {...columns};
        },

        apply() {
            this.loading = true
            let settings

            if (this.exportType === 'table') {
                let columns = [];
                for (let group in this.columns) {
                    columns = [...columns, ...this.columns[group].filter(column => column.checked).map(column => ({
                        label: column.label,
                        name: column.name,
                    }))]
                }

                settings = {
                    columns,
                    tracker_id: this.trackerId
                };
            } else {
                settings = {
                    info: this.infoFields.filter(item => item.checked).map(item => item.name),
                    contacts: this.contacts,
                    details: this.detailsFields.filter(item => item.checked).map(item => item.name),
                    answers: this.answers,
                    custom_fields: {},
                    tracker_id: this.trackerId,
                    tracker_details: this.exportTrackerDetails,
                }

                if (this.$store.getters.isEnterprise) {
                    settings['interactions'] = this.interactions.filter(item => item.checked).map(item => item.name);
                } else {
                    settings['interactions'] = [];
                }

                for (let i in this.profileFields) {
                    if (
                        this.profileFields[i].selected === 'all' ||
                        (this.profileFields[i].selected === 'selected' && this.profileFields[i].fields.some(field => field.checked))
                    ) {
                        settings.custom_fields[this.profileFields[i].id] = {
                            'selected': this.profileFields[i].selected,
                            'fields': this.profileFields[i].fields.filter(item => item.checked).map(item => item.id)
                        };
                    }
                }
            }

            let startups_ids = [];
            let filters = {};
            let query = {};

            if (this.startupId) {
                startups_ids = [this.startupId];
            } else if (this.startupsIds.length) {
                startups_ids = this.startupsIds
            } else if ('query' in this.query) {
                query = this.query
            } else {
                filters = {
                    ...this.filters,
                    tracker_id: this.trackerId,
                }
            }

            exportsService.generateStartupsPdf(this.exportType, startups_ids, settings, filters, query).then(response => {
                this.fileHelper.download(response.data, this.fileName + '.pdf')
            }).finally(() => {
                this.loading = false
                this.$bvModal.hide('export-settings-modal')
            })
        }
    }
}
</script>
