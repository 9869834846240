<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import trackersTemplatesService from "@/services/trackers-templates.service";
import {decodeIntervalFromServer} from "@/helpers/date";
import {useStore} from "vue2-helpers/vuex";

const props = defineProps(['submitterFieldsList', 'selectTemplateStep']);
const emit = defineEmits(['update:selectTemplateStep']);
const store = useStore();

const tracker = inject('tracker');
const timeline = inject('timeline');

const selectedTemplate = ref(null)

const templatesList = ref({
    default: [],
    custom: []
});

const hasTemplates = computed(() => templatesList.value.default.length || templatesList.value.custom.length);

watch(selectedTemplate, (value) => {
    if (value) {
        applyTemplate(value);
    }
});

onMounted(() => {
    getTemplatesList();
});

function getTemplatesList() {
    trackersTemplatesService.getTemplates().then(({data}) => {
        templatesList.value = data;
    })
}

function applyTemplate(template) {
    tracker.name = template.tracker_name;
    tracker.description = template.description;
    tracker.defaultView = template.default_view;
    tracker.reviewsEnabled = template.reviews_enabled;
    tracker.pipeline = template.pipeline;

    if (template.start_date || template.end_date) {
        timeline.value = decodeIntervalFromServer([template.start_date, template.end_date]);
    }

    tracker.categories = [...template.categories];

    tracker.reviewerFields = template.reviewer_fields.map(item => ({
        field: item,
        required: false
    }));

    tracker.diligenceFields = [...template.diligence_fields];

    tracker.submitterFields = template.submitter_fields.map(item => ({
        field: props.submitterFieldsList.find(fieldItem => fieldItem.id === item.id),
        value: {
            text: "",
            options: [],
            files: [],
            links: []
        },
    }));

    emit('update:selectTemplateStep', false);
}

defineExpose({
    selectedTemplate,
    hasTemplates
});
</script>

<template>
    <div v-if="selectTemplateStep">
        <h1 class="modal__title">Choose a Template</h1>

        <template v-if="templatesList.default.length">
            <h4 class="heading4">SwitchPitch Templates</h4>

            <ul class="list-template">
                <li v-for="template in templatesList.default" :key="template.id">
                    <a @click="selectedTemplate = template">
                        <div>
                            <h5 class="heading5 heading5--secondary">{{template.name}}</h5>
                            <p>{{template.description}}</p>
                        </div>
                    </a>
                </li>
            </ul>
        </template>

        <template v-if="templatesList.custom.length">
            <h4 class="heading4">{{ store.state.user.company_name }} Templates</h4>

            <ul class="list-template">
                <li class="list-template__list" v-for="template in templatesList.custom" :key="template.id">
                    <a @click="selectedTemplate = template">
                        <div>
                            <h5 class="heading5 heading5--secondary">{{template.name}}</h5>
                            <p>{{template.description}}</p>
                        </div>
                    </a>
                </li>
            </ul>
        </template>
    </div>
</template>

<style scoped>

</style>
