<script setup>
import {inject} from "vue";
import {trackerTypes} from "@/constants/trackers";
import constants from "@/constants/constants";
import {useStore} from "vue2-helpers/vuex";

const store = useStore();

const tracker = inject('tracker');
const canEditInfo = inject('canEditInfo');

function disableReviews(e) {
    if (tracker.reviewsEnabled && tracker.reviewerFields.length) {
        if (!confirm("Disable Reviews?\n" +
            "You added reviewer fields to the tracker which will be removed, are you sure you want to disable reviews?")) {

            e.preventDefault()
        }
        else {
            tracker.reviewerFields = []
        }
    }
}
</script>

<template>
    <div>
        <h5 class="heading5 heading5--secondary mb-2">Reviews</h5>

        <div class="d-flex mb-4">
            <div class="input-radio mr-3">
                <input type="radio" value="1" :disabled="!canEditInfo" v-model="tracker.reviewsEnabled" id="tr1" name="tr">
                <label for="tr1">Enabled (recommended)</label>
            </div>
            <div class="input-radio">
                <input type="radio" value="0" @click="disableReviews" :disabled="!canEditInfo" v-model="tracker.reviewsEnabled" id="tr2" name="tr">
                <label for="tr2">Disabled</label>
            </div>
        </div>

        <template v-if="store.getters.isEnterprise">
            <h5 class="heading5 heading5--secondary mb-2">Notifications</h5>

            <div class="input-checkbox">
                <input id="enable-notifications" type="checkbox" v-model="tracker.enableNotifications">
                <label for="enable-notifications">Enable Notifications</label>
            </div>
        </template>

        <template v-if="tracker.type === trackerTypes.PUBLIC">
            <h5 class="heading5 heading5--secondary mt-3 mb-2">Settings</h5>
            <div class="input-checkbox">
                <input id="only-referral" type="checkbox" :disabled="!canEditInfo" v-model="tracker.onlyReferral">
                <label for="only-referral">Only startups which have referral URL can apply</label>
            </div>

            <div class="input-checkbox mt-3">
                <input id="need-complete-profile" type="checkbox" :disabled="!canEditInfo" v-model="tracker.needCompleteProfile">
                <label for="need-complete-profile">Require startups to complete profiles to apply</label>
            </div>

            <div class="input-checkbox mt-3">
                <input id="notify-startups" type="checkbox" :disabled="!canEditInfo" v-model="tracker.notifyStartups">
                <label for="notify-startups">Notify not applied startups the day before end date</label>
            </div>

            <div class="input-checkbox mt-3">
                <input id="hide-details" type="checkbox" :disabled="!canEditInfo" v-model="tracker.hideDetailsUntilSignIn">
                <label for="hide-details">Hide tracker details until startups sign in</label>
            </div>
        </template>

        <div class="input-checkbox mt-2 mb-2" v-if="store.state.user.company_id === constants.companyPfizer && tracker.type === trackerTypes.PRIVATE">
            <h5 class="heading5 heading5--secondary">Settings</h5>
            <input id="referral-link-access" type="checkbox" :disabled="!canEditInfo" v-model="tracker.InternalAccessById">
            <label for="referral-link-access">Any user who has URL can see tracker</label>
        </div>
    </div>
</template>

<style scoped>

</style>
