<script setup>
import {trackerTypes} from "@/constants/trackers";
import {inject, onMounted, ref, watch} from "vue";
import {
    decodeDateFromServer,
    decodeIntervalFromServer,
    encodeDateForServer,
    encodeIntervalForServer
} from "@/helpers/date";
import DatePicker from "vue2-datepicker";

const tracker = inject('tracker');
const canEditInfo = inject('canEditInfo');
const timeline = inject('timeline');

const showTimeline = ref(false);
const postDate = ref(null);

watch(timeline, (value) => {
    if (value[0]) {
        showTimeline.value = true;
        [tracker.startDate, tracker.endDate] = encodeIntervalForServer(value)
    } else {
        showTimeline.value = false;
        [tracker.startDate, tracker.endDate] = [null, null];
    }
});

watch(postDate, (value) => {
    if (value) {
        tracker.postDate = encodeDateForServer(value);
    } else {
        tracker.postDate = null;
    }
});

onMounted(() => {
    if (tracker.startDate !== null) {
        timeline.value = decodeIntervalFromServer([tracker.startDate, tracker.endDate]);
        showTimeline.value = true;
    }

    if (tracker.postDate) {
        postDate.value = decodeDateFromServer(tracker.postDate);
    }
});
</script>

<template>
    <div>
        <h5 class="heading5 heading5--secondary mb-4">Timeline
            <a
                v-if="!showTimeline"
                class="link ml-4"
                @click="showTimeline = true"
            >+ Add</a>
        </h5>

        <div v-if="showTimeline" class="mb-4">
            <DatePicker
                v-model="timeline"
                format = "MM/DD/YYYY"
                :disabled="!canEditInfo"
                placeholder="Select Start / End Date"
                range
            />
        </div>

        <template v-if="tracker.type === trackerTypes.PUBLIC">
            <h5 class="heading5 heading5--secondary">Posted Date</h5>

            <div class="mb-4">
                <DatePicker
                    v-model="postDate"
                    format = "MM/DD/YYYY"
                    :disabled="!canEditInfo"
                    placeholder="Select Posted Date"
                />
            </div>
        </template>
    </div>
</template>

<style scoped>

</style>
