<template>
    <div>
        <b-modal :id="`modal-add-startup-${this.prefix}`" title="Add Startups">
            <template v-if="!$store.getters.settings.disable_upload">
                <div class="input-radio mb-2">
                    <input type="radio" v-model="addType" value="singleAdd" id="addonestartup">
                    <label for="addonestartup">Add one startup</label>
                </div>
                <div class="input-radio">
                    <input type="radio" v-model="addType" value="multipleAdd" id="uploadstartup">
                    <label for="uploadstartup">Upload multiple startups with .csv</label>
                </div>
            </template>

            <template v-if="addType === 'singleAdd'">
                <div class="form mt-3">

                    <h5 class="heading5 heading5--secondary">Startup Name</h5>
                    <template v-if="typeof errors.startup_name !== 'undefined'">
                        <span class="form__error" v-for="(error, index) in errors.startup_name" :key="'startup_name-' + index">{{error}}</span>
                    </template>
                    <b-form-input class="form-control--mid mb-3" v-model="name"></b-form-input>

                    <h5 class="heading5 heading5--secondary">Startup URL</h5>
                    <template v-if="typeof errors.url !== 'undefined'">
                        <span class="form__error" v-for="(error, index) in errors.url" :key="'url-' + index">{{error}}</span>
                    </template>
                    <b-form-input class="form-control--mid mb-2" v-model="url"></b-form-input>

                    <p class="modal-text">Startup URLs are checked against existing startups on SwitchPitch to avoid duplication</p>

                    <template v-if="canInvite && this.$store.state.user.company_id !== this.constants.companyTravelers">
                        <h5 class="heading5 heading5--secondary">Invite Startup Via Email</h5>
                        <template v-if="typeof errors.contact_email !== 'undefined'">
                            <span class="form__error" v-for="(error, index) in errors.contact_email" :key="'contact_email-' + index">{{error}}</span>
                        </template>
                        <b-form-input class="form-control--mid mb-2" v-model="email"></b-form-input>
                        <p class="modal-text">Adding a contact email will invite them to join SwitchPitch</p>
                    </template>

                    <template v-if="$store.state.companyType === 'enterprise' && $store.state.user.settings.show_tags_on_add_startup_form">

                        <div v-for="(field, index) in customFields" :key="index" class="mb-4">
                            <h5 class="heading5 heading5--secondary">{{field.field.name}}</h5>

                            <CustomFieldValue
                                :field="customFields[index].field"
                                :instantUpdate="false"
                                @valueChanged="(value) => customValueChanged(index, value)"
                            />
                            <span v-if="index in customFieldsErrors" class="error">{{customFieldsErrors[index]}}</span>
                        </div>

                    </template>
                </div>
            </template>

            <template v-else>
                <div class="form">
                <template v-if="typeof errors.file !== 'undefined'">
                    <div class="form__error" v-for="(error, index) in errors.file" :key="index">{{error}}</div>
                </template>

                <p v-if="!file" class="modal-text mt-3">Download and use <a :href="baseURL + '/files/add_startup.csv'" target="_blank">this file</a> for best results</p>
                    <div class="input-file mb-3">
                        <template v-if="!file">
                            <label for="input-file__upload" class="mr-2">Choose File</label>
                            <input type="file" ref="newFile" id="input-file__upload" accept=".csv" v-on:change="handleFileUpload()">
                        </template>

                        <div v-else class="tags-list tags-list--light">
                            <span class="tags-list__tag mt-3">
                            {{file.name}}
                            <a @click="file = null" class="tags-list__tag__close"></a>
                            </span>
                        </div>
                    </div>
                </div>
            </template>

            <h5 class="heading5 heading5--secondary">Add To Trackers</h5>

            <multiselect
                v-model="trackers"
                :multiple="true"
                :options="trackersList"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                class="multiselect-mid"
            >
            </multiselect>
            <br>

            <template v-if="$store.state.user.company_type === 'enterprise'">
                <template v-if="showTags">
                    <h5 class="heading5 heading5--secondary">Add Tags</h5>

                    <multiselect
                        v-model="tags"
                        :multiple="true"
                        placeholder="Select tags"
                        :options="tagsList"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                        class="multiselect-mid pl-2"
                    >
                    </multiselect>

                    <br>
                </template>

                <template v-if="$store.state.user.company_id !== constants.companyPfizer && $store.state.user.company_id !== constants.companyTravelers">
                    <h5 class="heading5 heading5--secondary mb-2">Startup Visibility</h5>

                    <div class="input-radio mb-2">
                        <input type="radio" v-model="isPublic" value="1" id="public-startup">
                        <label for="public-startup">Public - Other SwitchPitch enterprise users will see this startup</label>
                    </div>
                    <div class="input-radio mb-4">
                        <input type="radio" v-model="isPublic" value="0" id="private-startup">
                        <label for="private-startup">Private - Only users in your company account will see this startup</label>
                    </div>
                </template>
            </template>

            <template v-if="$store.state.user.company_type === 'ecosystem'">
                <h5 class="heading5 heading5--secondary">Add To Portfolios</h5>
                <multiselect
                    v-model="portfolios"
                    :multiple="true"
                    :options="portfoliosList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid"
                >
                </multiselect>
                <br>
            </template>

            <template #modal-footer>
                <div class="buttons-wrapper">
                    <button class="btn btn-bordered btn-lg" @click="editTemplate" v-if="contactEmailEntered">Customize Invite</button>
                    <button class="btn btn-primary btn-lg" variant="primary" size="lg" :disabled="!canAdd" @click="add">
                        <span v-if="!loading">
                            <template v-if="contactEmailEntered">Add & Invite</template>
                            <template v-else>Add</template>
                        </span>
                        <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                    </button>
                </div>
            </template>
        </b-modal>

        <EditEmailTemplate
            ref="editEmailTemplate"
            prefix = "addStartup"
            v-on:saved="setTemplate"
            v-on:close="$bvModal.show(`modal-add-startup-${prefix}`)"
        >
        </EditEmailTemplate>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import invite from "@/services/invite.service"
// import trackers from "@/services/trackers.service"
import portfolios from "@/services/ecosystem/portfolios.service"
import multiselect from "vue-multiselect";
import Vue from 'vue';
import EditEmailTemplate from "@/components/settings/EditEmailTemplate";
import companyService from "@/services/company.service";
import ecosystemStartups from "@/services/ecosystem/startups.service";
import { baseURL } from "@/axios";
import tags from "@/services/tags.service";
import customFields from "@/services/custom-fields.service";
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue";

export default {
    components: {
        CustomFieldValue,
        multiselect,
        EditEmailTemplate
    },

    data() {
        return {
            addType: "singleAdd",
            name: "",
            email: "",
            url: "",
            isPublic: 1,
            trackers: [],
            customFields: [],

            portfolios: [],
            portfoliosList: [],
            tags: [],
            tagsList: [],

            template: {
                subject: "",
                body: "",
            },

            errors: {},
            customFieldsErrors: {},
            result: "",
            file: null,
            loading: false,
            contactEmailEntered: false,
            canInvite: false,
            baseURL,
        }
    },

	props: {
		prefix: String
	},

    watch: {
        email() {
            let domainParts = this.email.split('@')

            if (domainParts && domainParts.length === 2) {
                this.contactEmailEntered = true
            }
            else {
                this.contactEmailEntered = false
            }
        }
    },

    computed: {
        ...mapGetters(['trackersList', 'isEnterprise']),
        canAdd() {
            if (this.loading) {
                return false
            }
            else {
                if (this.addType === 'singleAdd') {
                    return this.name.length && (this.url.length || parseInt(this.isPublic) === 0)
                } else {
                    return this.file !== null
                }
            }
        },

        showTags() {
            return this.$store.state.companyType === 'enterprise' && this.$store.state.user.settings.show_tags_on_add_startup_form
        }
    },

    mounted() {
        if (this.$store.state.user.company_id === this.constants.companyPfizer) {
            this.isPublic = 0
        }

        if (this.showTags) {
            this.getTagsList()
        }

        this.fetchTrackers()

        if (this.$store.state.user.company_type === 'ecosystem') {
            portfolios.getList().then(response => {
                this.portfoliosList = response.data
            })
        }
    },

    methods: {
        ...mapActions(['fetchTrackers']),

        open(url = '') {
            this.errors = {}
            this.customFieldsErrors = {}
            this.name = ""
            this.url = url
            this.email = ""
            this.customFields = []
            this.tags = []

            if (this.$store.state.companyType === 'enterprise') {

                if (this.$store.state.user.settings.show_tags_on_add_startup_form) {
                    this.getCustomFields()
                }

                if (this.$store.state.accountType === 'premium') {
                    this.$bvModal.show(`modal-add-startup-${this.prefix}`)
                    this.canInvite = true
                }
                else {
                    this.showNeedUpgrade('startups-add')
                }
            }

            else if (this.$store.state.companyType === 'ecosystem') {
                if (this.$store.state.accountType === 'premium') {
                    this.$bvModal.show(`modal-add-startup-${this.prefix}`)
                    this.canInvite = true
                }
                else {
                    ecosystemStartups.getCounts().then(response => {
                        if (response.data.added < this.constants.ecosystemLimits['startups-add'][this.$store.state.user.account_type]) {
                            this.$bvModal.show(`modal-add-startup-${this.prefix}`)
                        }
                        else {
                            this.showNeedUpgrade('startups-add')
                        }

                        if (this.$store.state.accountType === 'basic' && response.data.added < this.constants.ecosystemLimits['startups-invite'][this.$store.state.user.account_type]) {
                            this.canInvite = true
                        }
                    })
                }
            }
        },

        getCustomFields() {
            customFields.getFieldsForStartupAddForm().then(({data}) => {
                this.customFields = data.map(item => {
                    return {
                        field: item,
                        value: {
                            text: '',
                            options: [],
                            files: [],
                            links: [],
                        },
                        error: '',
                    }
                })
            })
        },

        handleFileUpload() {
            if (this.$refs['newFile'].files.length) {
                this.file = this.$refs['newFile'].files[0]
            }
        },

        add() {
            this.errors = {};
            this.customFieldsErrors = {};
            this.result = "";
            this.url = this.url.trim();

            if (this.addType === 'singleAdd') {
                if ((!this.contactEmailEntered || confirm(this.name + " will be invited to join SwitchPitch, do you want to proceed?"))) {
                    this.loading = true

                    let data = {
                        name: this.name,
                        url: this.url,
                        contact_email: this.email,
                        is_public: this.isPublic,
                        trackers: this.trackers.map(item => item.id),
                        portfolios: this.portfolios.map(item => item.id),
                        custom_fields: this.customFields,
                        email_template: this.template,
                    }

                    if (this.showTags) {
                        data.tags = this.tags.map(item => item.id)
                    }

                    invite.addSingleStartup(data).then(response => {
                        this.errors = response.data.errors;
                        this.parseErrors();

                        if (!Object.keys(this.errors).length) {
                            this.result = response.data.result
                            this.$bvModal.hide(`modal-add-startup-${this.prefix}`);
                            this.$emit('startupsAdded')

                            if (response.data.success) {
                                Vue.notify({
                                    group: 'app',
                                    type: 'success',
                                    duration: 15000,
                                    text: response.data.result
                                })
                            } else {
                                Vue.notify({
                                    group: 'app',
                                    type: 'error',
                                    duration: 5000,
                                    text: response.data.result
                                })
                            }
                        }

                    }).finally(() => {
                        this.loading = false
                    })
                }
            }
            else {
                this.loading = true
                let formData = new FormData();
                formData.append('file', this.file)
                formData.append('trackers', JSON.stringify(this.trackers.map(item => item.id)))
                formData.append('portfolios', JSON.stringify(this.portfolios.map(item => item.id)))
                formData.append('is_public', this.isPublic)

                if (this.showTags) {
                    formData.append('tags', JSON.stringify(this.tags.map(item => item.id)))
                }

                invite.addMultipleStartups(formData).then(response => {
                    this.loading = false
                    this.errors = response.data.errors;

                    if (!Object.keys(this.errors).length) {
                        this.file = null
                        this.$bvModal.hide(`modal-add-startup-${this.prefix}`);
                        this.$emit('startupsAdded')

                        if (response.data.success) {
                            Vue.notify({
                                group: 'app',
                                type: 'success',
                                duration: 15000,
                                text: response.data.result
                            })
                        } else {
                            Vue.notify({
                                group: 'app',
                                type: 'error',
                                duration: 5000,
                                text: response.data.result
                            })
                        }
                    }
                }).catch(() => {
                    this.loading = false
                })
            }
        },

        customValueChanged(index, value) {
            this.customFields[index].value = value;
        },

        editTemplate() {
            this.$bvModal.hide(`modal-add-startup-${this.prefix}`)

            if (!this.template.subject.length) {
                companyService.getEmailTemplateEdit('startup-invite').then(response => {
                    this.template.subject = response.data.subject
                    this.template.body = response.data.body

                    this.$refs.editEmailTemplate.open(this.template.subject, this.template.body)
                })
            }
            else {
                this.$refs.editEmailTemplate.open(this.template.subject, this.template.body)
            }
        },

        setTemplate(template) {
            this.template = template
            this.$bvModal.show(`modal-add-startup-${this.prefix}`)
        },

        getTagsList() {
            tags.getList().then(response => {
                this.tagsList = response.data
            })
        },

        parseErrors() {
            this.customFieldsErrors = ('custom_fields' in this.errors)
                ? this.errors.custom_fields.reduce((acc, {index, error}) => {
                    acc[index] = error;
                    return acc;
                }, {})
                : {};
        }
    }
}
</script>

<style scoped>

</style>
