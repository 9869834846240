<script setup>
import AccessSettings from "@/components/trackers/AccessSettings.vue";
import {ref, computed, inject, watch} from "vue";
import {trackerAccess} from "../../../helpers/string";
import {trackerTypes} from "@/constants/trackers";
import {useStore} from "vue2-helpers/vuex";

const store = useStore();
const tracker = inject('tracker');
const enableEcosystemSharing = ref(false);
const canChangeVisibility = computed(() => tracker.userPermissions.includes('tracker-add-ecosystem'));
const accessSettingsModal = ref(null);

watch(() => tracker.accessType, (value) => {
    enableEcosystemSharing.value = value !== 'private';
}, {immediate: true});

function setSharing() {
    if (enableEcosystemSharing.value) {
        accessSettingsModal.value.open();
    } else {
        tracker.accessType = trackerTypes.PRIVATE;
        tracker.ecosystems = [];
    }
}

function accessSettingsChanged(params) {
    tracker.accessType = params.accessType;
    tracker.ecosystems = params.ecosystems;
}

function accessSettingsCancelled() {
    if (tracker.accessType === 'private') {
        enableEcosystemSharing.value = false;
    }
}
</script>

<template>
    <div v-if="store.getters.isEnterprise && !store.getters.isEventLimited && tracker.type === trackerTypes.PRIVATE && (enableEcosystemSharing || canChangeVisibility)">
        <h5 class="heading5 heading5--secondary mb-2">Ecosystem Sharing</h5>

        <div class="form__item">
            <div class="input-checkbox mb-4">
                <input
                    v-model="enableEcosystemSharing"
                    id="enable-ecosystem-sharing"
                    type="checkbox"
                    :disabled="!canChangeVisibility"
                    @change="setSharing"
                >
                <label for="enable-ecosystem-sharing">Enable ecosystem sharing</label>
            </div>

            <div class="shared-ecosystem-label" v-if="tracker.accessType !== trackerTypes.PRIVATE && canChangeVisibility">
                <p style="line-height: normal" class="modal-text"> {{trackerAccess(tracker)}} <a class="heading-edit" @click="accessSettingsModal.open();"></a></p>
            </div>
        </div>

        <AccessSettings
            v-if="canChangeVisibility"
            ref="accessSettingsModal"
            :init-access-type="tracker.accessType"
            :init-ecosystems="tracker.ecosystems"
            v-on:saved="accessSettingsChanged"
            v-on:cancelled="accessSettingsCancelled"
        />
    </div>
</template>

<style scoped>

</style>
