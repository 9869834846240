<template>
    <section class="dashboard dashboard--dashboard">
        <header class="dashboard__header">
            <h3 class="heading3">Dashboard - Startups on Trackers</h3>
            <a v-if="false" @click="$emit('customize')" class="link link--dashboard">Customize Dashboard</a>

            <div v-if="false" class="sort-section">
                <a class="sort-section__up" href="#"></a>
                <a class="sort-section__down" href="#"></a>
            </div>
        </header>

        <div v-if="showStartInnovating" class="dashboard__content charts__no__data">
            <h4 class="heading4 mb-4">Start Innovating</h4>
            <p>Create trackers and add startups to see trends, categories and other data.</p>
            <button v-if="$store.state.user.permissions.indexOf('tracker-create') >= 0" @click="$refs.addTracker.open(0)" type="button" class="btn btn-primary btn-lg btn-secondary">Create A Tracker</button>

            <edit-tracker
                ref="addTracker"
                prefix="-header"
                :folder-id="0"
            />
        </div>

        <div v-else class="dashboard__content charts">
            <div v-for="(chartsRow, index) in structuredLayout"
                 :key="index"
                 class="charts-row">


                <div v-for="chart in chartsRow"
                    :key="chart.i"
                    class="dashboard__content__inner"
                    :class="'w-' + chart.w">

                    <div class="dashboard__content__component">
                        <component
                            :ref="chart.src"
                            :is="chart.src"
                            :chartSettings="chart"
                            :filters="filters"
                            @showStartups="(params) => showStartups(chart.src, params)"
                        />
                    </div>

                </div>
            </div>

            <StartupsModal
                ref="startupsModal"
                :filters="filters"
                @startupRemovedFromTracker="refreshChartData"/>
        </div>
    </section>
</template>
<script>
import {mapGetters} from "vuex";
import Highcharts from "highcharts"

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
})

export default {
    data() {
        return {
            currentChart: null
        }
    },

    components: {
        StartupsLocationChart: () => import("@/components/charts/StartupsLocationChart"),
        StartupsCategoryChart: () => import("@/components/charts/StartupsCategoryChart"),
        StartupsStageChart: () => import("@/components/charts/StartupsStageChart"),
        StartupsStageColumnChart: () => import("@/components/charts/StartupsStageColumnChart"),
        MeetingsTrackersChart: () => import("@/components/charts/MeetingsTrackersChart"),
        ScreenedByTrackersChart: () => import("@/components/charts/ScreenedByTrackersChart"),
        editTracker: () => import("@/components/trackers/trackerEdit/TrackerEdit.vue"),
        TrackerStagesFunnelChart: () => import("@/components/charts/TrackerStagesFunnelChart"),
        StartupsByDateAndTrackerChart: () => import("@/components/charts/StartupsByDateAndTrackerChart"),
        StartupsByYearChart: () => import("@/components/charts/StartupsByYearChart"),
        UniqueStartupsTrackedCounterChart: () => import("@/components/charts/UniqueStartupsTrackedCounterChart.vue"),
        CompaniesByTrackersAndCategoriesChart: () => import("@/components/charts/CompaniesByTrackersAndCategoriesChart.vue"),
        StartupsByRegionChart: () => import("@/components/charts/StartupsByRegionChart.vue"),
        CustomFieldFundingScatterChart: () => import("@/components/charts/private/mars/CustomFieldFundingScatterChart.vue"),
        StartupsByProblemStatementChart: () => import("@/components/charts/private/mars/StartupsByProblemStatementChart.vue"),
        StartupsBySourceChart: () => import("@/components/charts/private/mars/StartupsBySourceChart.vue"),
        TopInvestorsChart: () => import("@/components/charts/private/mars/TopInvestorsChart.vue"),
        StartupsModal: () => import("@/components/charts/StartupsModal"),
    },

    props: {
        filters: Object,
    },

    computed: {
        ...mapGetters({
            layout: 'dashboard/layout',
            isNoLayoutSettings: 'dashboard/isNoLayoutSettings',
            trackersList: 'trackersList',
        }),

        showStartInnovating() {
            return !this.trackersList.length && this.isNoLayoutSettings
        },

        structuredLayout() {
            let structuredLayout = []

            // group by value of 'y' prop to be able to get charts by row
            structuredLayout = this.arrayHelper.groupObjectsBy(this.layout, 'y')

            // sort charts in row
            structuredLayout.forEach((row) => {
                row.sort((a, b) => a.x - b.x)
            });

            // sort rows of charts
            structuredLayout.sort((a, b) =>  a[0].y - b[0].y)

            return structuredLayout
        },
    },

    methods: {
        showStartups(chart, params) {
            this.currentChart = chart
            this.$refs.startupsModal.open(...params)
        },

        refreshChartData() {
            this.$refs[this.currentChart][0].fetchChartData()
        },
    }
}
</script>
