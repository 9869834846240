<template>
    <b-modal v-model="showModal" modal-class="accessSettings" hide-footer @hide="beforeClose">
        <template slot="modal-title">
            <template v-if="inviteEcosystem">
                <div class="d-flex">
                    <a class="modal__link-back" @click="inviteEcosystem = null"></a>
                    Invite Ecosystem
                </div>
            </template>
            <template v-else>
                Ecosystem Visibility
            </template>
        </template>

        <div class="form" v-if="inviteEcosystem">
            <p class="modal-text">Enter an email for your contact at {{inviteEcosystem.name}} or request that SwitchPitch contact them on your behalf:</p>

            <template v-if="typeof errors.email !== 'undefined'">
                <span class="form__error" v-for="(error, index) in errors.email" :key="index">{{error}}</span>
            </template>
            <template v-if="typeof errors.url !== 'undefined'">
                <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
            </template>

            <div class="d-flex mb-4">
                <b-form-input class="form-control--mid" v-model="inviteEmail" placeholder="Enter Email"></b-form-input>

                <button v-if="!loading" :disabled="!canInvite" type="button" class="button button--sm button--sm--wide ml-3" @click="invite">Invite</button>
            
                <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </div>
        </div>

        <template v-else>
            <p class="mb-4 modal-text">Select which ecosystems will have the ability to view & contribute to your tracker</p>

            <div class="form__item">
                <div v-if="fromTrackerPage" class="input-radio">
                    <input type="radio" value="private" v-model="accessType" id="access-private">
                    <label for="access-private">
                        <p class="modal-text"><b>None</b> - Only members of your organization can view & edit</p>
                    </label>
                </div>

                <div class="input-radio">
                    <input type="radio" value="all" v-model="accessType" id="access-all">
                    <label for="access-all">
                        <p class="modal-text"><b>All</b> - All SwitchPitch ecosystems can view your tracker</p>
                    </label>
                </div>

                <div class="input-radio">
                    <input type="radio" value="categories" v-model="accessType" id="access-categories">
                    <label for="access-categories">
                        <p class="modal-text"><b>Category</b> - Only ecosystems in your selected categories can see your tracker</p>
                    </label>
                </div>

                <div class="input-radio">
                    <input type="radio" value="connections" v-model="accessType" id="access-connections">
                    <label for="access-connections">
                        <p class="modal-text"><b>Connections</b> -  Only ecosystems you're connected with can see your tracker</p>
                    </label>
                </div>

                <div class="input-radio">
                    <input type="radio" value="custom" v-model="accessType" id="access-custom">
                    <label for="access-custom">
                        <p class="modal-text"><b>Custom</b> -  Only ecosystems you select can see your tracker</p>
                    </label>
                </div>

                <ul class="rows-list mb-3" v-if="accessType !== 'private'">
                    <li class="rows-list__form rows-list__form--transparent flex-sm-nowrap flex-wrap">

                        <multiselect class="multiselect-mid mb-sm-0 mb-4"
                                     v-model="addEcosystem"
                                     :options="actualEcosystemsList"
                                     placeholder="Type to search and select ecosystems"
                                     select-label=""
                                     deselect-label=""
                                     label="name"
                                     track-by="name"
                                     @search-change="getEcosystemsList"
                        >
                            <template slot="option" slot-scope="props">
                                <div class="d-flex align-items-center">
                                    <div class="image-block mr-2">
                                        <img :src="LogoHelper.getLogo(props.option.logo)" alt="">
                                    </div>
                                    {{ props.option.name }}

                                    <span v-if="props.option.status.length" :class="props.option.status">{{stringHelper.ucFirst(props.option.status)}}</span>
                                </div>
                            </template>
                        </multiselect>
                    </li>

                    <li v-for="(ecosystem, index) in ecosystems" :key="index">

                        <div class="image-block mr-2">
                            <img :src="LogoHelper.getLogo(ecosystem.logo)" alt="">
                        </div>

                        <h5 class="heading5 heading5--secondary">{{ ecosystem.name }}</h5>

                        <a @click="ecosystems.splice(index, 1)" class="remove-action" href="#">Remove</a>
                    </li>
                </ul>
            </div>
        </template>

        
        <template v-if="inviteEcosystem">
            <footer class="modal-footer">
                <p>Don't have an email?</p>
                <button class="btn btn-bordered" variant="primary" @click="requestConnection">Request Connection</button>
            </footer>
        </template>
    
        <template v-else>
            <footer class="modal-bottom">
                <b-button class="btn-lg" variant="primary" @click="save" :disabled="(accessType == 'private' && !fromTrackerPage) || (accessType == 'custom' && !ecosystems.length)">Save</b-button>
            </footer>
        </template>
    </b-modal>
</template>

<script>
import multiselect from "vue-multiselect";
import ecosystems from "@/services/ecosystems.service";

export default {
    props: {
        fromTrackerPage: {
            type: Boolean,
            default: false
        },
        initAccessType: String,
        initEcosystems: Array,
    },

    components: {
        multiselect,
    },

    data() {
        return {
            showModal: false,
            accessType: '',
            addEcosystem: null,
            ecosystems: [],
            ecosystemsList: [],
            inviteEcosystem: null,
            inviteEmail: "",
            loading: false,
            errors: {},
            saved: false,
        }
    },

    watch: {
        addEcosystem() {
            if (this.addEcosystem) {
                if (this.addEcosystem.status === "invite") {
                    this.inviteEcosystem = this.addEcosystem
                    this.inviteEmail = ""
                }
                this.ecosystems.push(this.addEcosystem)
                this.addEcosystem = null
            }
        },
    },

    computed: {
        actualEcosystemsList() {
            let array = []
            this.ecosystemsList.forEach(item => {
                if (!this.arrayHelper.exists(this.ecosystems, 'id', item.id)) {
                    array.push(item)
                }
            })

            return array
        },

        canInvite() {
            return this.stringHelper.validEmail(this.inviteEmail)
        },
    },

    mounted() {
        this.getEcosystemsList("")
    },

    methods: {
        open() {
            this.saved = false
            this.accessType = this.initAccessType
            this.ecosystems = this.arrayHelper.cloneArray(this.initEcosystems)
            this.showModal = true;
        },

        getEcosystemsList(search) {
            ecosystems.getList(search).then(response => {
                this.ecosystemsList = response.data
            })
        },

        save() {
            this.saved = true
            this.$emit('saved', {accessType: this.accessType, ecosystems: this.ecosystems})
            this.showModal = false;
        },

        beforeClose() {
            if (!this.saved) {
                this.$emit('cancelled')
            }
        },

        invite() {
            this.loading = true

            ecosystems.addConnection({id: this.inviteEcosystem.id,  email: this.inviteEmail}).then(response => {
                this.loading = false
                this.errors = response.data.errors

                if (!Object.keys(this.errors).length) {
                    this.inviteEcosystem = null
                }
            })
        },

        requestConnection() {
            this.loading = true

            ecosystems.addConnectionRequest({id: this.inviteEcosystem.id}).then(() => {
                this.loading = false
                this.inviteEcosystem = null
            })
        }
    }
}
</script>

<style scoped>

</style>
