<script setup>
import {ref, reactive, watch, computed, inject} from "vue";
import useForm from "@/composables/useForm";
import {encodeDateForServer} from "@/helpers/date";
import articlesService from "@/services/articles.service";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import DatePicker from "vue2-datepicker";

const emit = defineEmits(['saved']);

const startupId = inject('companyId');
const article = reactive({
    title: "",
    url: "",
    posted_on: "",
});

const showModal = ref(false);
const date = ref(null);
const loading = ref(false);
const {errors, hasErrors, clearErrors, resetForm} = useForm(article);

const canSave = computed(() => {
    return article.title.length && article.url.length && date.value instanceof Date;
});

watch(date, () => {
    if (date.value) {
        article.posted_on = encodeDateForServer(date.value);
    } else {
        article.posted_on = "";
    }
});

async function save() {
    if (canSave.value) {
        loading.value = true;
        clearErrors();

        try {
            const {data} = await articlesService.save(0, startupId, article);

            if (!hasErrors(data)) {
                showModal.value = false;
                emit('saved');
            }
        } finally {
            loading.value = false;
        }
    }
}

function open() {
    resetForm();
    date.value = null;
    showModal.value = true;
}

defineExpose({
    open
})
</script>

<template>
    <b-modal v-model="showModal" title="Add Article" modal-class="modal-w-md">
        <div class="form">
            <div class="row">
                <div class="col-12 mb-3">
                    <b-form-input class="form-control--mid" v-model="article.title" placeholder="Article Title*"></b-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 mb-3">
                    <DatePicker
                        v-model="date"
                        class="mx-datepicker-range"
                        format = "MM/DD/YYYY"
                        placeholder="Article date*"
                    />
                </div>

                <div class="col-12 col-md-8 mb-3">
                    <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                    <b-form-input class="form-control--mid" v-model="article.url" placeholder="Article URL*"></b-form-input>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <PrimaryButton
                :disabled="!canSave"
                :loading="loading"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
