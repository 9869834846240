<script setup>
import { useRoute } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import {ref, watch, computed, defineProps, provide, onMounted, reactive, nextTick} from 'vue';
import useGrid from "@/composables/useGrid";
import useSearch from "@/composables/search/useSearch";
import TopBar from "@/views/search/SearchTopBar.vue";
import PulseLoader from "vue-spinner/src/MoonLoader";
import Grid from "@/components/Grid.vue";
import Radar from "@/views/search/radar/Radar.vue";
import SaveSearch from "@/components/search/SaveSearch.vue";

import {constants, columnsComponentsNames} from "@/views/search/searchConstants";
import globalConstants from "@/constants/constants";
import userService from "@/services/user.service";
import usePromiseAll from "@/composables/usePromiseAll";
import searchService from "@/services/search.service";
import SideBar from "@/views/search/SearchSideBar.vue";
import useSavedSearches from "@/composables/search/useSavedSearches";
import {ACCOUNT_TYPE_EVENT_LIMITED} from "@/constants/accountTypes";
import SearchGridActions from "@/views/search/SearchGridActions.vue";
import Helper from "@/components/Helper.vue";
import startupsService from "@/services/startups.service";
import ecosystemsService from "@/services/ecosystems.service";

const {customView = null, searchType = constants.SEARCH_TYPE_STARTUPS} = defineProps(['customView','searchType']);
const emit = defineEmits(['invite']);

const store = useStore();
const route = useRoute();

// GET query params
const initSavedSearch = route.query.query;
const searchCategory = route.query.category;
const searchTag = route.query.tag;

// Custom hooks
// -------------------------------------------------------------------------------------------------

// Use Grid
const {gridParams, gridMethods} = useGrid(getList);

// Use Search
const {query, promptQuery, searchParams, filters, customDataFilter, notWatchSearchParams, filtersOptions, suggestedAiParams,
    aiLoading, aiFailed, getAIQueryParams, applyAiQueryParams,
    filtersOptionsMethods: {getInvestorsList, getTopInvestorsList, getLocationsList, getTopLocationsList, getTagsList,
        getTopClientsList, getClientsList, getTopEcosystemsList, getEcosystemsList, getTrackersList},
    addKeyword, deleteRule, deleteAllRules, invertRule, toggleCondition, populateFilters, updateCustomDataFilter} = useSearch(getList);

// Use Saved Searches
const {savedSearch, savedSearches, refinedSavedSearch, getSavedSearches} = useSavedSearches(searchType, initSavedSearch)

// Data
// -------------------------------------------------------------------------------------------------
const items = ref([]);
const loaded = ref(false);
const displayType = ref(constants.DISPLAY_TYPE_LIST);
const leftCollapsed = ref(false);
const asideTabIndex = ref(0);
const elasticQuery = ref(null);
const topBar = ref(null);
const gridActions = ref(null);
const saveSearchModal = ref(null);
const needUpgradeModal = ref(null);
const canUpdateSavedSearch = ref(false);
const canLoadList = ref(false);
const searchString = ref("");
const notShownCount = ref(0);
const searchParamsFromSavedSearch = ref(false);

const addCellData = reactive({
    companyType: searchType,
    fromSearch: true,
});

const loadListResolvers = {
    settings: null,
    columns: null,
    initSavedSearch: null,
    categories: null,
    tags: null
};

// Computed
// -------------------------------------------------------------------------------------------------
const isStartupsSearch = computed(() => searchType === constants.SEARCH_TYPE_STARTUPS);
const isListView = computed(() => displayType.value === constants.DISPLAY_TYPE_LIST);
const isRadarView = computed(() => displayType.value === constants.DISPLAY_TYPE_RADAR);
const showRadarSwitcher = computed(() => isStartupsSearch.value && isListView.value);
const showSpinner = computed(() => store.getters["search/isShownSpinner"]);
const setSpinnerVisibility = (value) => store.commit('search/setSpinnerVisibility', value);

const searchData = computed(() => ({
        rules: query.rules,
        prompt_query: promptQuery,
        search_params: searchParams,
    }));

// Watchers
// -------------------------------------------------------------------------------------------------
watch(() => store.state.user.id, () => {
    applySettings();
});

watch(canLoadList, () => {

    //If Ai prompt exists in url query then postpone list load until AI processed
    if (!(searchType === constants.SEARCH_TYPE_STARTUPS && route.query.ai_prompt && !store.getters.settings.disable_ai)) {
        getList();
    }
});

// Watch applied saved search
watch(refinedSavedSearch, (value) => {
    populateFilters(value.rules);
    query.rules = value.rules;

    if (value.prompt_query) {
        promptQuery.rules = value.prompt_query.rules;
        promptQuery.params = value.prompt_query.params;
    }

    if (value.search_params) {
        for (let key in value.search_params) {
            searchParams[key] = value.search_params[key];
        }

        searchParamsFromSavedSearch.value = true;
    }

    if (!loaded.value) {
        loadListResolvers.initSavedSearch();
    }
});

watch(() => filtersOptions.categories, () => {
    checkCategories();
});

watch(() => filtersOptions.tags, () => {
    checkTags();
});

watch([query, promptQuery], () => {
    if (savedSearch.value) {
        canUpdateSavedSearch.value = true;
    }
});

watch(savedSearch, () => {
    canUpdateSavedSearch.value = false;
});

watch(() => store.getters.trackersList, () => {
    if (store.getters.isEnterprise) {
        getTrackersList();
    }
}, {immediate: true});

watch(() => store.state.needUpdateStartups || store.state.needUpdateUsers, () => {
    if (store.state.needUpdateStartups || store.state.needUpdateUsers) {
        getList();
    }
});

// Lifecycle hooks
// -------------------------------------------------------------------------------------------------
onMounted(() => {
    // Trigger getList() function when all the prerequisites are fulfilled
    usePromiseAll(loadListResolvers, () => {canLoadList.value = true});

    if (!initSavedSearch) {
        loadListResolvers.initSavedSearch();
    }

    getColumns();
    getSavedSearches();
    getTopLocationsList();
    applySettings();
});


// Methods
// -------------------------------------------------------------------------------------------------

function applySettings() {
    let settings = store.getters.settings;

    if (store.state.user.id) {
        if (store.getters.isEnterprise) {
            notWatchSearchParams.value = true;

            if (!searchParamsFromSavedSearch.value) {
                if (store.state.user.company_id === globalConstants.companyPfizer && settings.search_big_companies) {
                    searchParams.includeHidden = true;
                }

                searchParams.includeInactive = !!settings.search_inactive_companies;
                searchParams.includeAcquired = !!settings.search_acquired_companies;
            }

            if (store.state.user.account_type === ACCOUNT_TYPE_EVENT_LIMITED) {
                gridParams.sort.field = 'irl_score';
                gridParams.sort.dir = 'desc';
            }

            nextTick(() => notWatchSearchParams.value = false);

            if (searchCategory) {
                checkCategories();
            } else {
                loadListResolvers.categories();
            }

            if (searchTag) {
                checkTags();
            } else {
                loadListResolvers.tags();
            }

            getTagsList();
            getTopEcosystemsList();
            store.dispatch('fetchTrackers');

            // Add restriction rule
            if (store.getters.isFreeEnterprise) {
                store.state.user.connected_ecosystems.forEach(name => {

                    if (!filters.ecosystems.find(item => item.value === name)) {
                        filters.ecosystems = [
                            ...filters.ecosystems,
                            {
                                name: name,
                                value: name,
                            }
                        ];
                    }
                })
            }
        } else {
            loadListResolvers.tags();
            loadListResolvers.categories();
        }

        if (searchType === constants.SEARCH_TYPE_STARTUPS) {
            getTopClientsList();
        }

        loadListResolvers.settings();
    }
}

async function getColumns() {
    const {data} = await userService.getColumns('search-' + searchType);
    gridParams.columns = [];

    if (searchType === constants.SEARCH_TYPE_STARTUPS) {
        gridParams.columns = data.columns;
        searchParams.includeHidden = !!data.params.include_hidden_startups;
    } else {
        gridParams.columns = data;
    }

    gridParams.columns.forEach(column => {
        if (columnsComponentsNames[column.name] !== undefined) {
            column.component = columnsComponentsNames[column.name];
        }

        if (column.name.startsWith('custom_') || column.name.startsWith('diligence_')) {
            column.component = 'CustomField';
        }
    });

    loadListResolvers.columns();
    getList();
}

// Check search category from GET params
function checkCategories() {
    if (searchCategory && filtersOptions.categories.length && !filters.categories.length) {
        filters.categories = filtersOptions.categories.filter(category => category.value === searchCategory);

        nextTick(() => {
            loadListResolvers.categories();
        });
    }
}

// Check search tag from GET params
function checkTags() {
    if (searchTag && store.getters.tagsLoaded && filtersOptions.tags.length && !filters.tags.length) {
        filters.tags = filtersOptions.tags.filter(tag => tag.value === searchTag);

        nextTick(() => {
            loadListResolvers.tags();
        });
    }
}

async function getList(resetPage = true) {
    if (!canLoadList.value) {
        return;
    }

    if (store.state.user.show_similar) {
        let index = gridParams.columns.findIndex(item => item.name === 'relevancy');

        if (filters.similarTracker) {
            if (index < 0) {
                gridParams.columns.splice(1, 0, {
                    label: 'Relevancy',
                    name: 'relevancy',
                })
            }
        } else {
            if (index >= 0) {
                gridParams.columns.splice(index, 1)
            }
        }
    }

    if (resetPage) {
        gridParams.currentPage = 1;
    }

    setSpinnerVisibility(true);
    elasticQuery.value = null;

    let requestParams = gridMethods.getCommonRequestParams(false);

    if (customView === constants.CUSTOM_VIEW_MY_STARTUPS) {
        requestParams.searchString = searchString.value;
    } else {
        requestParams = {
            ...requestParams,
            searchType,
            query: {
                main: query,
                prompt: promptQuery,
            },

            params: searchParams,
        }
    }

    try {
        const {data} = await (
            customView === constants.CUSTOM_VIEW_MY_STARTUPS
                ? startupsService.getAddedByCompany(requestParams)
                : searchService.getList(requestParams)
        );

        items.value = data.data;
        gridParams.totalRowsCount = parseInt(data.total_count);
        gridParams.currentPage = data.current_page;

        if (!customView) {
            loaded.value = true;
            console.log(data.request);
            elasticQuery.value = JSON.stringify(data.request);

            if ('not_shown_count' in data) {
                notShownCount.value = parseInt(data.not_shown_count);
            }
        }
    } finally {
        setSpinnerVisibility(false);
    }
}

function changeView(viewType) {
    if (viewType === constants.DISPLAY_TYPE_RADAR) {
        asideTabIndex.value = 1
        leftCollapsed.value = false;
    } else {
        asideTabIndex.value = 0;
    }

    displayType.value = viewType;
}

function gridAction(params) {
    if (params.action === 'tag-clicked') {
        let tag = filtersOptions.tags.find(item => item.name === params.tagName);

        if (tag && !filters.tags.some(item => item.name === tag.name)) {
            filters.tags = [...filters.tags, tag];
        }
    } else if (params.action === 'invite') {
        emit('invite', params.rowId);
    } else {
        gridActions.value.action(params);
    }
}

function openSaveSearch() {
    saveSearchModal.value.openSave();
}

function openManageSavedSearches() {
    saveSearchModal.value.openManage();
}

function reloadSavedSearches() {
    getSavedSearches();
}

function updateSavedSearch() {
    saveSearchModal.value.updateQuery(savedSearch.value.id);
    canUpdateSavedSearch.value = false;
}

function searchStringChanged() {
    getList(true);
}

async function updateIsFavorite(id, isFavorited) {
    await ecosystemsService.setFavorite(id, isFavorited);
    items.value.find(startup => startup.id === id).name.is_favorited = isFavorited;
}

function invertRuleLocal(rule, isPromptQuery) {
    if (rule.name === "ecosystems" && store.getters.isFreeEnterprise) {
        needUpgradeModal.value.show();
        return;
    }

    invertRule(rule, isPromptQuery);
}

function deleteRuleLocal(rule, isPromptQuery) {
    if (rule.name === "ecosystems" && store.getters.isFreeEnterprise) {
        needUpgradeModal.value.show();
        return;
    }

    deleteRule(rule, isPromptQuery);
}

function changePage(page) {
    gridParams.currentPage = page;
    getList(false);
}

// Providers
// -------------------------------------------------------------------------------------------------
provide('deleteRule', deleteRuleLocal);
provide('invertRule', invertRuleLocal);
provide('toggleCondition', toggleCondition);
provide('getColumns', getColumns);
provide('updateIsFavorite', updateIsFavorite);
</script>

<template>
    <div>
        <TopBar
            :grid-params="gridParams"
            :search-type="searchType"
            :search-string.sync="searchString"
            :custom-view="customView"
            :ai-loading="aiLoading"
            :ai-failed="aiFailed"
            :query="query"
            :prompt-query="promptQuery"
            :search-params="searchParams"
            :show-radar-switcher="showRadarSwitcher"
            :is-radar-view="isRadarView"
            :can-update-saved-search="canUpdateSavedSearch"
            :suggested-ai-params="suggestedAiParams"
            :elastic-query="elasticQuery"
            :aside-tab-index="asideTabIndex"
            @keywordAdded="addKeyword"
            @changeView="changeView"
            @deleteAllRules="deleteAllRules"
            @saveSearch="openSaveSearch"
            @updateSavedSearch="updateSavedSearch"
            @getAiQueryParams="getAIQueryParams"
            @applyAIQueryParams="applyAiQueryParams"
            @getList="getList"
            @searchStringChanged="searchStringChanged"
        />

        <div>
            <div>
                <main class="main main--scrolled" :class="{'main--collapsed': leftCollapsed}">
                    <SideBar
                        v-if="!customView"
                        ref="topBar"
                        :is-radar-view="isRadarView"
                        :search-type="searchType"
                        :aside-tab-index.sync="asideTabIndex"
                        :saved-search.sync="savedSearch"
                        :saved-searches="savedSearches"
                        :filters="filters"
                        :filters-options="filtersOptions"
                        :custom-data-filter="customDataFilter"
                        :search-params="searchParams"
                        :query="query"
                        @getTopInvestorsList="getTopInvestorsList"
                        @getInvestorsList="getInvestorsList"
                        @updateStartupLocationsList="getLocationsList"
                        @updateClientsList="getClientsList"
                        @updateEcosystemsList="getEcosystemsList"
                        @updateCustomDataFilter="updateCustomDataFilter"
                        @manageSavedSearches="openManageSavedSearches"
                    />

                    <div class="content">
                        <div class="content__inner" style="position: relative">
                            <PulseLoader :loading="showSpinner" :color="constants.spinnerColor"></PulseLoader>

                            <template v-if="items.length">
                                <Radar v-if="isRadarView"
                                       :query="query"
                                       :prompt-query="promptQuery"
                                       :search-params="searchParams"
                                />

                                <Grid
                                    v-else
                                    :columns="gridParams.columns"
                                    :has-checkbox-column="true"
                                    :add-cell-data="addCellData"
                                    :data="items"
                                    :page-length="gridParams.pageLength"
                                    :total-rows-count="gridParams.totalRowsCount"
                                    :sort="gridParams.sort"
                                    :currentPage="gridParams.currentPage"
                                    @changePage="changePage"
                                    @changeSort="gridMethods.changeSort"
                                    @selectedRowsChanged="gridMethods.changeSelectedRows"
                                    @selectAllResults="gridMethods.selectAllResults"
                                    @action="gridAction"/>

                                <div v-if="notShownCount" class="upgrade-membership">
                                    <h5 class="upgrade-membership__title">{{notShownCount}} more companies match this search</h5>
                                    <p>Upgrade your SwitchPitch membership to see them.</p>

                                    <a class="btn btn-primary btn-lg" href="mailto:info@switchpitch.com">Contact Sales</a>
                                </div>
                            </template>

                            <div v-else-if="loaded" class="not-found">
                                <template v-if="searchType === constants.SEARCH_TYPE_STARTUPS">
                                    <h2 class="heading2 heading2--startups">No Results</h2>

                                    <template v-if="!customView && store.getters.isEnterprise && !store.getters.isFreeEnterprise">
                                        <p>If you need help finding good startups, try messaging an ecosystem on the platform. They’d be happy to share their startups with you.</p>

                                        <a :href="'/ecosystems'" class="btn btn-primary btn-lg">View Ecosystems</a>
                                    </template>
                                </template>

                                <template v-if="searchType === constants.SEARCH_TYPE_ECOSYSTEMS">
                                    <h2 class="heading2 heading2--ecosystems">No ecosystems</h2>

                                    <p>Try removing or changing some of your keywords</p>

                                </template>

                                <template v-if="searchType === constants.SEARCH_TYPE_ENTERPRISES">
                                    <h4 class="heading4">No enterprises</h4>

                                    <p>Try removing or changing some of your keywords</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>

        <SaveSearch
            ref="saveSearchModal"
            :search-type="searchType"
            :search-data="searchData"
            :saved-searches-list="savedSearches"
            @saved-search-changed="reloadSavedSearches"
        />

        <SearchGridActions
            ref="gridActions"
            :items="items"
        />

        <Helper
            v-if="searchType === constants.SEARCH_TYPE_STARTUPS && store.getters.isEnterprise"
            page="search"
        />

        <helper
            v-if="searchType === constants.SEARCH_TYPE_ECOSYSTEMS && store.getters.isEnterprise"
            page="ecosystems"
        />

        <b-modal ref="needUpgradeModal" modal-class="modal-show-upgrade modal-w-md" :hide-footer="true" title="Discover over 450,000 more startups">
            <p>Upgrade your SwitchPitch membership to find, access and manage startup relationships.</p>

            <div class="modal-footer">
                <a class="btn btn-primary btn-lg" href="mailto:info@switchpitch.com">Contact Sales</a>
            </div>
        </b-modal>
    </div>
</template>

<style scoped>

</style>
