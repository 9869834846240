<script setup>
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue";
import {ref, computed, onMounted, inject} from "vue";
import {validateNumericValues} from "@/helpers/functions";
import customFieldsService from "@/services/custom-fields.service";
import useConditionalFields from "@/composables/useConditionalFields";

const tracker = inject('tracker');
const trackerId = inject('trackerId');
const showAddFieldModal = ref(false);
const fieldsErrors = ref([]);
const newField = ref(null);
const fieldsList = ref([]);

const allValues = computed(() => tracker.submitterFields.reduce((acc, item) => {
    acc[item.field.id] = item.value;
    return acc;
}, {}));

const {showField, getPossibleOptions} = useConditionalFields(fieldsList, allValues);

const possibleFieldsList = computed(() =>
    fieldsList.value
        .filter(field => showField(field))
        .map(field => ({
            ...field,
            options: getPossibleOptions(field)
        }))
);

const actualFieldsList = computed(() =>
    possibleFieldsList.value
        .filter(field => !tracker.submitterFields.some(item => item.field.id === field.id))
);

const canManageFields = computed(() => tracker.userPermissions.includes('tracker-submitterFields-edit'));
const canFillFields = computed(() => !trackerId || tracker.userPermissions.includes('tracker-submitterFields-value-edit'));

const visibleFields = computed(() => tracker.submitterFields
    .filter(item => showField(item.field))
    .map(item => item.field.id));

onMounted(() => {
    customFieldsService.getFieldsArray('tracker_submit').then(({data}) => {
        fieldsList.value = data;
    })
});

function addField() {
    tracker.submitterFields.push({
        field: newField.value,
        value: {
            text: "",
            options: [],
            files: [],
            links: []
        },
    })

    newField.value = null;
    showAddFieldModal.value = false;
}

function validateFields() {
    let [errors, hasErrors] = validateNumericValues(tracker.submitterFields);
    fieldsErrors.value = errors;

    return !hasErrors;
}

defineExpose({
    fieldsList,
    validateFields,
    visibleFields,
});
</script>

<template>
    <div>
        <div v-if="canManageFields" class="d-flex align-items-baseline justify-content-end mb-4">
            <a @click="showAddFieldModal = true" class="link">+Add More Details Fields</a>
        </div>

        <div v-if="tracker.submitterFields.length" class="modal-edit-creation-tracker__wrapper">
            <div v-for="(field, index) in tracker.submitterFields" :key="field.id" class="mb-4">
                <template v-if="visibleFields.includes(field.field.id)">
                    <h5 class="heading5 heading5--secondary">{{field.field.name}}</h5>

                    <div v-if="index in fieldsErrors">
                        <span class="form__error error">{{fieldsErrors[index]}}</span>
                    </div>

                    <div class="form-control-wrapper">
                        <CustomFieldValue
                            v-if="possibleFieldsList.some(item => item.id === field.field.id)"
                            :field="possibleFieldsList.find(item => item.id === field.field.id)"
                            :init-value="tracker.submitterFields[index].value"
                            :instantUpdate="false"
                            :disabled="!canFillFields"
                            @valueChanged="(value) => tracker.submitterFields[index].value = value"
                        />

                        <a
                            v-if="canManageFields"
                            class="link-remove link-remove--blue link ml-2"
                            @click="tracker.submitterFields.splice(index, 1)"
                        ></a>
                    </div>
                </template>
            </div>
        </div>

        <b-modal v-model="showAddFieldModal" title="Add Field">
            <select v-model="newField" class="selectbox selectbox-mid">
                <option :value="null">
                    Select field
                </option>

                <option v-for="(field, id) in actualFieldsList" :key="id" :value="field">
                    {{field.name}}
                </option>
            </select>

            <template #modal-footer>
                <b-button variant="primary" @click="addField" :disabled="!newField">Add</b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped>

</style>
