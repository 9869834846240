<script setup>
import Multiselect from "vue-multiselect";
import {inject, onMounted, ref, watch} from "vue";
import trackersService from "@/services/trackers.service";
import CustomFieldEdit from "@/components/customFields/CustomFieldEdit.vue";

const tracker = inject('tracker');
const trackerId = inject('trackerId');
const canEditInfo = inject('canEditInfo');
const currentTimeline = ref(null);
const timelinesList = ref([]);
const editPOCModal = ref(null);

watch(() => tracker.timelinePreset, (value) => {
    if (value) {
        if (value.id === -1) {
            tracker.timelinePreset = null;
            editPOCModal.value.open(0);
        } else {
            currentTimeline.value = value;
        }
    }
}, {immediate: true});

onMounted(() => {
    getTimelinesList();
});

async function getTimelinesList() {
    const {data} = await trackersService.getTimelinePresets();

    timelinesList.value = data;

    if (timelinesList.value.length && !trackerId.value) {
        tracker.timelinePreset = timelinesList.value[0];
    }

    timelinesList.value.push({
        id: -1,
        name: "Create New Preset",
        statuses: [],
    });
}

async function updateTimeline(id) {
    const {data} = await trackersService.getTimelinePresets();

    timelinesList.value = [
        ...data,
        {
            id: -1,
            name: "Create New Funnel",
            statuses: [],
        }
    ];

    tracker.timelinePreset = timelinesList.value.find(item => item.id === id);
}

function resetTimeline() {
    tracker.timelinePreset = currentTimeline.value;
}

</script>

<template>
    <div>
        <div class="pipeline-cover">
            <span class="pipeline-cover__status">Tracker statuses:</span>

            <Multiselect
                class="multiselect-mid"
                v-model="tracker.timelinePreset"
                :options="timelinesList"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                :disabled="!canEditInfo"
            />

            <div class="link-wrapper m-0 p-0">
                <a
                    v-if="tracker.timelinePreset && canEditInfo"
                    class="link-wrapper__edit text-nowrap"
                    @click="editPOCModal.open(tracker.timelinePreset.id)"
                >Edit Template</a>
            </div>
        </div>

        <ul v-if="tracker.timelinePreset" class="rows-list rows-list--pipeline list-colors">
            <li v-for="(status, index) in tracker.timelinePreset.statuses" :key="index">
                {{status.name}}

                <div class="list-colors__input">
                    <span class="list-colors__input__text">Color:</span>

                    <div class="list-colors__input__wrapper">
                        <input type="color" disabled :value="status.color">
                    </div>
                </div>
            </li>
        </ul>

        <CustomFieldEdit
            ref="editPOCModal"
            type="tracker_diligence"
            fieldType="POC_preset"
            @saved="updateTimeline"
            @closedWithoutChanges="resetTimeline"
        />
    </div>
</template>

<style scoped>

</style>
