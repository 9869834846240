<script setup>
import ManagerBlock from "@/components/ManagerBlock.vue";
import VideoModal from "@/components/widgets/VideoModal.vue";
import ReleaseNotes from "@/components/ReleaseNotes.vue";
import UpcomingFeatures from "@/components/UpcomingFeatures.vue";
import KnowledgeBase from "@/components/widgets/KnowledgeBase.vue";
import {ref, computed, onMounted, watch} from "vue";
import {pages, pagesIconsClasses} from "@/constants/helperItems";
import helperItemsService from "@/services/helper-items.service";
import constants from "@/constants/constants";
import {useStore} from "vue2-helpers/vuex";

const store = useStore();

const items = ref({
    walkthrough: {},
    knowledge_base: {},
    video: [],
    private: [],
});

const search = ref('');
const filterPage = ref('');
const itemsCount = ref(0);
const lastVersion = ref(null);
const releaseNotes = ref(null);
const upcomingFeatures = ref(null);
const knowledgeBase = ref(null);
const videoModal = ref(null);
const privateItemsCount = ref(6);
const videosCount = ref(6);

const pageFilterOptions = computed(() => ({'': 'All Sections', ...pages}));
const privateItems = computed(() => items.value.private.slice(0, privateItemsCount.value));
const videos = computed(() => items.value.video.slice(0, videosCount.value));

watch(filterPage, () => {
    getItems();
});

onMounted(() => {
    getItems();
    getLastVersion();
});

async function getItems() {
    const {data} = await helperItemsService.getStructuredItems(filterPage.value, search.value);
    items.value = data.items;
    itemsCount.value = data.count;
}

async function getLastVersion() {
    const {data} = await helperItemsService.getLastVersion();
    lastVersion.value = data;
}

function getPrivateItemClass(type) {
    return type === 'walkthrough' ? 'list-resources__walkthrough' : type === 'video' ? 'list-resources__video' : 'list-resources__search';
}

function getPrivateItemName(type) {
    return type === 'walkthrough' ? 'Walkthrough' : type === 'video' ? 'Guide Video' : 'Knowledgebase Article';
}

function showArticle(item) {
    if (item.internal) {
        knowledgeBase.value.open(item);
    } else {
        window.open(item.source_id, '_blank').focus();
    }
}

function showVideo(video) {
    videoModal.value.show(`https://player.vimeo.com/video/${video.source_id}`);
    helperItemsService.setUserWatched(video.id);
}

function startWalkthrough(id) {
    window.pendo.showGuideById(id);
}

function showItem(item) {
    if (item.type === 'walkthrough') {
        startWalkthrough(item.source_id)
    } else if (item.type === 'video') {
        showVideo(item)
    } else {
        showArticle(item)
    }
}
</script>

<template>
    <div>
        <div class="topbar">
            <h2 class="heading2 w-100 mb-3">
                Resources
            </h2>

            <div class="d-flex align-items-center flex-wrap">
                <input class="form-search-primary mb-2 mr-sm-4" v-model="search" v-debounce:500="getItems" placeholder="Search for resources" type="text"/>

                <div class="topbar__selectbox topbar__selectbox--blue mb-2">
                    <b-dropdown :text="pageFilterOptions[filterPage]" class="topbar__selectbox__item">
                        <b-dropdown-item
                            v-for="(pageLabel, pageName) in pageFilterOptions"
                            :key="pageName"
                            @click="filterPage = pageName"
                        >
                            {{pageLabel}}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>

        <div class="main main--dark main--center">
            <aside class="sidebar">
                <template v-if="lastVersion">
                    <h3 class="heading3 mb-4">About</h3>

                    <div class="card card--bordered-slim">
                        <h4 class="heading4 mb-3">SwitchPitch v{{lastVersion.version}}</h4>

                        <p class="mb-0">Released {{lastVersion.release_date}}</p>

                        <footer class="card--bordered-slim__footer">
                            <a class="link link-open" @click="releaseNotes.open()">Release Notes</a>
                            <hr>
                            <a class="link link-open" @click="upcomingFeatures.open()">Upcoming Features</a>
                        </footer>
                    </div>
                </template>

                <template v-if="store.state.user.company_id !== constants.companyPfizer">

                    <h3 class="heading3 mb-4">Need Help?</h3>

                    <div class="card card--bordered-slim">
                        <h4 class="heading4 mb-4">Your Point Person</h4>

                        <ManagerBlock/>
                    </div>
                </template>

                <template v-if="Object.keys(items.knowledge_base).length">
                    <h3 class="heading3 mb-4">Knowledgebase</h3>

                    <div class="card card--bordered-slim">
                        <div v-for="(items, section) in items.knowledge_base" :key="section">
                            <h6 class="heading7">{{ pages[section] }}</h6>

                            <ul class="list-basic">
                                <li v-for="(item, index) in items" :key="index">
                                    <a @click="showArticle(item)">{{item.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </aside>

            <div class="content">
                <template v-if="items.private.length">
                    <h3 class="heading3 mb-4">{{$store.state.user.company_name}} Resources</h3>

                    <div class="card card--bordered-slim card--full">
                        <ul class="list-grid list-resources">
                            <li v-for="(item, index) in privateItems" :key="index" :class="getPrivateItemClass(item.type)">
                                <a
                                    v-if="item.type === 'video'"
                                    class="list-resources__video__image"
                                    @click="showVideo(item)"
                                >
                                    <img :src="item.thumbnail_url" :alt="item.name"/>
                                </a>
                                <div>
                                    <h6 class="heading6">
                                        <a @click="showItem(item)">{{item.name}}</a>
                                    </h6>
                                    <p>{{getPrivateItemName(item.type)}}</p>
                                </div>
                            </li>
                        </ul>

                        <button
                            v-if="items.private.length > privateItems.length"
                            class="button button--bordered--without--icon mx-auto"
                            @click="privateItemsCount += 6"
                        >
                            Show More
                        </button>
                    </div>
                </template>

                <template v-if="videos.length">
                    <h3 class="heading3 mb-4">Guide Videos</h3>

                    <div class="card card--bordered-slim card--full">
                        <ul class="list-grid">
                            <li v-for="(video, index) in videos" :key="index">
                                <a @click="showVideo(video)">
                                    <figure>
                                        <img :src="video.thumbnail_url" :alt="video.name"/>
                                        <figcaption>{{video.name}}</figcaption>
                                    </figure>
                                </a>
                            </li>
                        </ul>

                        <button
                            v-if="items.video.length > videos.length"
                            class="button button--bordered--without--icon mx-auto"
                            @click="videosCount += 6"
                        >
                            Show More
                        </button>
                    </div>
                </template>

                <template v-if="Object.keys(items.walkthrough).length">
                    <h3 class="heading3 mb-4">Walkthroughs</h3>

                    <div class="card card--bordered-slim card--full">
                        <p class="mb-0">Our walkthroughs are your guided tour through SwitchPitch’s features.</p>
                        <p>Click a walkthrough to be automatically taken through each step necessary to perform the action you’re looking for.</p>

                        <ul class="list-grid">
                            <li v-for="(items, section) in items.walkthrough" :key="section">
                                <div class="card-hints">
                                    <header class="card-hints__header" :class="pagesIconsClasses[section]">
                                        {{ pages[section] }}
                                    </header>

                                    <div class="card-hints__content">
                                        <ul class="list-basic list-basic--play">
                                            <li v-for="item in items" :key="item.source_id">
                                                <a @click="() => {startWalkthrough(item.source_id)}">{{item.name}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </template>

                <div v-if="!itemsCount && search.length">
                    We have no resources for “{{search}}”.
                    <p>
                        Use the Send Mesage button on the left panel to contact your account manager and let us know what content we can add that would be helpful.
                    </p>
                </div>
            </div>
        </div>

        <VideoModal
            ref="videoModal"
        />

        <KnowledgeBase
            ref="knowledgeBase"
        />

        <ReleaseNotes
            ref="releaseNotes"
        />

        <UpcomingFeatures
            ref="upcomingFeatures"
        />
    </div>
</template>

<style scoped>

</style>
