<template>
    <b-modal :id="modalId" modal-class="modal-w-456" hide-footer>
        <template #modal-title>
            Customize Homepage
        </template>
        
        <p class="modal-text">Drag sections to reorder, hide to remove them from your homepage. <b>Changes will affect all your users.</b></p>

        <div class="customize-section">
            <draggable v-model="layout" class="mb-4">
                <div class="form__grabbing drag-n-drop-item" inline v-for="section in layout" :key="section">
                    <div class="drag-n-drop-item__content">
                        <h5 class="heading5">{{constants.homepageSections[section]}}</h5>
                        
                        <a class="link-edit" @click="() => {
                            $bvModal.hide(modalId)
                            $emit('customizeDashboard')
                        }" v-if="section === 'dashboard'">Edit</a>
                    </div>
                    
                    <footer class="drag-n-drop-item__footer">
                        <a class="icon-hide" @click="() => {layout = layout.filter(item => item !== section)}">
                            Hide Section
                        </a>
                    </footer>
                </div>
            </draggable>

            <div v-if="hiddenSections.length">
                <h5 class="heading5 heading5--secondary">Hidden sections</h5>
                <div class="drag-n-drop-item" v-for="section in hiddenSections" :key="section">
                    <div class="drag-n-drop-item__content">
                        {{constants.homepageSections[section]}}
                    </div>
                    <footer class="drag-n-drop-item__footer">
                        <a class="icon-show" @click="() => {layout.push(section)}">
                            Show Section
                        </a>
                    </footer>
                </div>
            </div>
        </div>
    </b-modal>
</template>


<script>
    import enterprise from '@/services/enterprise.service'
    import draggable from 'vuedraggable'
    import {mapGetters, mapMutations} from "vuex";

    export default {
        components: {draggable},
        data() {
            return {
                modalId: "customize-homepage-modal",
                layout: [],
            }
        },
        watch: {
            layout: {
                deep: true,
                handler() {
                    this.changeLayout(this.layout)
                    this.saveSettings()
                }
            }
        },
        computed: {
            ...mapGetters({
                initLayout: 'homepage/layout',
            }),

            defaultLayout() {
                let layout = this.$store.state.homepage.defaultLayout;

                if (this.$store.getters.settings.disable_tasks) {
                    layout = layout.filter(item => item !== 'tasks')
                }

                return layout;
            },

            hiddenSections() {
                return this.defaultLayout.filter(section => (this.$store.state.accountType !== 'free' || !['tasks','searches'].includes(section)) && !this.layout.some(item => item === section))
            },
        },
        methods: {
            ...mapMutations({
                changeLayout: 'homepage/changeLayout',
            }),
            saveSettings() {
                enterprise.saveHomepageSettings(this.layout)
            },
            open() {
                this.layout = [...this.initLayout]
                this.$bvModal.show(this.modalId)
            },
            close() {
                this.$bvModal.hide(this.modalId)
            },
        }
    }
</script>
