<script setup>
import {useRoute} from "vue2-helpers/vue-router";
import {ref, onMounted, watch} from "vue";
import {useStore} from "vue2-helpers/vuex";
import publicTrackersService from "@/services/public-trackers.service";
import Application from "@/components/trackers/Application.vue";
import SimpleSignup from "@/views/common/SimpleSignup.vue";
import trackersService from "@/services/trackers.service";

const route = useRoute();
const store = useStore();
const id = route.params.id;
const signup = ref(null);
const showDetails = ref(null);

onMounted(() => {
    checkUser();

    if (route.query['apply']) {
        signup.value.modalAction = 'login';
    }

    document.body.classList.add('public-tracker');
});

async function checkUser() {
    if (store.getters.isLoggedIn) {
        watch(() => store.getters.isEnterprise, async (isEnterprise) => {
            if (isEnterprise) {
                const {data} = await trackersService.checkEnterpriseAccess(id);
                window.location.href = '/trackers/' + data
            } else {
                showDetails.value = true;
            }
        }, {immediate: true});
    } else {
        const {data} = await publicTrackersService.checkCanShowToGuest(id);
        showDetails.value = data;

        if (!showDetails.value) {
            signup.value.show();
        }
    }
}
</script>

<template>
    <main :class="{'enter-page': !showDetails}">
        <template v-if="showDetails !== null">
            <Application
                v-if="showDetails"
                :tracker-id="id"
                @login="signup.show()"
            />

            <header v-else class="header">
                <span class="logo"></span>
            </header>
        </template>

        <SimpleSignup
            ref="signup"
            company-type="startup"
            :disable-hide="!showDetails"
            :return-url="`${route.fullPath}?apply=1`"
            @loggedIn="showDetails = true"
        />
    </main>
</template>

<style scoped>

</style>
