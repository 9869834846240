<script setup>
import useGrid from "@/composables/useGrid";
import useCurrentPageData from "@/composables/useCurrentPageData";
import {ref, inject, watch, toRefs, computed} from "vue";
import TablePager from "@/components/TablePager.vue";
import SearchCompanyName from "@/components/customColumns/SearchCompanyName.vue";
import searchTypes from "@/constants/searchTypes";
import IRLScore from "@/components/customColumns/IRLScore.vue";
import SearchCompanyLocation from "@/components/customColumns/SearchCompanyLocation.vue";

const props = defineProps(["searchQuery", "searchCategories"]);
const {searchQuery, searchCategories} = toRefs(props);
const getStartupsIndexList = inject("getStartupsIndexList");
const {gridParams, gridMethods} = useGrid(getStartups, 10);
const getPageItems = useCurrentPageData();
const startups = ref(null);

const startupsCount = computed(() => {
    return gridParams.totalRowsCount;
});

watch([searchQuery, searchCategories], () => {
    gridParams.currentPage = 1;
    getStartups();
});

function getFilteredStartups(pageParams) {
    return getStartupsIndexList({
        query: searchQuery.value,
        categories: searchCategories.value,
    }, pageParams);
}


async function getStartups() {
    startups.value = await getPageItems(getFilteredStartups, gridParams);
    startups.value = startups.value.map(startup => ({
        ...startup,
        cropCategories: true,
    }));
}

function getCategories(row) {
    if (row.cropCategories) {
        return row.categories.slice(0, 3);
    } else {
        return row.categories;
    }
}

function initList() {
    if (startups.value === null) {
        getStartups();
    }
}

defineExpose({
    initList,
    startupsCount
});
</script>

<template>
    <div class="main-table-wrapper main-table-wrapper--light">
        <template v-if="startups !== null">
            <template v-if="startups.length">
                <table class="main-table">
                    <tbody>
                    <tr v-for="startup in startups" :key="startup.id">
                        <td>
                            <SearchCompanyName
                                :column-data="startup.name"
                                :add-data="{
                                companyType: searchTypes.STARTUPS,
                            }"
                                :column-raw-data="startup"
                                :row-id="startup.id"
                            />
                        </td>
                        <td>
                            <IRLScore
                                :column-data="startup"
                            />
                        </td>
                        <td>
                            <SearchCompanyLocation
                                :column-data="startup.location"
                            />
                        </td>
                        <td>
                            <div v-if="startup.categories.length" class="tags-list tags-list--light">
                                <span
                                    v-for="category in getCategories(startup)"
                                    :key="category.id"
                                    class="tags-list__tag"
                                    :title="category"
                                >
                                    {{ category }}
                                </span>

                                <div v-if="startup.categories.length > 3" class="text-center">
                                    <a v-if="startup.cropCategories" class="link" @click="startup.cropCategories = false">+ {{ startup.categories.length - 3 }} more</a>
                                    <a v-else class="link" @click="startup.cropCategories = true">Show less</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <TablePager
                    v-if="startups.length"
                    :pages-count="gridParams.pagesCount"
                    :init-page="gridParams.currentPage"
                    @changePage="gridMethods.changePage"
                />
            </template>

            <div v-else class="no-data-message">
                <h4 class="heading4">No Startups</h4>
                <p>Try changing your search criteria</p>
            </div>
        </template>
    </div>
</template>

<style scoped>
.main-table .main-table__data {
    min-width: auto;
}

.tags-list {
    display: block;
}
</style>
