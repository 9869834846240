<script setup>
import {trackerTypes} from "@/constants/trackers";
import defaults from "@/constants/defaults";
import Folder from "@/components/trackers/trackerEdit/Folder.vue";
import Editor from "@/components/Editor.vue";
import {inject} from "vue";

defineProps(['initFolderId','selectedTemplate','hasTemplates','selectTemplateStep']);
const emit = defineEmits(['update:selectTemplateStep']);

const tracker = inject('tracker');
const canEditInfo = inject('canEditInfo');
</script>

<template>
    <div>
        <ul :class="{'list-template list-template--w-change': !!selectedTemplate, 'list-template-new': !selectedTemplate}">
            <li class="list-template__list">
                <a
                    v-if="selectedTemplate || (tracker.type === trackerTypes.PRIVATE && canEditInfo && hasTemplates)"
                    class="link link-normal"
                    @click="emit('update:selectTemplateStep', true)"
                    style="text-decoration: none"
                >
                    <template v-if="selectedTemplate">
                        <div>
                            <h5 class="heading5 heading5--secondary">{{selectedTemplate.name}}</h5>
                        </div>

                        <span class="link link-normal">Change</span>
                    </template>

                    <template v-else>
                        Change tracker template
                    </template>
                </a>
            </li>
        </ul>

        <b-row class="mb-4 mt-4">
            <b-col cols="12" sm="7" class="mb-4 mb-sm-0">
                <div class="form form-modal">
                    <h5 class="heading5 heading5--secondary">Tracker name</h5>
                    <b-form-input class="form-control--mid" v-model="tracker.name" placeholder="" :disabled="!canEditInfo"></b-form-input>
                </div>
            </b-col>

            <b-col cols="12" sm="5">
                <Folder/>
            </b-col>
        </b-row>

        <h5 class="heading5 heading5--secondary">Description</h5>

        <div class="mb-3">
            <Editor
                v-model="tracker.description"
                :disabled="!canEditInfo"
                :apiKey="apiKeys.tinyMCE"
                :init="defaults.editorOptions"
            />
        </div>
    </div>
</template>

<style scoped>

</style>
