<script setup>

import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import {ref, computed, reactive, watch} from "vue";
import {useStore} from "vue2-helpers/vuex";
import customFieldsService from "@/services/custom-fields.service";
import VueMultiselect from "vue-multiselect";
import accountTypes from "@/constants/accountTypes";
import VueDraggable from "vuedraggable";
import teamService from "@/services/team.service";
import Avatar from "vue-avatar";
import useForm from "@/composables/useForm";
import UserSelect from "@/components/ui/UserSelect.vue";

const {type, sectionId = 0, fieldType} = defineProps(['type', 'sectionId', 'fieldType']);
const emit = defineEmits(['saved', 'closedWithoutChanges']);
const store = useStore();

const showModal = ref(false);
const showDependencyModal = ref(false);
const loading = ref(false);
const id = ref(0);
const hasPermissionsDropdown = ref(null);
const roleDropdown = ref(null);
const disableDefaultPipeline = ref(false);
const formSubmitted = ref(false);
const selectedFieldType = ref({
    type: null,
    name: null,
});
const hasPermissions = ref(false);

const field = reactive({
    name: "",
    description: "",
    type: type,
    fieldType: fieldType,
    options: [],
    minValue: null,
    maxValue: null,
    permissions: [],
    defaultPipeline: null,
    defaultQuestion: null,
    allowStartupPopulation: false,
    dependsOn: null,
    dependsOnOption: null,
});

const {populateForm, resetForm, errors, setErrors, clearErrors} = useForm(field);

const newPermission = reactive({
    holder: null,
    accessType: 'read-only',
});

const permissionHolders = ref(null);
const parentField = ref(null);
const parentFieldOption = ref(null);
const parentFieldsList = ref(null);
const parentOptionsRef = ref([]);
let initTimestamp = null;

const title = computed(() => (id.value ? "Edit " : "Add New ") + (field.fieldType === 'pipeline' ? "Funnel" : (field.fieldType === "POC_preset" ? "Preset" : "Field")));
const fieldTitle = computed(() => (field.fieldType === 'pipeline' ? "Funnel Title" : (field.fieldType === "POC_preset" ? "Preset Title" : "Field Label")));
const placeHolder = computed(() => 'Enter ' + fieldTitle.value);
const addTitle = computed(() => (field.fieldType === 'pipeline' ? "Stage" : (field.fieldType === "POC_preset" ? "Status" : "an option")));
const hasOptions = computed(() => ['radio','checkbox','select','multiselect','pipeline','POC_preset','users_list'].includes(field.fieldType));
const actualPermissionHolders = computed(() => permissionHolders.value?.filter(holder => !field.permissions.some(item => item.type === holder.type && item.id === holder.id)));
const actualUsersList = computed(() => store.getters.usersList.filter(user => !field.options.some(option => parseInt(option.value.id) === parseInt(user.id))));

const canSave = computed(() =>
    field.name.length && field.fieldType && !field.options.some(option => !option.value)
    && !(
        field.fieldType === 'numeric' &&
        (
            !field.minValue || !field.maxValue || parseInt(field.minValue) < 1 || parseInt(field.maxValue) < 1
            || parseInt(field.minValue) > parseInt(field.maxValue))
        )
);

watch(selectedFieldType, (value) => {
    if (value) {
        field.fieldType = value.type;
    }
});

watch(showModal, value => {
    if (!value && !formSubmitted.value) {
        emit('closedWithoutChanges');
    }
})

watch(hasPermissions, value => {
    if (!value) {
        field.permissions = [];
    }
})

watch(showDependencyModal, value => {
    if (value) {
        showModal.value = false;
    } else {
        showModal.value = true;
    }
})

watch(parentField, (value) => {
    if (value) {
        field.dependsOn = value.id;

        parentOptionsRef.value = field.options.reduce((acc, option) => {
            acc.push(value && option.depends_on ? value.options.find(parentOption => parentOption.id === option.depends_on) : null);
            return acc;
        }, []);
    } else {
        field.dependsOn = null;

        field.options.forEach(option => {
            option.depends_on = null;
        });
    }
})

watch(parentFieldOption, (value) => {
    if (value) {
        field.dependsOnOption = value.id;
    } else {
        field.dependsOnOption = null;
    }
})

watch(parentOptionsRef, () => {
    field.options.forEach((option, index) => {
        option.depends_on = parentOptionsRef.value[index]?.id ?? null;
    });
}, {deep: true})

watch([() => field.dependsOn, parentFieldsList],() => {
    if (field.dependsOn && parentFieldsList.value) {
        parentField.value = parentFieldsList.value.find(parentField => parentField.id === field.dependsOn);
    } else {
        parentField.value = null;
    }

    parentFieldOption.value = parentField.value?.options.find(option => option.id === field.dependsOnOption) ?? null;
})

async function getData() {
    const {data: [data, timestamp]} = await customFieldsService.getEdit(id.value);
    populateForm(data);
    initTimestamp = timestamp;

    if (field.fieldType === 'users_list') {
        await store.dispatch('fetchUsersList');


        field.options = field.options
            .filter(option => store.getters.usersList.some(user => parseInt(user.id) === parseInt(option.value)))
            .map(option => ({
                ...option,
                value: store.getters.usersList.find(user => parseInt(user.id) === parseInt(option.value))
            }));
    }

    disableDefaultPipeline.value = !!data.default_pipeline;
    selectedFieldType.value = store.getters.allFieldTypes[type].find(type => type.type === field.fieldType);
    hasPermissions.value = !!data.permissions.length;
}

async function save() {
    loading.value = true;
    clearErrors();

    if (id.value) {
        const {data: updateTimestamp} = await customFieldsService.getUpdateTimestamp(id.value);

        if (updateTimestamp > initTimestamp) {
            if (confirm("This field has been updated by another user. Do you want to load actual data?")) {
                getData();
            }

            loading.value = false;
            return;
        }
    }

    try {
        let form = {
            ...field,
            options: field.options.map(option => ({
                ...option,
                value: option.value.id ?? option.value
            })),
        }
        const {data} = await customFieldsService.save(id.value ?? 0, sectionId, form);

        if (Object.keys(data[1]).length) {
            setErrors(data[1]);
        } else {
            formSubmitted.value = true;
            showModal.value = false;
            emit('saved', data[0]);
        }
    } finally {
        loading.value = false;
    }
}

function addOption() {
    let option = {
        id: 0,
        value: ''
    };

    if (field.type === 'POC_preset') {
        option.color = '#000000';
    }

    field.options.push(option);

    if (parentField.value) {
        parentOptionsRef.value.push(null);
    }
}

async function getPermissionHolders() {
    const {data} = await teamService.getPermissionHolders();
    permissionHolders.value = data;
}

function addPermission() {
    field.permissions.push(
        {
            type: newPermission.holder.type,
            id: newPermission.holder.id,
            name: newPermission.holder.name,
            accessType: newPermission.accessType,
            avatar: newPermission.holder.avatar,
        }
    )

    newPermission.holder = null
}

async function getParentFieldsList() {
    const {data} = await customFieldsService.getParentsList(type, sectionId, id.value);
    parentFieldsList.value = data;
}

async function open(fieldId) {
    store.dispatch('fetchUsersList');
    resetForm();
    selectedFieldType.value = null;
    parentField.value = null;
    parentFieldsList.value = null;
    parentOptionsRef.value = [];

    await store.dispatch('fetchFieldTypes', field.type);

    if (permissionHolders.value === null) {
        getPermissionHolders();
    }

    if (fieldId) {
        id.value = fieldId;
        getData();
    } else {
        id.value = 0;

        if (fieldType) {
            field.fieldType = fieldType;
        }
    }

    getParentFieldsList();

    showModal.value = true;
    formSubmitted.value = false;
}

defineExpose({open});
</script>

<template>
    <div>
        <b-modal v-model="showModal" :title="title" modal-class="modal-w-md">
            <template #modal-title>
                <div class="modal-top">
                    {{ title }}

                    <div v-if="['pipeline','POC_preset'].includes(field.fieldType) && store.state.isAdmin" class="input-checkbox">
                        <input id="dp0" type="checkbox" v-model="field.defaultPipeline" :disabled="disableDefaultPipeline">
                        <label v-if="field.fieldType === 'pipeline'" for="dp0">Default Funnel</label>
                        <label v-else for="dp0">Default Preset</label>
                    </div>
                </div>
            </template>

            <b-row class="mb-4">
                <b-col v-if="field.type === 'pipeline'" cols="12" class=mb-3 >
                    Changing the funnel stages will apply those changes to every tracker that uses this template.
                </b-col>

                <b-col cols="12" sm="6">

                    <h5 class="heading5 heading5--secondary">
                        {{fieldTitle}}*
                    </h5>

                    <b-form-input class="form-control--mid" v-model="field.name" :placeholder="placeHolder"></b-form-input>
                </b-col>

                <b-col cols="12" sm="6">
                    <template v-if="!fieldType && store.getters.allFieldTypes[type] !== undefined">
                        <h5 class="heading5 heading5--secondary">Type*</h5>

                        <VueMultiselect
                            class="multiselect-mid mb-2"
                            v-model="selectedFieldType"
                            :options="store.getters.allFieldTypes[type]"
                            label="name"
                            track-by="name"
                            :allow-empty="false"
                            :disabled="id > 0"
                        />
                    </template>
                </b-col>
            </b-row>

            <template v-if="type === 'company_diligence'">
                <b-row class="mb-4">
                    <b-col cols="12">
                        <h5 class="heading5 heading5--secondary">
                            Description
                        </h5>
                        <b-form-input class="form-control--mid" v-model="field.description" placeholder="Description"></b-form-input>

                        <span v-if="errors.description.length" class="error">{{errors.description[0]}}</span>
                    </b-col>
                </b-row>
            </template>

            <template v-if="hasOptions">
                <h5 class="heading5 heading5--secondary">
                    <template v-if="field.fieldType === 'pipeline'">
                        Stages
                    </template>

                    <template v-else-if="field.fieldType === 'POC_preset'">
                        Statuses
                    </template>

                    <template v-else-if="field.fieldType === 'users_list'">
                        Users
                    </template>

                    <template v-else>
                        Dropdown Options
                    </template>
                </h5>

                <VueDraggable v-model="field.options" class="list-colors mb-2">
                    <div class="form__grabbing drag-n-drop-item" inline v-for="(option, index) in field.options" :key="index">

                        <UserSelect
                            v-if="field.fieldType === 'users_list'"
                            v-model="field.options[index].value"
                            :options="actualUsersList"
                        />

                        <input
                            v-else
                            v-model="field.options[index].value"
                            v-focus
                            class="form-control--light w-50 form-control form-control--borderless"
                        >

                        <div v-if="field.fieldType === 'POC_preset'" class="list-colors__input">
                            <span class="list-colors__input__text">Color:</span>
                            <div class="list-colors__input__wrapper list-colors__input__wrapper--editable">
                                <input type="color" :id="'color-picker-'+index" v-model="field.options[index].color">
                                <label :for="'color-picker-'+index"></label>
                            </div>
                        </div>

                        <a @click="field.options.splice(index, 1)" class="link-remove"></a>
                    </div>
                </VueDraggable>

                <a @click="addOption" class="link">+ Add {{addTitle}}</a>
            </template>

            <div v-if="field.fieldType === 'numeric'" class="mb-4">
                <h5 class="heading5 heading5--secondary">Min Value*</h5>
                <b-form-input type="number" class="form-control--mid" v-model="field.minValue" placeholder="Min value"></b-form-input>

                <h5 class="heading5 heading5--secondary">Max Value*</h5>
                <b-form-input type="number" class="form-control--mid" v-model="field.maxValue" placeholder="Max value"></b-form-input>
            </div>

            <div v-if="field.type === 'tracker_question'" class="input-checkbox">
                <input id="default_question" type="checkbox" v-model="field.defaultQuestion">
                <label for="default_question">Default Question</label>
            </div>

            <h4 class="heading4 mt-3">
                Permissions
                <b-dropdown class="ml-sm-3 dropdown--tertiary" variant="link" ref="hasPermissionsDropdown" toggle-class="text-decoration-none" no-caret>

                    <template v-if="hasPermissions" #button-content>
                        Only users I select can access
                    </template>

                    <template v-else #button-content>
                        All users can access
                    </template>

                    <b-dropdown-header>
                        <ul class="modal-list">
                            <li @click="hasPermissions = false; hasPermissionsDropdown.hide()">
                                <h5 class="heading5 heading5--secondary">All users can access</h5>
                            </li>

                            <li @click="hasPermissions = true; hasPermissionsDropdown.hide()">
                                <h5 class="heading5 heading5--secondary">Only users I select can access</h5>
                            </li>
                        </ul>
                    </b-dropdown-header>

                </b-dropdown>
            </h4>

            <ul class="rows-list mb-3">
                <li class="rows-list__form" v-if="hasPermissions && actualPermissionHolders">
                    <UserSelect
                        v-model="newPermission.holder"
                        :options="actualPermissionHolders"
                    />

                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <template v-if="!['pipeline','POC_preset'].includes(field.fieldType)">
                            <span class="text-nowrap ml-sm-2">Add as:</span>
                            <b-dropdown class="ml-2 dropdown--tertiary" variant="link" ref="roleDropdown" toggle-class="text-decoration-none" no-caret>

                                <template #button-content>
                                    {{ constants.customFieldAccessTypes[newPermission.accessType] }}
                                </template>

                                <b-dropdown-header>
                                    <ul class="modal-list">
                                        <li v-for="(name, accessType) in constants.customFieldAccessTypes" :key="name" @click="newPermission.accessType = accessType; roleDropdown.hide()">
                                            <h5 class="heading5 heading5--secondary">{{ name }}</h5>
                                        </li>
                                    </ul>
                                </b-dropdown-header>

                            </b-dropdown>
                        </template>

                        <b-button @click="addPermission" class="ml-2" variant="primary" size="md" :disabled="!newPermission.holder">Add</b-button>
                    </div>
                </li>

                <template v-if="hasPermissions">
                    <li v-for="(permission, index) in field.permissions" :key="index">
                        <div class="image-block image-block--rounded">
                            <Avatar
                                :username="permission.name"
                                :src="permission.avatar"
                                :size="30"
                            />

                            <img v-if="permission.type === 'user'" :src="permission.avatar" alt=""/>
                        </div>

                        <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                        <b-dropdown v-if="!['pipeline','POC_preset'].includes(field.fieldType)" class="ml-auto dropdown--tertiary modal-list" variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <h5 class="heading5 heading5--secondary text-capitalize">{{constants.customFieldAccessTypes[permission.accessType]}}</h5>
                            </template>

                            <b-dropdown-item v-for="(name, accessType) in constants.customFieldAccessTypes" :key="name" @click="permission.accessType = accessType">
                                <h5 class="heading5 heading5--secondary">{{name}}</h5>
                            </b-dropdown-item>
                        </b-dropdown>

                        <a @click="field.permissions.splice(index, 1)" class="remove-action" href="#">Remove</a>
                    </li>
                </template>
            </ul>

            <b-row
                v-if="type === 'company_diligence'
                    && store.state.companyType === accountTypes.ENTERPRISE
                    && field.fieldType !== 'users_list'"
                class="mb-4"
            >
                <b-col cols="12">
                    <div class="gray-bg-container">
                        <h5 class="heading5 heading5--secondary flex-space-between">
                            <span>Startup Sharing</span>

                            <span class="input-checkbox">
                                <input id="enable-startup-population" type="checkbox" v-model="field.allowStartupPopulation">
                                <label for="enable-startup-population">Enable sharing</label>
                            </span>
                        </h5>

                        <p class="modal-text">Allow your team to send startups a request to populate this field</p>
                    </div>
                </b-col>
            </b-row>

            <div class="gray-bg-container" v-if="['company_diligence','tracker_submit'].includes(type)">
                <h5 class="heading5 heading5--secondary">
                    Dependency
                </h5>

                <p class="modal-text">Make options dependant on selected parent fields</p>

                <div class="flex-line mt-1">
                    <span class="text-nowrap"><b>Parent Field:</b></span>

                    <VueMultiselect
                        v-if="parentFieldsList !== null"
                        class="multiselect-mid light-select ml-2"
                        v-model="parentField"
                        :options="parentFieldsList"
                        placeholder="Select A Field"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                    />
                </div>

                <div v-if="parentField && parentField.options.length" class="flex-line mt-1">
                    <span class="text-nowrap"><b>Parent Option:</b></span>

                    <VueMultiselect
                        class="multiselect-mid light-select ml-2"
                        v-model="parentFieldOption"
                        :options="parentField.options"
                        placeholder="Select Option"
                        select-label=""
                        deselect-label=""
                        label="value"
                        track-by="value"
                    />
                </div>

                <template v-if="selectedFieldType && parentField && field.options.length">
                    <template v-if="['select','users_list'].includes(selectedFieldType.type)">
                        <h5 class="heading5 heading5--secondary text-grey2-light mt-3 mb-3">
                            OPTIONS
                        </h5>

                        <div class="form-grid-2" v-if="Object.keys(parentOptionsRef).length">
                            <div
                                v-for="(option, index) in field.options"
                                :key="index"
                            >
                                <div v-if="option.value">
                                    <div>
                                        <b>{{option.value?.name || option.value}}</b>
                                    </div>

                                    <div class="flex-line">
                                        <VueMultiselect
                                            v-model="parentOptionsRef[index]"
                                            class="multiselect-mid light-select"
                                            :options="parentField.options"
                                            placeholder="Select Parent Option"
                                            select-label=""
                                            deselect-label=""
                                            label="value"
                                            track-by="value"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </div>

            <template #modal-footer>
                <PrimaryButton
                    :loading="loading"
                    :disabled="!canSave"
                    @click="save"
                />
            </template>
        </b-modal>
    </div>
</template>

<style scoped>

</style>
