import { render, staticRenderFns } from "./EcosystemProfile.vue?vue&type=template&id=6811f8b0&scoped=true&"
import script from "./EcosystemProfile.vue?vue&type=script&setup=true&lang=js&"
export * from "./EcosystemProfile.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./EcosystemProfile.vue?vue&type=style&index=0&id=6811f8b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6811f8b0",
  null
  
)

export default component.exports