<script setup>
import {computed, ref} from "vue";
import upcomingFeaturesService from "@/services/upcoming-features.service";

const showModal = ref(false);
const features = ref([]);
const index = ref(0);

const currentFeature = computed(() => features.value[index.value]);

async function getData() {
    const {data} = await upcomingFeaturesService.getFeatures();
    features.value = data;
}

async function open() {
    if (!features.value.length) {
        await getData();
    }

    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="showModal" modal-class="upcoming-features-modal modal-w-xl" title="Upcoming Features">
        <template v-if="features.length">
            <h3>{{currentFeature.title}}</h3>
            <div class="feature-content" v-html="currentFeature.text"></div>
        </template>

        <template #modal-footer>
            <div class="buttons-wrapper">
                <div>
                    <button
                        v-show="index > 0"
                        class="btn btn-bordered"
                        @click="index--"
                    >
                        Previous Feature
                    </button>
                </div>

                <div>
                    <button
                        v-show="index < features.length - 1"
                        class="btn btn-bordered"
                        @click="index++"
                    >
                        Next Feature
                    </button>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<style scoped>

</style>
