<script setup>
import VueAvatar from "vue-avatar";
import {computed} from "vue";

const props = defineProps(['username', 'src', 'size', 'color', 'backgroundColor', 'customStyle', 'initials']);
const croppedName = computed(() => props.username?.split(' ').slice(0, 2).join(' '));
</script>

<template>
    <VueAvatar
        :src="src"
        :username="croppedName"
        :initials="initials"
        :size="size"
        :color="color"
        :customStyle="customStyle"
        :backgroundColor="backgroundColor"
    />
</template>

<style scoped>

</style>
