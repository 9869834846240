<script setup>
import VueMultiselect from "vue-multiselect";
import Avatar from "@/components/ui/Avatar.vue";
import {computed} from "vue";
const props = defineProps(['value', 'options']);
const emit = defineEmits(['input']);

const user = computed({
    get: () => props.value,
    set: (value) => emit('input', value)
});
</script>

<template>
    <VueMultiselect
        v-model="user"
        :options="options"
        class="multiselect-mid col-md-5 mb-2 mb-md-0"
        select-label=""
        deselect-label=""
        label="name"
        track-by="name"
    >
        <template slot="option" slot-scope="props">
            <template v-if="props.option.type === 'group'">
                <div class="d-flex align-items-center">
                    <div class="mr-2">
                        <font-awesome-icon :icon="['fa', 'users']" size="lg"  />
                    </div>
                    {{ props.option.name }}
                </div>
            </template>

            <template v-else>
                <div class="d-flex align-items-center">
                    <div class="mr-2">
                        <Avatar
                            :username="props.option.name"
                            :src="props.option.avatar"
                            :size="30">
                        </Avatar>
                    </div>
                    {{ props.option.name }}
                </div>
            </template>
        </template>
    </VueMultiselect>
</template>

<style scoped>

</style>
