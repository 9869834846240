<script setup>
import {trackerTypes} from "@/constants/trackers";
import {inject, ref} from "vue";
import defaults from "@/constants/defaults";
import Editor from "@/components/Editor.vue";

defineProps(['loading']);
const emit = defineEmits(['save']);

const tracker = inject('tracker');
const trackerId = inject('trackerId');
const canEditInfo = inject('canEditInfo');
const showTermsModal = ref(false);
</script>

<template>
    <footer class="modal__bottom">
        <div v-if="tracker.type === trackerTypes.PUBLIC">
            <a @click="showTermsModal = true" class="link">+ Add Terms and Conditions</a>
        </div>

        <div class="modal__bottom__inner">
            <div class="buttons-wrapper justify-content-end">
                <b-button class="m-0" :disabled="!tracker.name.length || loading" variant="primary" size="lg" @click="emit('save')">
                    <template v-if="trackerId">Update</template><template v-else>Create</template> Tracker
                    <b-spinner class="spinner--button ml-1 mb-1" small label="Saving..." v-if="loading"></b-spinner>
                </b-button>
            </div>
        </div>

        <b-modal v-model="showTermsModal" title="Add Terms and Conditions" no-enforce-focus>
            <Editor
                v-model="tracker.termsAndConditions"
                :disabled="!canEditInfo"
                :apiKey="apiKeys.tinyMCE"
                :init="defaults.editorOptions"
            />

            <template #modal-footer>
                <b-button variant="primary" size="lg" @click="showTermsModal = false">Save</b-button>
            </template>
        </b-modal>
    </footer>
</template>

<style scoped>

</style>
