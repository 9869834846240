import { render, staticRenderFns } from "./ProfileActivities.vue?vue&type=template&id=1e05fc1a&scoped=true&"
import script from "./ProfileActivities.vue?vue&type=script&setup=true&lang=js&"
export * from "./ProfileActivities.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e05fc1a",
  null
  
)

export default component.exports