import axios from '@/axios';

const RESOURCE_NAME = '/public-trackers';

export default {

    getApplicationInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-application-info?id=${id}`)
    },

    checkCanShowToGuest(id) {
        return axios.get(`${RESOURCE_NAME}/check-can-show-to-guest?id=${id}`);
    },
}
