<script setup>

import Signup from "@/components/common/Signup.vue";
import Login from "@/components/common/Login.vue";
import {ref} from "vue";

defineProps(['companyType', 'returnUrl', 'disableHide']);
const emit = defineEmits(['loggedIn','closed']);

const showModal = ref(false);
const modalAction = ref('signup');
const registerStatus = ref(null);
const registerEmail = ref('');

function registered({status, email}) {
    registerStatus.value = status
    registerEmail.value = email
}

function show() {
    showModal.value = true;
}

function loggedIn() {
    showModal.value = false;
    emit('loggedIn');
}

defineExpose({
    show,
    modalAction
});
</script>

<template>
    <b-modal
        v-model="showModal"
        :hide-footer="true"
        modal-class="modal-w-md"
        title=""
        :no-close-on-backdrop="disableHide"
        :hide-header-close="disableHide"
    >
        <template #modal-title>
            <div v-if="modalAction === 'signup' && registerStatus !== 'registered'">
                <div class="modal-top">
                    <h2 class="heading2 mb-0">Sign Up to SwitchPitch</h2>
                </div>

                <p class="mb-0 mt-3">Have an account? <a @click="modalAction = 'login'" class="link">Log In</a></p>
            </div>

            <div v-if="modalAction === 'login'">
                <div class="modal-top">
                    <h2 class="heading2 mb-0">Log In to SwitchPitch</h2>
                </div>
                <p class="mb-0 mt-3">Need an account? <a @click="modalAction = 'signup'" class="link">Sign Up</a></p>
            </div>

            <div v-if="modalAction === 'signup' && registerStatus === 'registered'" class="modal-top">
                <h2 class="heading2 mb-0">Confirm Email</h2>
            </div>
        </template>

        <template v-if="modalAction === 'signup'">
            <template v-if="registerStatus !== 'registered'">
                <Signup
                    :company-type="companyType"
                    :fixed-company-type="true"
                    :return-url="returnUrl"
                    @registered="registered"
                />
            </template>

            <div v-else>
                <div class="form form--enter">
                    <div class="form--enter__container mt-2">
                        <div class="form--enter__fieldset">
                            <p>We sent an email to {{ registerEmail }} to activate your account. Click the link to complete registration.</p>
                            <p>If you don’t receive the email, check your spam folder.</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>


        <template v-if="modalAction === 'login'">
            <Login
                @login="loggedIn"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
