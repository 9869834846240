<script setup>
import {onMounted, ref} from "vue";
import Avatar from "@/components/ui/Avatar.vue";
import agencyClientsService from "@/services/agency-clients.service";
import useProxyLogin from "@/composables/useProxyLogin";

const clients = ref([]);

onMounted(() => {
    getClients();
});

async function getClients() {
    const {data} = await agencyClientsService.getClients();
    clients.value = data;
}

const {login} = useProxyLogin();

</script>

<template>
    <div class="flex-grow-1 bg-white">
        <div class="tracker-section tracker-section--inline">
            <h1 class="heading1 mb-3">My Clients</h1>
        </div>
        <div class="main-inner flex-grow-1">
            <div class="row">
                <div class="col-md-12 col-lg-6" v-for="client in clients" :key="client.id">
                    <div class="card card--agency">
                        <div class="card__content">
                            <Avatar
                                :src="client.logo"
                                :username="client.name"
                                class="image-block image-block--lg"
                                :customStyle="{
                                    backgroundColor: '#fff',
                                    borderRadius: '6px',
                                    boxShadow: '1px 1px 5px 0 rgba(0, 0, 0, 0.15)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    overflow: 'hidden',
                                    marginRight: '31px',
                                    marginBottom: '0',
                                    flex: '0 0 110px',
                                    width: '110px',
                                    height: '110px'
                                }"
                            />

                            <div>
                                <h2 class="heading2 heading2--secondary">{{client.name}}</h2>
                            </div>

                            <a class="btn btn-primary btn-lg ml-auto" @click="login(client.id)">Log Into Account</a>
                        </div>

                        <footer class="card__footer">
                            <span class="card--agency__user">{{client.users_count}} {{stringHelper.getNoun('user', client.users_count)}}</span>
                            <span class="card--agency__activity" v-if="client.last_activity === 'online'">Online</span>
                            <span class="card--agency__activity" v-else>Last seen {{client.last_activity}}</span>
                            <span class="card--agency__trackers">{{ client.trackers_count }} {{stringHelper.getNoun('tracker', client.trackers_count)}}</span>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
