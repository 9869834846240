<template>
    <b-sidebar id="chats-slider" v-model="chatSliderVisible" @hidden="onCloseSlider" right>
        <template #header>
            <div class="messages-slider-header">
                <div class="messages-slider-header__inner">
                    <button type="button" class="close-slider" @click="chatSliderVisible = false"></button>
                    <h2 class="heading2">Messages</h2>
                    <b-dropdown :text="selectedFilterLabel" id="dropdown-1" class="ml-sm-auto dropdown-small icons-list"
                                variant="link" no-caret>
                        <b-dropdown-item
                            v-for="option in filterOptions"
                            :key="option.value"
                            :active="option.value === selectedFilter.value"
                            @click="onSelectFilter(option)"
                        >{{ option.label }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <div class="form-search">
                    <input placeholder="Search messages" type="text" v-model="searchStr" v-debounce:500="search">
                    <button class="form-search__button" @click="newRoom"></button>
                </div>
            </div>
        </template>

        <ul class="messages__list" v-if="chats.length">
            <li v-for="chat in sortedChats" :key="chat.id" @click="openChat(chat.id)"
                :class="{'messages__list__unread': chat.unread}">
                <div class="d-flex">
                    <div class="image-block">
                        <Avatar v-if="chat.recipient_name"
                                :username="chat.recipient_name"
                                :src="chat.logo"
                                :size="48"
                        />
                        <img v-else :src="LogoHelper.getLogo(chat.logo)" alt="">
                    </div>
                    <div>
                        <h5 class="heading5">{{ chat.company_name }}</h5>
                        <p>{{chat.members_shortlist}}</p>
                    </div>
                </div>
            </li>
        </ul>
        <div v-else class="pt-2 text-center">
            <h5>No chat found</h5>
            <p>Try adjusting your search or filter.</p>
        </div>
    </b-sidebar>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex"

    let filterOptions = [
        {
            value: 'all',
            label: 'All messages',
        },
        {
            value: 'internal',
            label: 'Internal'
        },
        {
            value: 'startup',
            label: 'Startups'
        },
        {
            value: 'ecosystem',
            label: 'VCs'
        },
    ]

    export default {
        components: {
            Avatar: () => import("@/components/ui/Avatar.vue")
        },
        props: {
            chatSliderVisibility: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                chatSliderVisible: this.chatSliderVisibility,
                searchStr: "",
                filterOptions: filterOptions,
                selectedFilter: filterOptions[0],
            }
        },
        mounted() {
            this.getChatsList()
        },
        watch: {
            chatSliderVisible() {
                this.$emit('changedVisibility', this.chatSliderVisible)
            },
            chatSliderVisibility() {
                this.chatSliderVisible = this.chatSliderVisibility
            },
            chats: {
                deep: true,
                handler() {
                    this.$emit('newMessage', this.chats.some(chat => chat.unread))
                }
            }
        },
        computed: {
            ...mapGetters(['chats']),
            selectedFilterLabel() {
                return this.selectedFilter.value !== 'all' ? this.selectedFilter.label : 'Filter'
            },
            sortedChats() {
                let sortedChats = [...this.chats]

                // desc sort by last message date
                sortedChats.sort((a, b) => {
                    return this.dateHelper.datesDiff(b.last_message_sent, a.last_message_sent)
                })

                // unread chats first
                sortedChats.sort((a, b) => Number(b.unread) - Number(a.unread))

                return sortedChats
            },
        },
        methods: {
            ...mapMutations(['updateChat', 'chatNewMessageReceived', 'openChat']),
            getChatsList() {
                this.$store.dispatch('fetchChats', { search: this.searchStr, filter: this.selectedFilter.value })
            },
            search() {
                this.getChatsList()
            },
            // selectRoom(chatRoom) {
            //     chatRoom.unread = false
            //     this.$emit('selectRoom', chatRoom)
            // },
            newRoom() {
                this.$emit('newRoom')
            },
            addChat(chat) {
                this.chats.unshift(chat)
            },
            updateSliderChat(updatedChat) {
                if (updatedChat.id) {
                    this.updateChat({id: updatedChat.id, data: updatedChat})
                } else if(updatedChat.chat_id) {
                    this.chatNewMessageReceived({id: updatedChat.chat_id, data: {last_message_sent: updatedChat.message_sent}})
                }

                if (!updatedChat.id && !updatedChat.chat_id) {
                    this.getChatsList()
                }
            },
            markChat(roomId, isRead) {
                let index = this.arrayHelper.findIndexByColumnValue(this.chats, 'id', roomId)

                if (index >= 0) {
                    this.chats[index].unread = isRead
                }
            },
            onSelectFilter(filter) {
                if (this.selectedFilter.value !== filter.value) {
                    this.selectedFilter = filter
                    this.getChatsList()
                }
            },
            onCloseSlider() {
                this.selectedFilter = this.filterOptions[0]
                this.searchStr = ""
                this.getChatsList()
            },
            removeChat(roomId) {
                let index = this.arrayHelper.findIndexByColumnValue(this.chats, 'id', roomId)

                if (index >= 0) {
                    this.chats.splice(index, 1)
                }
            },
        }
    }
</script>
