export default function (fields, values) {
    function getFieldStatus(field) {
        let canShow = showField(field);

        let actualField = canShow ? {
            ...field,
            options: getPossibleOptions(field),
        } : null;

        let needClear = field.depends_on
            && field.id in values.value &&
            values.value[field.id].options.length &&
            !getPossibleOptions(field).some(option => parseInt(option.id) === parseInt(values.value[field.id].options[0].id))

        return {canShow, actualField, needClear};
    }

    function showField(field) {
        return canShowField(field) && (!field.depends_on || !field.options.length || getPossibleOptions(field).length);
    }

    function canShowField(field) {
        if (!field.depends_on) {
            return true;
        }

        // If parent field is filled, then can show this field
        if (values.value[field.depends_on]?.options?.length) {
            if (field.depends_on_option) {
                return values.value[field.depends_on]?.options.some(option => option.id === field.depends_on_option) || false;
            } else {
                return true;
            }
        }

        let parentField = fields.value.find((item) => parseInt(item.id) === parseInt(field.depends_on));

        if (!parentField) {
            return false;
        }

        // If parent field has options, but is not filled, then can't show this field
        if (getPossibleOptions(parentField).length) {
            return false;
        }

        return canShowField(parentField);
    }

    function getPossibleOptions(field) {
        if (field.root_parent_id) {
            let rootParentValue = values.value[field.root_parent_id]?.options;

            if (rootParentValue) {
                return field.options.filter(option => !option.depends_on || rootParentValue
                    .some(parentOption => parseInt(parentOption.id) === parseInt(option.depends_on))
                );
            }

            return [];
        }

        return field.options;
    }

    return {
        getFieldStatus,
        showField,
        getPossibleOptions,
    }
}
