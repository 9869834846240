<script setup>
import {inject, ref} from 'vue'
import useGrid from "@/composables/useGrid";
import useCurrentPageData from "@/composables/useCurrentPageData";
import TablePager from "@/components/TablePager.vue";
import Avatar from "@/components/ui/Avatar.vue";
import accountTypes from "@/constants/accountTypes";
import logoHelper from "@/helpers/logo";
import {useStore} from "vue2-helpers/vuex";
import EditContact from "@/components/contacts/EditContact.vue";
import contactsService from "@/services/contacts.service";

const emit = defineEmits(['updated']);
const store = useStore();
const {gridParams, gridMethods} = useGrid(getContacts, 9);
const getPageItems = useCurrentPageData();

const companyId = inject('companyId');
const companyType = inject('companyType');
const getContactsIndexList = inject('getContactsIndexList');
const companyLogo = inject('companyLogo');

const showModal = ref(false);
const contacts = ref([]);
const search = ref("");
const listLoaded = ref(false);
const editContactModal = ref(null);

function getFilteredContactsList(pageParams) {
    return getContactsIndexList(search.value, pageParams);
}
async function getContacts() {
    contacts.value = await getPageItems(getFilteredContactsList, gridParams);
    listLoaded.value = true;
}

async function deleteContact(contact) {
    if (confirm("Delete contact?")) {
        await contactsService.delete(contact.id);
        await getContacts();
        emit('updated');
    }
}

function contactUpdated() {
    getContacts();
    emit('updated');
}

function addContact() {
    editContactModal.value.open(
        0,
        companyType === accountTypes.STARTUP ? companyId : 0,
        companyType === accountTypes.ECOSYSTEM ? companyId : 0
    );
}

function showContact(contact) {
    showModal.value = false;
    editContactModal.value.open(contact.id);
}

function editContact(contact) {
    showModal.value = false;
    editContactModal.value.open(contact.id, 0, 0, true);
}

function open() {
    if (!listLoaded.value) {
        gridParams.currentPage = 1;
        getContacts();
    }

    showModal.value = true;
}

defineExpose({
    open
});

</script>

<template>
    <b-modal v-model="showModal" title="All Contacts" hide-footer modal-class="modal-contacts" static>
        <header class="modal-contacts__header">
            <input
                v-model="search"
                type="text"
                placeholder="Search by name or title"
                v-debounce:500ms.lock="getContacts"
                class="modal__search"
            />

            <a class="link link--add" @click="addContact">Add Contact</a>
        </header>

        <div class="modal-contacts__list" style="">
            <div
                v-for="contact in contacts"
                class="modal-contacts__card"
                :class="{'with-star': contact.is_primary}"
                :key="contact.id"
                @click="showContact(contact)"
            >
                <div class="modal-contacts__card__content">
                    <div class="image-block image-block--rounded image-block--rounded--mds">
                        <Avatar
                            :username="contact.name"
                            :src="contact.avatar"
                            :size="53"
                        >
                        </Avatar>
                    </div>

                    <div class="modal-contacts__card__inner">
                        <h5 class="heading5">{{ contact.name }}</h5>

                        <p>{{ contact.job_title }}</p>
                    </div>
                    
                    <span class="modal-contacts__card__primary"></span>

                    <div class="dropdown-original dropdown-original--small">
                        <b-dropdown v-if="contact.can_edit" class="icons-list dropdown-simple dropdown-slim position-absolute" boundary="viewport">
                            <b-dropdown-item class="icons-list__edit" @click.stop="editContact(contact)">
                                Edit Contact
                            </b-dropdown-item>

                            <b-dropdown-item v-if="contact.custom" class="icons-list__delete2" @click.stop="deleteContact(contact)">
                                Delete Contact
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>

                <div class="modal-contacts__card__footer">
                    Added by
                    <figure class="modal-contacts__card__footer__image">
                        <img v-if="contact.added_by === 'sp'" src="/apple-touch-icon.png" alt=""/>
                        <img v-if="[accountTypes.ENTERPRISE, accountTypes.ECOSYSTEM].includes(contact.added_by)" :src="logoHelper.getLogo(store.state.user.company_logo)" alt=""/>
                        <img v-if="contact.added_by === accountTypes.STARTUP" :src="logoHelper.getLogo(companyLogo)" alt=""/>
                    </figure>
                </div>

                
            </div>
        </div>

        <TablePager
            v-if="contacts.length"
            :pages-count="gridParams.pagesCount"
            :init-page="gridParams.currentPage"
            @changePage="gridMethods.changePage"
        />

        <EditContact
            ref="editContactModal"
            @contactSaved="contactUpdated"
            @closed="showModal = true"
        />
    </b-modal>
</template>

<style scoped>

</style>
