<template>
    <div>
        <div class="topbar topbar--search">

            <div class="tabs tabs-tertiary">
                <b-nav tabs>
                    <b-nav-item to="/startups" exact-path>
                        Find Startups
                    </b-nav-item>

                    <b-nav-item v-if="$store.state.user.has_added_startups" to="/startups/my-startups" exact>
                        My Startups
                    </b-nav-item>
                </b-nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        current: String
    }
}
</script>

<style scoped>

</style>
