import { render, staticRenderFns } from "./UpcomingFeatures.vue?vue&type=template&id=48f0b828&scoped=true&"
import script from "./UpcomingFeatures.vue?vue&type=script&setup=true&lang=js&"
export * from "./UpcomingFeatures.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f0b828",
  null
  
)

export default component.exports