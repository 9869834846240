import { render, staticRenderFns } from "./ProfileTopBar.vue?vue&type=template&id=394788da&scoped=true&"
import script from "./ProfileTopBar.vue?vue&type=script&setup=true&lang=js&"
export * from "./ProfileTopBar.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ProfileTopBar.vue?vue&type=style&index=0&id=394788da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394788da",
  null
  
)

export default component.exports