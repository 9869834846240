<template>
    <div v-if="trackersLoaded">
        <template v-if="hasTrackers">
            <header class="main-inner__header">
                <h2 v-if="$store.state.accountType === 'premium' && !this.isEcosystem && !hideFilter" class="heading2-dropdown">
                    <b-dropdown class="" :text="filterType2Text" boundary="viewport">
                        <b-dropdown-item v-for="(name, value) in filterTypes2" :key="value" @click="filterType2 = value">
                            {{ name }}
                        </b-dropdown-item>
                    </b-dropdown>
                </h2>

                <a v-if="userCanAddTracker" class="link" @click="$emit('add')">+ New Tracker</a>

                <div class="main-inner__header__info">
                    <p v-if="trackersStatus === 'active'">
                        Showing {{ trackersCount.active }} active {{stringHelper.getNoun('tracker', trackersCount.active)}} <a v-if="trackersCount.archived > 0" @click="trackersStatus = 'archived'" class="link-inherit ml-3">Show archived trackers</a>
                    </p>

                    <p v-if="trackersStatus === 'archived'">
                        Showing {{ trackersCount.archived }} archived {{stringHelper.getNoun('tracker', trackersCount.archived)}} <a v-if="trackersCount.active > 0" @click="trackersStatus = 'active'" class="link-inherit ml-3">Show active trackers</a>
                    </p>

                    <b-dropdown class="dropdown--sort" :text="sortText" boundary="viewport">
                        <b-dropdown-item v-for="(name, value) in sortingTypes" :key="value" @click="sorting = value" :class="{active: sorting === value}">
                            {{ name }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </header>

            <template v-if="hasTrackers">
    <!--    The same but in table   -->
                <div class="card--trackers-wrapper">
                    <div v-for="tracker in trackers" :key="tracker.id" class="card card--bordered card--ordered card--trackers">
                        <header class="card__header">
                            <h4 class="heading4 heading4--secondary">
                                <span v-if="tracker.is_favorite" class="icon-favorite"></span>
                                <router-link :to="'/trackers/' + tracker.id">{{ tracker.name }}</router-link>
                            </h4>
                        </header>

                        <div class="card__inner">
                            <div class="card__inner__side">
                                <p v-if="tracker.type === 'ecosystems'" class="card--trackers__ecosystem">Ecosystem tracker</p>
                                <div class="card--trackers__description line-clamp line-clamp4" v-html="tracker.description"></div>

                                <footer v-if="tracker.type === 'ecosystems'" class="card__footer">
                                    <template v-if="tracker.companies_count > 0">{{ tracker.companies_count }}
                                        ecosystems
                                    </template>
                                    <span v-if="tracker.new_startups_count" class="card__footer__highlighted">({{tracker.new_startups_count }} new)</span>
                                    <span v-if="tracker.companies_count > 0"> • </span>Last modified {{ tracker.last_modified }}
                                </footer>
                                <footer v-else class="card__footer card__footer--icon" :class="{'card__footer--icon-timeline': tracker.visualization_type == 'timeline'}">
                                    {{ trackerTypesLabels[tracker.type] }} • {{ trackerVisualizationTypesLabels[tracker.visualization_type] }}

                                    <template v-if="tracker.companies_count > 0"> • {{ tracker.companies_count }}
                                        startups
                                    </template>
                                    <span v-if="tracker.new_startups_count" class="card__footer__highlighted">
                                        ({{tracker.new_startups_count }} new)
                                    </span> • Last modified {{ tracker.last_modified }}
                                    <template v-if="typeof tracker.folder !== 'undefined'"></template>

                                    <div class="shared-ecosystem-label" v-if="tracker.access_type !== 'private'">
                                        {{ stringHelper.trackerAccess(tracker) }}
                                    </div>
                                </footer>
                            </div>

                            <div class="card__controls">
                                <b-dropdown class="dropdown--secondary ml-auto" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template #button-content>
                                        <div class="section-users section-users--limited">
                                            <div class="section-users__images">
                                                <div v-for="(avatar, index) in stringHelper.getAvatarsForDisplay(tracker.permissions)" :key="index" class="section-users__images__item">
                                                    <img :src="avatar" alt=""/>
                                                </div>
                                            </div>

                                            <p>
                                                <span class="section-users__data">
                                                    <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(tracker.permissions).slice(0, 2)" :key="'1' + index">
                                                        <span v-if="index > 0">, </span>{{ item }}
                                                    </a>
                                                </span>
                                                <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(tracker.permissions).slice(2, 3)" :key="'2' + index">
                                                    {{ item }}
                                                </a>
                                            </p>
                                        </div>
                                    </template>

                                    <b-dropdown-header>
                                        <header class="dropdown__header-inner">
                                            <h4 class="heading4">Users in {{ tracker.name }}</h4>
                                        </header>

                                        <ul class="rows-list">
                                            <li  v-for="(permission, index) in tracker.permissions" :key="index">
                                                <div class="rows-list__avatar">
                                                    <Avatar
                                                        :username="permission.name"
                                                        :src="permission.avatar"
                                                        :size="30">
                                                    </Avatar>
                                                </div>

                                                <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                                                <!-- Please add here the logic first -->
                                                <p class="ml-auto">{{ permission.role.name }}</p>
                                                <a class="link-info" v-b-popover.hover.top="permission.role.description" title="" hideArrow={true}>
                                                </a>
                                            </li>
                                        </ul>
                                    </b-dropdown-header>
                                </b-dropdown>

                                <div class="card__controls__folder" v-if="typeof tracker.folder !== 'undefined'">
                                    Added to <a :href="'/trackers/folder/' + tracker.folder.id" class="link-folder" target="_blank">{{tracker.folder.name}}</a>
                                </div>
                            </div>

                            <b-dropdown v-if="tracker.user_can_edit || tracker.user_can_archive" class="ml-5 dropdown-original icons-list dropdown-small" boundary="viewport">
                                <b-dropdown-item v-if="tracker.user_can_edit" class="icons-list__edit" @click="$emit('edit', tracker.id)">
                                    Edit Tracker
                                </b-dropdown-item>

                                <template v-if="folderId === 0">
                                    <b-dropdown-item v-if="tracker.user_can_edit && folderId === 0" class="icons-list__copy" @click="$emit('duplicate', {id: tracker.id, name: tracker.name, type: tracker.real_type})">
                                        Duplicate Tracker
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="!tracker.is_favorite" class="icons-list__favorite" @click="$emit('setFavorite', {id: tracker.id, favorite: 1})">
                                        Mark As Favorite
                                    </b-dropdown-item>

                                    <b-dropdown-item v-else  class="icons-list__favorite" @click="$emit('setFavorite', {id: tracker.id, favorite: 0})">
                                        Unmark As Favorite
                                    </b-dropdown-item>
                                </template>

                                <b-dropdown-item v-if="tracker.user_can_edit && folderId > 0" class="icons-list__remove" @click="$emit('removeFromFolder', tracker.id)">
                                    <!--<font-awesome-icon :icon="['fa', 'trash']" />-->
                                    Remove From Folder
                                </b-dropdown-item>

                                <b-dropdown-item v-if="tracker.user_can_delete" class="icons-list__delete" @click="$emit('remove', tracker.id)">
                                    <font-awesome-icon :icon="['fa', 'trash']" />
                                    Delete Tracker
                                </b-dropdown-item>

                                <b-dropdown-item v-if="tracker.user_can_archive && tracker.status !== 'archive'" class="icons-list__archive" @click="$emit('archive', tracker.id)">
                                    Archive
                                </b-dropdown-item>

                                <b-dropdown-item v-if="tracker.user_can_archive && tracker.status === 'archive'" class="icons-list__archive" @click="$emit('unarchive', tracker.id)">
                                    Unarchive
                                </b-dropdown-item>

                                <b-dropdown-item v-if="tracker.user_can_edit && folderId === 0" class="icons-list__add" @click="$emit('addToFolder', {tracker, type: tracker.type})">
                                    Add to Folder
                                </b-dropdown-item>
                            </b-dropdown>

                        </div>

                    </div>
                </div>

            </template>
         </template>

        <div v-else class="empty-tracker">
            <a v-if="userCanAddTracker" @click="$emit('add')" class="link">+ New Tracker</a>
            
            <div class="empty-tracker__inner">
                <p>
                    Trackers allow you to keep tabs on startups and perform diligence on them. You can also use trackers to create steps  to move startups through as you gather more information to determine their compatibility with your needs.
                </p>
    
                <div v-if="userCanAddTracker" class="card__controls ml-auto">
                    <b-button variant="primary" size="lg" @click="$emit('add')">Create A Tracker</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import trackers from "@/services/trackers.service"
import Avatar from "vue-avatar";
import scrolledBottom from "@/mixins/scrolledBottom";
import {mapGetters} from "vuex";
import {trackerTypesLabels, visualizationTypesLabels} from "@/constants/trackers";

export default {
    props: {
        filterType1: String,
        folderId: Number,
        search: String,
        userCanAddTracker: Boolean,
        hideFilter: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            trackers: [],

            trackersCount: {
                active: 0,
                archived: 0,
            },

            trackersTotalCount: {
                active: 0,
                archived: 0,
            },

            trackersLoaded: false,

            trackersStatus: 'active',

            filterType2: "all",

            filterTypes1: {
                'all': 'All Trackers',
                'created-by-me': 'Created By Me',
                'related-to-me': 'Related To Me',
            },

            filterTypes2: {
                'all': 'All Trackers',
                'private': 'Private Trackers',
                'public': 'Public Trackers',
                'ecosystems': 'Ecosystem Trackers',
                'POC': 'POC Trackers',
            },

            sortingTypes: {
                'default': 'Default order (Favorite first)',
                'created_at-desc': 'Date created (Newest first)',
                'created_at-asc': 'Date created (Oldest first)',
                'updated_at-desc': 'Date modified (Latest first)',
                'updated_at-asc': 'Date modified (Oldest first)',
                'name-asc': 'Alphabetically (A-Z)',
                'name-desc': 'Alphabetically (Z-A)',
                'startups_count-asc': 'Number of startups (Low to high)',
                'startups_count-desc': 'Number of startups (High to low)',
            },

            sorting: 'default',
            page: 0,
        }
    },

    mixins: [scrolledBottom],

    components: {
        Avatar,
    },

    mounted() {
        this.checkWindowBottom()
    },

    watch: {
        filterType1() {
            this.getTrackersList()
        },

        filterType2() {
            this.getTrackersList()
        },

        sorting() {
            this.getTrackersList()
        },

        trackersStatus() {
            this.getTrackersList()
        },
    },

    computed: {
        ...mapGetters(['isEcosystem']),

        filterType2Text() {
            let text = ''

            if (this.filterType1 !== 'all') {

                if (this.filterType2 !== 'all') {
                    text +=  this.filterTypes2[this.filterType2]
                } else {
                    text +=  'Trackers '
                }

                text += ' ' + this.filterTypes1[this.filterType1]
            }
            else {
                text += this.filterTypes2[this.filterType2]
            }

            return text
        },

        sortText() {
            if (this.sorting === 'default') {
                return "Sort Trackers"
            }
            else {
                return this.sortingTypes[this.sorting]
            }
        },

        hasTrackers() {
            return this.trackersTotalCount.active > 0 || this.trackersTotalCount.archived > 0
        },

        trackerTypesLabels() {
            return trackerTypesLabels;
        },

        trackerVisualizationTypesLabels() {
            return visualizationTypesLabels;
        },
    },

    methods: {

        getData() {
           this.getTrackersNumber()
        },

        getTrackersNumber() {
            trackers.getNumber(this.folderId).then(response => {
                this.trackersTotalCount = response.data

                if (this.hasTrackers) {
                    this.getTrackersList()
                }
                else {
                    this.trackersLoaded = true
                    this.trackersCount.active = 0
                    this.trackersCount.archived = 0
                }
            })
        },

        getTrackersList() {
            if (this.hasTrackers) {
                this.page = 1

                trackers.getTrackersIndex(this.folderId, this.trackersStatus, this.filterType1, this.filterType2, this.search, this.sorting, this.page).then(response => {
                    this.trackers = response.data.trackers
                    this.trackersLoaded = true
                    this.hasMore = response.data.has_more
                    this.trackersCount = response.data.count
                })
            }
        },

        loadMore() {
            this.page++

            trackers.getTrackersIndex(this.folderId, this.trackersStatus, this.filterType1, this.filterType2, this.search, this.sorting, this.page).then(response => {
                this.trackers = this.trackers.concat(response.data.trackers)
                this.hasMore = response.data.has_more
            })
        },
    }
}
</script>

<style scoped>
    .link-info {
        padding: 0;
        background-color: transparent !important;
        border: none;
    }
    .popover {
        top: 50px !important;
        left: auto !important;
        margin: auto !important;
        max-width: 160px !important;
        padding: 5px 10px;
        background: #fff;
        border-radius: 4px;
        color: #7b7b7b !important;
        filter: drop-shadow(1px 3px 4px rgba(#000, 0.3));
        box-shadow: none !important;
        font-size: 10px !important;
        font-weight: 400;
        line-height: 1.5;
    }
    .popover.bs-popover-bottom,
	.popover.bs-popover-top {
		margin-left: 0 !important;
            left: 0 !important;
	}
    .popover.bs-popover-bottom .arrow {
        display: none !important;
        opacity: 0;
        visibility: hidden !important;
    }
    div.popover.b-popover .arrow {display: none !important; visibility:hidden !important;}
</style>
