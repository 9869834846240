<template>
    <div>
        <b-modal id="modal-add-enterprise-connection" title="Add Enterprise Connection">
            Note: Your connection must be approved by the Enterprise company

            <div class="form">
                <template v-if="typeof errors.url !== 'undefined'">
                    <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                </template>
            </div>

            <template #modal-footer>
                <primary-button
                    :loading="loading"
                    caption="Send Connection Request"
                    loading-caption="Sending..."
                    @click="add"
                />
            </template>
        </b-modal>

        <b-modal id="modal-request-enterprise-connection" title="Request Enterprise Connection">
            <template v-if="$store.getters.isStartup">
                <p>
                    Your connection request will be reviewed by the SwitchPitch team. Please explain below why your company would be a good partner for {{name}}.
                </p>

                <b-form-textarea class="form-control--mid" v-model="form.message"></b-form-textarea>

                <div class="tags-list tags-list--light d-flex">
                    <p>Attachments:</p>
                    <span v-for="(file, index) in files" :key="index" class="tags-list__tag">
                        <a :title="file.name">{{file.name}}</a>
                        <a @click="files.splice(index, 1)" class="tags-list__tag__close"></a>
                    </span>

                    <a @click="() => {$bvModal.show('upload-request-file')}" class="link">+ Add File</a>
                </div>

                <b-modal title="Upload File" :id="'upload-request-file'" size="md" hide-footer>
                    <input type="file" multiple ref="newFile" v-on:change="handleFileUpload">
                    <br>
                    <br>
                </b-modal>
            </template>

            <template #modal-footer>
                <primary-button
                    @click="requestConnection"
                    :loading="loading"
                    :disabled="!canSend"
                    caption="Send Connection Request"
                    loading-caption="Sending..."
                />
            </template>
        </b-modal>
    </div>
</template>

<script>

import ecosystemEnterprisesService from "@/services/ecosystem/enterprises.service"
import startupEnterprisesService from "@/services/startup/enterprises.service"
import enterprises from "@/services/ecosystem/enterprises.service"
import Vue from 'vue';
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

export default {
    components: {PrimaryButton},

    data() {
        return {
            id: 0,
            name: "",
            form: {
                enterprise_id: 0,
                message: "",
            },
            files: [],

            errors: {},
            result: "",
            loading: false,
        }
    },

    computed: {
        enterprisesService() {
            if (this.$store.getters.isStartup) {
                return startupEnterprisesService;
            } else {
                return ecosystemEnterprisesService;
            }
        },

        canSend() {
            return this.$store.getters.isEcosystem || this.form.message.length;
        }
    },

    methods: {
        open(id) {
            this.form.enterprise_id = id;
            this.form.message = "";
            this.files = [];

            this.enterprisesService.getDetails(this.form.enterprise_id).then(response => {
                if (this.$store.getters.isEcosystem) {
                    if (response.data.has_account) {
                        if (this.$store.state.user.account_type === 'premium') {
                            this.$bvModal.show('modal-add-enterprise-connection')
                        } else if (this.$store.state.user.account_type == 'free') {
                            this.showNeedUpgrade('enterprises-connections')
                        } else {
                            enterprises.getCounts().then(response => {
                                if (response.data.connected < this.constants.ecosystemLimits['enterprises-connect'][this.$store.state.user.account_type]) {
                                    this.$bvModal.show('modal-add-enterprise-connection')
                                } else {
                                    this.showNeedUpgrade('enterprises-connections')
                                }
                            })
                        }

                    } else {
                        if (this.$store.state.user.account_type === 'premium') {
                            this.$bvModal.show('modal-request-enterprise-connection')
                        } else {
                            enterprises.getCounts().then(response => {
                                if (response.data.connect_requests < this.constants.ecosystemLimits['enterprises-connect-request'][this.$store.state.user.account_type]) {
                                    this.$bvModal.show('modal-request-enterprise-connection')
                                } else {
                                    this.showNeedUpgrade('enterprises-connections')
                                }
                            })
                        }
                    }
                } else {
                    this.name = response.data.name;
                    this.$bvModal.show('modal-request-enterprise-connection');
                }
            })
        },

        add() {
            this.loading = true
            this.errors = {}
            this.result = ""

            let data = {
                id: this.form.enterprise_id,
            }

            this.enterprisesService.addConnection(data).then(response => {
                this.errors = response.data.errors
                this.result = response.data.result

                if (!Object.keys(this.errors).length) {
                    this.$bvModal.hide('modal-add-enterprise-connection');
                    this.$store.commit('setNeedUpdateEnterprises', true);

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data.result
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },

        requestConnection() {
            let data
            if (this.$store.getters.isStartup) {
                data = new FormData();

                for (let i in this.form) {
                    data.append(i, this.form[i])
                }

                for (let i in this.files) {
                    if (typeof this.files[i] === "object") {
                        data.append('file[]', this.files[i])
                    }
                }
            }
            else {
                data = {enterprise_id: this.form.enterprise_id}
            }

            this.loading = true

            this.enterprisesService.addConnectionRequest(data).then(() => {
                this.$bvModal.hide('modal-request-enterprise-connection');
                this.$store.commit('setNeedUpdateEnterprises', true);
                this.$emit('connection-request-sent')

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    text: "Connection Request Sent"
                })
            }).finally(() => {
                this.loading = false
            })
        },

        handleFileUpload() {
            let files = this.$refs['newFile'].files

            for (let i in files) {
                if (typeof files[i] === "object") {
                    files[i].id = 0
                    files[i].file_name = files[i].name
                    this.files.push(files[i])
                }
            }

            this.$bvModal.hide('upload-request-file')
        },
    }
}
</script>

<style scoped>

</style>
