<template>
    <div>
        <b-modal id="modal-add-connection" :title="title">
            <template v-if="companyType === 'enterprise'">
                Note: Your connection must be approved by the Enterprise company
            </template>

            <div class="form mt-3">
                <div>
                    <h5 class="heading5 heading5--secondary">Company Name</h5>
                    <template v-if="typeof errors.name !== 'undefined'">
                        <span class="form__error" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
                    </template>
                    <b-form-input class="form-control--mid mb-3" v-model="name"></b-form-input>
                </div>

                <div>
                    <h5 class="heading5 heading5--secondary">Company URL</h5>
                    <template v-if="typeof errors.url !== 'undefined'">
                        <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                    </template>
                    <b-form-input class="form-control--mid mb-3" v-model="url"></b-form-input>
                </div>

                <div v-if="canInvite">
                    <h5 class="heading5 heading5--secondary">Contact Email</h5>
                    <template v-if="typeof errors.email !== 'undefined'">
                        <span class="form__error" v-for="(error, index) in errors.email" :key="index">{{error}}</span>
                    </template>
                    <b-form-input class="form-control--mid mb-2" v-model="email"></b-form-input>
                </div>

                <template v-if="companyType === 'ecosystem' && canInvite">
                    An invite will be sent to this email. When ecosystems are on SwitchPitch they can share their portfolio with you
                    and help scout startups.
                </template>
            </div>

            <template #modal-footer>
                <div class="buttons-wrapper">
                    <button class="btn btn-bordered btn-lg" @click="editTemplate" v-if="companyType === 'ecosystem' && emailEntered">Customize Invite</button>
                    <button class="btn btn-primary btn-lg" variant="primary" size="lg" :disabled="!canAdd" @click="add">
                        <span v-if="!loading"><template v-if="emailEntered">Send</template><template v-else>Add</template></span>
                        <span v-else><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                    </button>
                </div>
            </template>
        </b-modal>

        <EditEmailTemplate
            ref="editEmailTemplate"
            prefix = "addConnection"
            v-on:saved="setTemplate"
            v-on:close="$bvModal.show('modal-add-connection')"
        />
    </div>
</template>

<script>

import enterprises from "@/services/ecosystem/enterprises.service"
import ecosystems from "@/services/ecosystems.service"
import Vue from 'vue';
import companyService from "@/services/company.service";
import EditEmailTemplate from "@/components/settings/EditEmailTemplate";

export default {
    props: {
        companyType: String,
    },

    components: {
        EditEmailTemplate
    },

    data() {
        return {
            name: "",
            url: "",
            email: "",
            canInvite: false,

            errors: {},
            result: "",
            loading: false,

            template: {
                subject: "",
                body: "",
            },

            emailEntered: false,
        }
    },

    watch: {
        email() {
            let domainParts = this.email.split('@')

            if (domainParts && domainParts.length == 2) {
                this.emailEntered = true
            }
            else {
                this.emailEntered = false
            }
        }
    },

    computed: {
        title() {
            if (this.companyType === 'enterprise') {
                return 'Add Enterprise Connection'
            }
            else if (this.companyType === 'ecosystem') {
                return 'Add/Invite Ecosystem'
            }
            else return ''
        },

        canAdd() {
            if (this.loading) {
                return false
            }
            else {
                return this.url.length && this.name.length
            }
        },
    },

    methods: {
        open() {
            this.name = ""
            this.url = ""
            this.email = ""
            this.errors = {}

            if (this.$store.state.companyType === 'ecosystem' && ['premium','standard','trial'].includes(this.$store.state.accountType)) {
                enterprises.getCounts().then(response => {
                    if (response.data.added < this.constants.ecosystemLimits['enterprises-invite'][this.$store.state.accountType]) {
                        this.$bvModal.show('modal-add-connection')
                        this.canInvite = true
                    }
                    else {
                        this.showNeedUpgrade('enterprises-invitations')
                    }
                })
            }
            else if (this.$store.state.companyType === 'enterprise' && !this.$store.getters.isPremium) {
                ecosystems.getInvitedCount().then(response => {
                    if (response.data < this.constants.enterpriseLimits['ecosystems-invite'][this.$store.state.accountType]) {
                        this.canInvite = true
                    }

                    this.$bvModal.show('modal-add-connection')
                })
            }
            else {
                this.canInvite = true
                this.$bvModal.show('modal-add-connection')
            }
        },

        add() {
            this.loading = true
            this.errors = {}
            this.result = ""

            let data = {
                name: this.name,
                url: this.url,
                email: this.email,
                template: this.template,
            }

            if (this.companyType === 'enterprise') {
                enterprises.addConnection(data).then(response => {
                    this.errors = response.data.errors
                    this.result = response.data.result

                    if (!Object.keys(this.errors).length) {
                        this.$bvModal.hide('modal-add-connection');

                        this.$store.commit('setNeedUpdateEnterprises', true);

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 5000,
                            text: response.data.result
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
            else if (this.companyType === 'ecosystem') {
                ecosystems.addConnection(data).then(response => {
                    this.errors = response.data.errors
                    this.result = response.data.result

                    if (!Object.keys(this.errors).length) {
                        this.$bvModal.hide('modal-add-connection');

                        this.$store.commit('setNeedUpdateEcosystems', true);

                        this.$nextTick(() => {
                            this.$store.commit('setNeedUpdateEcosystems', false);
                        })

                        Vue.notify({
                            group: 'app',
                            type: 'success',
                            duration: 5000,
                            text: response.data.result
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },

        editTemplate() {
            this.$bvModal.hide('modal-add-connection')

            if (!this.template.subject.length) {
                companyService.getEmailTemplateEdit(this.companyType + '-invite').then(response => {
                    this.template.subject = response.data.subject
                    this.template.body = response.data.body

                    this.$refs.editEmailTemplate.open(this.template.subject, this.template.body)
                })
            } else {
                this.$refs.editEmailTemplate.open(this.template.subject, this.template.body)
            }
        },

        setTemplate(template) {
            this.template = template
            this.$bvModal.show('modal-add-connection')
        }
    }
}
</script>

<style scoped>

</style>
