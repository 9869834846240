<template>
    <div>
        <div class="topbar topbar--full pt-4">
            <router-link to="/settings" class="link-back">All Settings</router-link>
            <h2 class="heading1 mb-0">Notifications</h2>
        </div>

        <div class="admin-page">
            <div class="tabs admin-page__tabs">
                <b-nav tabs>
                    <li class="nav-item">
                        <a @click="currentTab = 'startup-trackers'" class="nav-link" :class="{active: currentTab === 'startup-trackers'}">
                            Startup Trackers
                        </a>
                    </li>
                    <li v-if="$store.getters.isPremiumEnterprise" class="nav-item">
                        <a @click="currentTab = 'ecosystem-trackers'" class="nav-link" :class="{active: currentTab === 'ecosystem-trackers'}">
                            Ecosystem Trackers
                        </a>
                    </li>
                    <li class="nav-item">
                        <a @click="currentTab = 'searches'" class="nav-link" :class="{active: currentTab === 'searches'}">
                            Saved Searches
                        </a>
                    </li>
                    <li v-if="!$store.getters.settings.disable_messages" class="nav-item">
                        <a @click="currentTab = 'messages'" class="nav-link" :class="{active: currentTab === 'messages'}">
                            Messages
                        </a>
                    </li>
                </b-nav>
            </div>

            <div class="admin-page__content main-inner">
                <div v-show="currentTab === 'startup-trackers'">
                    <div v-for="(setting, objectType) in settings" :key="objectType">
                        <div v-if="objectType === 'tracker-startups'" class="row">
                            <div class="col-12 col-lg-4 col-xl-4 mb-4">
                                <div class="input-radio mb-3">
                                    <input type="radio" v-model="settings[objectType].is_common" value="1" :id="objectType+'-common-1'" :name="objectType+'-common'">
                                    <label :for="objectType+'-common-1'">Use the same settings for all trackers</label>
                                </div>
                                <div class="input-radio">
                                    <input type="radio" v-model="settings[objectType].is_common" value="0" :id="objectType+'-common-0'" :name="objectType+'-common'">
                                    <label :for="objectType+'-common-0'">Use individual tracker settings</label>
                                </div>
                            </div>

                            <div class="col-12 col-lg-8 col-xl-6">
                                <template v-if="parseInt(settings[objectType].is_common) === 1">
                                    <b-collapse :id="'accordion-' + objectType + '-0'" visible :accordion="'my-accordion-' + objectType" role="tabpanel">
                                        <div class="card card--bordered">
                                            <table class="table-simple">
                                                <thead>
                                                    <tr>
                                                        <th>Event</th>
                                                        <th>Real Time</th>
                                                        <th>Daily</th>
                                                        <th>Weekly</th>
                                                        <th>Never</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ objectType === 'tracker-startups' ? 'Startups' : 'Ecosystems' }} added/removed</td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-link']" value="now" :id="objectType+'-link-now'" :name="objectType+'-link'">
                                                                <label :for="objectType+'-link-now'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-link']" value="day" :id="objectType+'-link-day'" :name="objectType+'-link'">
                                                                <label :for="objectType+'-link-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-link']" value="week" :id="objectType+'-link-week'" :name="objectType+'-link'">
                                                                <label :for="objectType+'-link-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-link']" value="never" :id="objectType+'-link-never'" :name="objectType+'-link'">
                                                                <label :for="objectType+'-link-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Team added/removed</td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['team-member']" value="now" :id="objectType+'-team-member-now'" :name="objectType+'-team-member'">
                                                                <label :for="objectType+'-team-member-now'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['team-member']" value="day" :id="objectType+'-team-member-day'" :name="objectType+'-team-member'">
                                                                <label :for="objectType+'-team-member-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['team-member']" value="week" :id="objectType+'-team-member-week'" :name="objectType+'-team-member'">
                                                                <label :for="objectType+'-team-member-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['team-member']" value="never" :id="objectType+'-team-member-never'" :name="objectType+'-team-member'">
                                                                <label :for="objectType+'-team-member-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>{{ objectType === 'tracker-startups' ? 'Startup' : 'Ecosystem' }} reviewed</td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-reviewed']" value="now" :id="objectType+'-reviewed-now'" :name="objectType+'-reviewed'">
                                                                <label :for="objectType+'-reviewed-now'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-reviewed']" value="day" :id="objectType+'-reviewed-day'" :name="objectType+'-reviewed'">
                                                                <label :for="objectType+'-reviewed-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-reviewed']" value="week" :id="objectType+'-reviewed-week'" :name="objectType+'-reviewed'">
                                                                <label :for="objectType+'-reviewed-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-reviewed']" value="never" :id="objectType+'-reviewed-never'" :name="objectType+'-reviewed'">
                                                                <label :for="objectType+'-reviewed-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>News</td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['news']" value="day" :id="objectType+'-news-day'" :name="objectType+'-news'">
                                                                <label :for="objectType+'-news-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['news']" value="week" :id="objectType+'-news-week'" :name="objectType+'-news'">
                                                                <label :for="objectType+'-news-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['news']" value="never" :id="objectType+'-news-never'" :name="objectType+'-news'">
                                                                <label :for="objectType+'-news-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr v-if="objectType === 'tracker-startups'">
                                                        <td>New funding</td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['new-funding']" value="day" :id="'tracker-new-funding-day'" :name="'tracker-new-funding'">
                                                                <label :for="'tracker-new-funding-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['new-funding']" value="week" :id="'tracker-new-funding-week'" :name="'tracker-new-funding'">
                                                                <label :for="'tracker-new-funding-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications['new-funding']" value="never" :id="'tracker-new-funding-never'" :name="'tracker-new-funding'">
                                                                <label :for="'tracker-new-funding-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Notes / Interactions / Custom Fields updated</td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-interaction']" value="day" :id="objectType+'-interaction-day'" :name="objectType+'-interaction'">
                                                                <label :for="objectType+'-interaction-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-interaction']" value="week" :id="objectType+'-interaction-week'" :name="objectType+'-interaction'">
                                                                <label :for="objectType+'-interaction-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-interaction']" value="never" :id="objectType+'-interaction-never'" :name="objectType+'-interaction'">
                                                                <label :for="objectType+'-interaction-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </b-collapse>
                                </template>

                                <template v-if="parseInt(settings[objectType].is_common) === 0">
                                    <div class="accordion mb-0" role="tablist1">

                                        <div v-for="(tracker, index) in settings[objectType].specific" :key="index">

                                            <header class="accordion__header">
                                                <div v-b-toggle="'accordion-' + objectType + '-' + tracker.id" variant="info" class="accordion__title accordion__title--small">
                                                    {{ tracker.name }}
                                                </div>
                                            </header>

                                            <b-collapse :id="'accordion-' + objectType + '-' + tracker.id" :visible="tracker.id === currentTrackerId || index === 0 && !currentTrackerId" :accordion="'my-accordion-' + objectType" role="tabpanel">
                                                <div class="card card--bordered">
                                                    <table class="table-simple">
                                                        <thead>
                                                            <tr>
                                                                <th>Event</th>
                                                                <th>Real Time</th>
                                                                <th>Daily</th>
                                                                <th>Weekly</th>
                                                                <th>Never</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td>{{ objectType === 'tracker-startups' ? 'Startups' : 'Ecosystems' }} added/removed</td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-link']" value="now" :id="'tracker-' + tracker.id + '-startup-link-now'" :name="'tracker-' + tracker.id + '-startup-link'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-link-now'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-link']" value="day" :id="'tracker-' + tracker.id + '-startup-link-day'" :name="'tracker-' + tracker.id + '-startup-link'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-link-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-link']" value="week" :id="'tracker-' + tracker.id + '-startup-link-week'" :name="'tracker-' + tracker.id + '-startup-link'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-link-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-link']" value="never" :id="'tracker-' + tracker.id + '-startup-link-never'" :name="'tracker-' + tracker.id + '-startup-link'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-link-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Team added/removed</td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['team-member']" value="now" :id="'tracker-' + tracker.id + '-team-member-now'" :name="'tracker-' + tracker.id + '-team-member'">
                                                                        <label :for="'tracker-' + tracker.id + '-team-member-now'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['team-member']" value="day" :id="'tracker-' + tracker.id + '-team-member-day'" :name="'tracker-' + tracker.id + '-team-member'">
                                                                        <label :for="'tracker-' + tracker.id + '-team-member-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['team-member']" value="week" :id="'tracker-' + tracker.id + '-team-member-week'" :name="'tracker-' + tracker.id + '-team-member'">
                                                                        <label :for="'tracker-' + tracker.id + '-team-member-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['team-member']" value="never" :id="'tracker-' + tracker.id + '-team-member-never'" :name="'tracker-' + tracker.id + '-team-member'">
                                                                        <label :for="'tracker-' + tracker.id + '-team-member-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>{{ objectType === 'tracker-startups' ? 'Startup' : 'Ecosystem' }} reviewed</td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-reviewed']" value="now" :id="'tracker-' + tracker.id + '-startup-reviewed-now'" :name="'tracker-' + tracker.id + '-startup-reviewed'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-reviewed-now'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-reviewed']" value="day" :id="'tracker-' + tracker.id + '-startup-reviewed-day'" :name="'tracker-' + tracker.id + '-startup-reviewed'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-reviewed-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-reviewed']" value="week" :id="'tracker-' + tracker.id + '-startup-reviewed-week'" :name="'tracker-' + tracker.id + '-startup-reviewed'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-reviewed-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-reviewed']" value="never" :id="'tracker-' + tracker.id + '-startup-reviewed-never'" :name="'tracker-' + tracker.id + '-startup-reviewed'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-reviewed-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>News</td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['news']" value="day" :id="'tracker-' + tracker.id + '-news-day'" :name="'tracker-' + tracker.id + '-news'">
                                                                        <label :for="'tracker-' + tracker.id + '-news-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['news']" value="week" :id="'tracker-' + tracker.id + '-news-week'" :name="'tracker-' + tracker.id + '-news'">
                                                                        <label :for="'tracker-' + tracker.id + '-news-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['news']" value="never" :id="'tracker-' + tracker.id + '-news-never'" :name="'tracker-' + tracker.id + '-news'">
                                                                        <label :for="'tracker-' + tracker.id + '-news-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr v-if="objectType === 'tracker-startups'">
                                                                <td>New funding</td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['new-funding']" value="day" :id="'tracker-' + tracker.id + '-new-funding-day'" :name="'tracker-' + tracker.id + '-new-funding'">
                                                                        <label :for="'tracker-' + tracker.id + '-new-funding-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['new-funding']" value="week" :id="'tracker-' + tracker.id + '-new-funding-week'" :name="'tracker-' + tracker.id + '-new-funding'">
                                                                        <label :for="'tracker-' + tracker.id + '-new-funding-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['new-funding']" value="never" :id="'tracker-' + tracker.id + '-new-funding-never'" :name="'tracker-' + tracker.id + '-new-funding'">
                                                                        <label :for="'tracker-' + tracker.id + '-new-funding-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Notes / Interactions / Custom Fields updated</td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-interaction']" value="day" :id="'tracker-' + tracker.id + '-startup-interaction-day'" :name="'tracker-' + tracker.id + '-startup-interaction'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-interaction-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-interaction']" value="week" :id="'tracker-' + tracker.id + '-startup-interaction-week'" :name="'tracker-' + tracker.id + '-startup-interaction'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-interaction-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-interaction']" value="never" :id="'tracker-' + tracker.id + '-startup-interaction-never'" :name="'tracker-' + tracker.id + '-startup-interaction'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-interaction-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </b-collapse>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-show="currentTab === 'ecosystem-trackers'">
                    <div v-for="(setting, objectType) in settings" :key="objectType">
                        <div v-if="objectType === 'tracker-ecosystems'" class="d-flex">
                            <div class="col-4">
                                <div class="input-radio mb-3">
                                    <input type="radio" v-model="settings[objectType].is_common" value="1" :id="objectType+'-common-1'" :name="objectType+'-common'">
                                    <label :for="objectType+'-common-1'">Use the same settings for all trackers</label>
                                </div>
                                <div class="input-radio">
                                    <input type="radio" v-model="settings[objectType].is_common" value="0" :id="objectType+'-common-0'" :name="objectType+'-common'">
                                    <label :for="objectType+'-common-0'">Use individual tracker settings</label>
                                </div>
                            </div>

                            <div class="col-6">
                                <template v-if="parseInt(settings[objectType].is_common) === 1">
                                    <div class="card card--bordered">
                                        <table class="table-simple">
                                            <thead>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Real Time</th>
                                                    <th>Daily</th>
                                                    <th>Weekly</th>
                                                    <th>Never</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>{{ objectType === 'tracker-startups' ? 'Startups' : 'Ecosystems' }} added/removed</td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-link']" value="now" :id="objectType+'-link-now'" :name="objectType+'-link'">
                                                            <label :for="objectType+'-link-now'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-link']" value="day" :id="objectType+'-link-day'" :name="objectType+'-link'">
                                                            <label :for="objectType+'-link-day'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-link']" value="week" :id="objectType+'-link-week'" :name="objectType+'-link'">
                                                            <label :for="objectType+'-link-week'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-link']" value="never" :id="objectType+'-link-never'" :name="objectType+'-link'">
                                                            <label :for="objectType+'-link-never'"></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Team added/removed</td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['team-member']" value="now" :id="objectType+'-team-member-now'" :name="objectType+'-team-member'">
                                                            <label :for="objectType+'-team-member-now'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['team-member']" value="day" :id="objectType+'-team-member-day'" :name="objectType+'-team-member'">
                                                            <label :for="objectType+'-team-member-day'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['team-member']" value="week" :id="objectType+'-team-member-week'" :name="objectType+'-team-member'">
                                                            <label :for="objectType+'-team-member-week'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['team-member']" value="never" :id="objectType+'-team-member-never'" :name="objectType+'-team-member'">
                                                            <label :for="objectType+'-team-member-never'"></label>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>{{ objectType === 'tracker-startups' ? 'Startup' : 'Ecosystem' }} reviewed</td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-reviewed']" value="now" :id="objectType+'-reviewed-now'" :name="objectType+'-reviewed'">
                                                            <label :for="objectType+'-reviewed-now'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-reviewed']" value="day" :id="objectType+'-reviewed-day'" :name="objectType+'-reviewed'">
                                                            <label :for="objectType+'-reviewed-day'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-reviewed']" value="week" :id="objectType+'-reviewed-week'" :name="objectType+'-reviewed'">
                                                            <label :for="objectType+'-reviewed-week'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-reviewed']" value="never" :id="objectType+'-reviewed-never'" :name="objectType+'-reviewed'">
                                                            <label :for="objectType+'-reviewed-never'"></label>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>News</td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['news']" value="day" :id="objectType+'-news-day'" :name="objectType+'-news'">
                                                            <label :for="objectType+'-news-day'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['news']" value="week" :id="objectType+'-news-week'" :name="objectType+'-news'">
                                                            <label :for="objectType+'-news-week'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['news']" value="never" :id="objectType+'-news-never'" :name="objectType+'-news'">
                                                            <label :for="objectType+'-news-never'"></label>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr v-if="objectType === 'tracker-startups'">
                                                    <td>New funding</td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['new-funding']" value="day" :id="'tracker-new-funding-day'" :name="'tracker-new-funding'">
                                                            <label :for="'tracker-new-funding-day'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['new-funding']" value="week" :id="'tracker-new-funding-week'" :name="'tracker-new-funding'">
                                                            <label :for="'tracker-new-funding-week'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications['new-funding']" value="never" :id="'tracker-new-funding-never'" :name="'tracker-new-funding'">
                                                            <label :for="'tracker-new-funding-never'"></label>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>Notes / Interactions / Custom Fields updated</td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-interaction']" value="day" :id="objectType+'-interaction-day'" :name="objectType+'-interaction'">
                                                            <label :for="objectType+'-interaction-day'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-interaction']" value="week" :id="objectType+'-interaction-week'" :name="objectType+'-interaction'">
                                                            <label :for="objectType+'-interaction-week'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings[objectType].common[0].notifications[companyType(objectType)+'-interaction']" value="never" :id="objectType+'-interaction-never'" :name="objectType+'-interaction'">
                                                            <label :for="objectType+'-interaction-never'"></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>

                                <template v-if="parseInt(settings[objectType].is_common) === 0">
                                    <div class="accordion mb-0" role="tablist1">
                                        <div v-for="(tracker, index) in settings[objectType].specific" :key="index">
                                            <header class="accordion__header">
                                                <div v-b-toggle="'accordion-' + objectType + '-' + tracker.id" variant="info" class="accordion__title accordion__title--small">
                                                    {{ tracker.name }}
                                                </div>
                                            </header>

                                            <b-collapse :id="'accordion-' + objectType + '-' + tracker.id" visible :accordion="'my-accordion-' + objectType" role="tabpanel">
                                                <div class="card card--bordered">
                                                    <table class="table-simple">
                                                        <thead>
                                                            <tr>
                                                                <th>Event</th>
                                                                <th>Real Time</th>
                                                                <th>Daily</th>
                                                                <th>Weekly</th>
                                                                <th>Never</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td>{{ objectType === 'tracker-startups' ? 'Startups' : 'Ecosystems' }} added/removed</td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-link']" value="now" :id="'tracker-' + tracker.id + '-startup-link-now'" :name="'tracker-' + tracker.id + '-startup-link'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-link-now'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-link']" value="day" :id="'tracker-' + tracker.id + '-startup-link-day'" :name="'tracker-' + tracker.id + '-startup-link'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-link-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-link']" value="week" :id="'tracker-' + tracker.id + '-startup-link-week'" :name="'tracker-' + tracker.id + '-startup-link'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-link-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-link']" value="never" :id="'tracker-' + tracker.id + '-startup-link-never'" :name="'tracker-' + tracker.id + '-startup-link'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-link-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Team added/removed</td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['team-member']" value="now" :id="'tracker-' + tracker.id + '-team-member-now'" :name="'tracker-' + tracker.id + '-team-member'">
                                                                        <label :for="'tracker-' + tracker.id + '-team-member-now'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['team-member']" value="day" :id="'tracker-' + tracker.id + '-team-member-day'" :name="'tracker-' + tracker.id + '-team-member'">
                                                                        <label :for="'tracker-' + tracker.id + '-team-member-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['team-member']" value="week" :id="'tracker-' + tracker.id + '-team-member-week'" :name="'tracker-' + tracker.id + '-team-member'">
                                                                        <label :for="'tracker-' + tracker.id + '-team-member-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['team-member']" value="never" :id="'tracker-' + tracker.id + '-team-member-never'" :name="'tracker-' + tracker.id + '-team-member'">
                                                                        <label :for="'tracker-' + tracker.id + '-team-member-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>{{ objectType === 'tracker-startups' ? 'Startup' : 'Ecosystem' }} reviewed</td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-reviewed']" value="now" :id="'tracker-' + tracker.id + '-startup-reviewed-now'" :name="'tracker-' + tracker.id + '-startup-reviewed'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-reviewed-now'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-reviewed']" value="day" :id="'tracker-' + tracker.id + '-startup-reviewed-day'" :name="'tracker-' + tracker.id + '-startup-reviewed'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-reviewed-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-reviewed']" value="week" :id="'tracker-' + tracker.id + '-startup-reviewed-week'" :name="'tracker-' + tracker.id + '-startup-reviewed'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-reviewed-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-reviewed']" value="never" :id="'tracker-' + tracker.id + '-startup-reviewed-never'" :name="'tracker-' + tracker.id + '-startup-reviewed'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-reviewed-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>News</td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['news']" value="day" :id="'tracker-' + tracker.id + '-news-day'" :name="'tracker-' + tracker.id + '-news'">
                                                                        <label :for="'tracker-' + tracker.id + '-news-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['news']" value="week" :id="'tracker-' + tracker.id + '-news-week'" :name="'tracker-' + tracker.id + '-news'">
                                                                        <label :for="'tracker-' + tracker.id + '-news-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['news']" value="never" :id="'tracker-' + tracker.id + '-news-never'" :name="'tracker-' + tracker.id + '-news'">
                                                                        <label :for="'tracker-' + tracker.id + '-news-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr v-if="objectType === 'tracker-startups'">
                                                                <td>New funding</td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['new-funding']" value="day" :id="'tracker-' + tracker.id + '-new-funding-day'" :name="'tracker-' + tracker.id + '-new-funding'">
                                                                        <label :for="'tracker-' + tracker.id + '-new-funding-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['new-funding']" value="week" :id="'tracker-' + tracker.id + '-new-funding-week'" :name="'tracker-' + tracker.id + '-new-funding'">
                                                                        <label :for="'tracker-' + tracker.id + '-new-funding-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications['new-funding']" value="never" :id="'tracker-' + tracker.id + '-new-funding-never'" :name="'tracker-' + tracker.id + '-new-funding'">
                                                                        <label :for="'tracker-' + tracker.id + '-new-funding-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>Notes / Interactions / Custom Fields updated</td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-interaction']" value="day" :id="'tracker-' + tracker.id + '-startup-interaction-day'" :name="'tracker-' + tracker.id + '-startup-interaction'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-interaction-day'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-interaction']" value="week" :id="'tracker-' + tracker.id + '-startup-interaction-week'" :name="'tracker-' + tracker.id + '-startup-interaction'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-interaction-week'"></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-radio input-radio--simple">
                                                                        <input type="radio" v-model="settings[objectType].specific[index].notifications[companyType(objectType)+'-interaction']" value="never" :id="'tracker-' + tracker.id + '-startup-interaction-never'" :name="'tracker-' + tracker.id + '-startup-interaction'">
                                                                        <label :for="'tracker-' + tracker.id + '-startup-interaction-never'"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </b-collapse>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-show="currentTab === 'searches'">
                    <div class="d-flex">
                        <div class="col-4">
                            <div v-if="false" class="d-flex">
                                <h6 class="heading6">Notifications:</h6>
                                <div class="input-radio ml-3">
                                    <input type="radio" value="1" id="n10" name="n00">
                                    <label for="n10">On</label>
                                </div>
                                <div class="input-radio ml-3">
                                    <input type="radio" value="1" id="n20" name="n00">
                                    <label for="n20">Off</label>
                                </div>
                            </div>

                            <div class="input-radio mb-3">
                                <input type="radio" v-model="settings['saved-search'].is_common" value="1" id="saved-search-common-1" name="saved-search-common">
                                <label for="saved-search-common-1">Use the same settings for all searches</label>
                            </div>
                            <div class="input-radio mb-3">
                                <input type="radio" v-model="settings['saved-search'].is_common" value="0" id="saved-search-common-0" name="saved-search-common">
                                <label for="saved-search-common-0">Use individual search settings</label>
                            </div>
                        </div>

                        <template>
                            <div class="accordion col-6" role="tablist">
                                <b-collapse id="accordion-saved-search" visible accordion="accordion-saved-search" role="tabpanel" class="accordion__content">
                                    <template v-if="parseInt(settings['saved-search'].is_common) === 1">
                                        <b-collapse id="accordion-saved-search-0" visible accordion="my-accordion-saved-search" role="tabpanel">
                                            <div class="card card--bordered">
                                            <table class="table-simple">
                                                <thead>
                                                    <tr>
                                                        <th>Event</th>
                                                        <th>Daily</th>
                                                        <th>Weekly</th>
                                                        <th>Never</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>New startup matches</td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['new-startup-matches']" value="day" :id="'saved-search-new-startup-matches-day'" :name="'saved-search-new-startup-matches'">
                                                                <label :for="'saved-search-new-startup-matches-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['new-startup-matches']" value="week" :id="'saved-search-new-startup-matches-week'" :name="'saved-search-new-startup-matches'">
                                                                <label :for="'saved-search-new-startup-matches-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['new-startup-matches']" value="never" :id="'saved-search-new-startup-matches-never'" :name="'saved-search-new-startup-matches'">
                                                                <label :for="'saved-search-new-startup-matches-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>News</td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['news']" value="day" :id="'saved-search-news-day'" :name="'saved-search-news'">
                                                                <label :for="'saved-search-news-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['news']" value="week" :id="'saved-search-news-week'" :name="'saved-search-news'">
                                                                <label :for="'saved-search-news-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['news']" value="never" :id="'saved-search-news-never'" :name="'saved-search-news'">
                                                                <label :for="'saved-search-news-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>New funding</td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['new-funding']" value="day" :id="'saved-search-new-funding-day'" :name="'saved-search-new-funding'">
                                                                <label :for="'saved-search-new-funding-day'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['new-funding']" value="week" :id="'saved-search-new-funding-week'" :name="'saved-search-new-funding'">
                                                                <label :for="'saved-search-new-funding-week'"></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="input-radio input-radio--simple">
                                                                <input type="radio" v-model="settings['saved-search'].common[0].notifications['new-funding']" value="never" :id="'saved-search-new-funding-never'" :name="'saved-search-new-funding'">
                                                                <label :for="'saved-search-new-funding-never'"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </b-collapse>
                                    </template>

                                    <template v-if="parseInt(settings['saved-search'].is_common) === 0">
                                        <div class="accordion mb-0" role="tablist1">

                                            <div v-for="(search, index) in settings['saved-search'].specific" :key="index">
                                                <header class="accordion__header">
                                                    <div v-b-toggle="'accordion-saved-search-' + search.id" variant="info" class="accordion__title accordion__title--small">
                                                        {{ search.name }}
                                                    </div>
                                                </header>

                                                <b-collapse :id="'accordion-saved-search-' + search.id" visible accordion="my-accordion-saved-search" role="tabpanel">
                                                    <div class="card card--bordered">
                                                        <table class="table-simple">
                                                            <thead>
                                                                <tr>
                                                                    <th>Event</th>
                                                                    <th>Daily</th>
                                                                    <th>Weekly</th>
                                                                    <th>Never</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                <tr>
                                                                    <td>New startup matches</td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['new-startup-matches']" value="day" :id="'saved-search-' + search.id + '-new-startup-matches-day'" :name="'saved-search-' + search.id + '-new-startup-matches'">
                                                                            <label :for="'saved-search-' + search.id + '-new-startup-matches-day'"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['new-startup-matches']" value="week" :id="'saved-search-' + search.id + '-new-startup-matches-week'" :name="'saved-search-' + search.id + '-new-startup-matches'">
                                                                            <label :for="'saved-search-' + search.id + '-new-startup-matches-week'"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['new-startup-matches']" value="never" :id="'saved-search-' + search.id + '-new-startup-matches-never'" :name="'saved-search-' + search.id + '-new-startup-matches'">
                                                                            <label :for="'saved-search-' + search.id + '-new-startup-matches-never'"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>News</td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['news']" value="day" :id="'saved-search-' + search.id + '-news-day'" :name="'saved-search-' + search.id + '-news'">
                                                                            <label :for="'saved-search-' + search.id + '-news-day'"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['news']" value="week" :id="'saved-search-' + search.id + '-news-week'" :name="'saved-search-' + search.id + '-news'">
                                                                            <label :for="'saved-search-' + search.id + '-news-week'"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['news']" value="never" :id="'saved-search-' + search.id + '-news-never'" :name="'saved-search-' + search.id + '-news'">
                                                                            <label :for="'saved-search-' + search.id + '-news-never'"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>New funding</td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['new-funding']" value="day" :id="'saved-search-' + search.id + '-new-funding-day'" :name="'saved-search-' + search.id + '-new-funding'">
                                                                            <label :for="'saved-search-' + search.id + '-new-funding-day'"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['new-funding']" value="week" :id="'saved-search-' + search.id + '-new-funding-week'" :name="'saved-search-' + search.id + '-new-funding'">
                                                                            <label :for="'saved-search-' + search.id + '-new-funding-week'"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="input-radio input-radio--simple">
                                                                            <input type="radio" v-model="settings['saved-search'].specific[index].notifications['new-funding']" value="never" :id="'saved-search-' + search.id + '-new-funding-never'" :name="'saved-search-' + search.id + '-new-funding'">
                                                                            <label :for="'saved-search-' + search.id + '-new-funding-never'"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                        </div>
                                    </template>
                                </b-collapse>
                            </div>
                        </template>
                    </div>
                </div>
                <div v-show="currentTab === 'messages'">
                    <template>
                        <b-collapse id="accordion-messages" visible accordion="accordion-messages" role="tabpanel" class="accordion__content">
                            <template v-if="parseInt(settings['messages'].is_common) === 1">
                                <b-collapse id="accordion-messages-0" visible accordion="my-accordion-messages" role="tabpanel">
                                    <div class="card card--bordered">
                                        <table class="table-simple">
                                            <thead>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Aggregated</th>
                                                    <th>Never</th>
                                                </tr>
                                            </thead>
    
                                            <tbody>
                                                <tr>
                                                    <td>New Message Received</td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings['messages'].common[0].notifications['new-messages-received']" value="now" :id="'messages-new-messages-received-now'" :name="'messages-new-messages-received-'">
                                                            <label :for="'messages-new-messages-received-now'"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-radio input-radio--simple">
                                                            <input type="radio" v-model="settings['messages'].common[0].notifications['new-messages-received']" value="never" :id="'messages-new-messages-received-never'" :name="'messages-new-messages-received-'">
                                                            <label :for="'messages-new-messages-received-never'"></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-collapse>
                            </template>
                        </b-collapse>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import user from "@/services/user.service";

export default {
    data() {
        return {
            settings: {
                'tracker-startups': {
                    'is_common': null,
                    'common': [],
                    'specific': [],
                },
                'tracker-ecosystems': {
                    'is_common': null,
                    'common': [],
                    'specific': [],
                },
                'saved-search': {
                    'is_common': null,
                    'common': [],
                    'specific': [],
                },
                'messages': {
                    'is_common': null,
                    'common': [],
                },
            },

            canWatch: false,
            updateSettings: false,
            currentTab: this.$route.query.tab ? this.$route.query.tab : "startup-trackers",
            currentTrackerId: this.$route.query.trackerId ? parseInt(this.$route.query.trackerId) : null,
        };
    },

    watch: {
        settings: {
            deep: true,
            handler() {
                if (this.canWatch) {
                    user.updateNotificationSettings({settings: this.settings})
                }
            }
        }
    },

    mounted() {
        user.getNotificationSettings().then(result => {
            this.settings = result.data;

            this.$nextTick(() => {
                this.canWatch = true;
                this.openCurrentObject();
            })
        });
    },

    methods: {
        companyType(objectType) {
            return objectType === 'tracker-startups' ? 'startup' : 'ecosystem'
        },

        openCurrentObject() {
        },
    },
}
</script>

<style scoped>

</style>
