import {reactive, watch} from "vue";

export default function (getListMethod, pageLength = 25) {
    const gridParams = reactive({
        columns: [],
        currentPage: 1,
        pageLength: pageLength,
        totalRowsCount: 0,
        pagesCount: 0,
        filters: {},
        sort: {
            field: '',
            dir: '',
        },
        selectedRows: [],
        selectedAllResults: false,
    })

    watch(() => gridParams.pageLength, () => {
        gridParams.pagesCount = Math.ceil(gridParams.totalRowsCount / gridParams.pageLength)

        if (gridParams.currentPage > gridParams.pagesCount) {
            gridParams.currentPage = gridParams.pagesCount
        }

        getListMethod();
    });

    watch(() => gridParams.totalRowsCount, () => {
        gridParams.pagesCount = Math.ceil(gridParams.totalRowsCount / gridParams.pageLength);
    });

    function getCommonRequestParams(includeFilters = true) {
        let params = {
            columns: gridParams.columns.map(column => column.name),
            sort: gridParams.sort,
            currentPage: gridParams.currentPage,
            pageLength: gridParams.pageLength,
        };

        if (includeFilters) {
            params.filters = gridParams.filters;
        }

        return params;
    }

    function updateList() {
        getListMethod()
    }

    function changePage(page) {
        gridParams.currentPage = page
        updateList()
    }

    function changeSort(sort) {
        gridParams.sort = sort
        updateList()
    }

    function changeSelectedRows(ids) {
        gridParams.selectedRows = ids
    }

    function selectAllResults(value) {
        gridParams.selectedAllResults = value
    }

    const gridMethods = {
        getCommonRequestParams,
        changePage,
        changeSort,
        changeSelectedRows,
        selectAllResults,
    }

    return {
        gridParams,
        gridMethods
    }
}
