<script setup>
import Multiselect from "vue-multiselect";
import {computed, inject, onMounted, ref} from "vue";
import teamService from "@/services/team.service";
import {useStore} from "vue2-helpers/vuex";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import Avatar from "vue-avatar";

const store = useStore();

const tracker = inject('tracker');
const trackerId = inject('trackerId');
const showManagePermissionsModal = ref(false);
const roleDropdown = ref(null);

const newPermission = ref({
    holder: null,
    role: null,
});

const permissionHolders = ref([]);

const canManageRoles = computed(() => tracker.userPermissions.includes('tracker-permissions-edit'));
const roles = computed(() => store.getters.roles);

const actualPermissionHolders = computed(
    () => permissionHolders.value.filter(item =>
        !tracker.permissions.some(permission => permission.id === item.id && permission.type === item.type)
    )
);

onMounted(async () => {
    if (store.getters.isEnterprise) {
        await store.dispatch('fetchRoles');
        newPermission.value.role = roles.value[0];
    }

    const {data} = await teamService.getPermissionHolders();
    permissionHolders.value = data;

    if (!trackerId.value && !tracker.permissions.length && roles.value.length) {
        addDefaultRoles();
    }
});

function addDefaultRoles() {
    tracker.permissions.push(
        {
            type: 'user',
            id: store.state.user.id,
            name: store.state.user.name,
            role: roles.value[0],
            avatar: store.state.user.avatar,
        }
    )

    let defaultRole = roles.value.find(item => item.is_default === 1);
    let group = permissionHolders.value.find(item => parseInt(item.id) === -1);

    if (defaultRole && group) {
        tracker.permissions.push(
            {
                ...group,
                role: defaultRole
            }
        );
    }
}

function addPermission() {
    tracker.permissions.push(
        {
            type: newPermission.value.holder.type,
            id: newPermission.value.holder.id,
            name: newPermission.value.holder.name,
            role: newPermission.value.role,
            avatar: newPermission.value.holder.avatar,
        }
    )

    newPermission.value.holder = null
}
</script>

<template>
    <div>
        <b-row>
            <b-col>
                <h5 class="heading5 heading5--secondary">Permissions</h5>
            </b-col>

            <b-col class="text-right">
                <template v-if="canManageRoles">
                    <a @click="showManagePermissionsModal = true" class="link text-nowrap">Manage Permissions</a>

                    <b-modal v-model="showManagePermissionsModal" modal-class="managePermissions modal-w-md" title="Manage Permissions">
                        <ul class="rows-list mb-3">
                            <li class="rows-list__form rows-list__form--transparent flex-sm-nowrap flex-wrap">

                                <Multiselect class="multiselect-mid mb-sm-0 mb-4"
                                             v-model="newPermission.holder"
                                             :options="actualPermissionHolders"
                                             placeholder="Select User"
                                             select-label=""
                                             deselect-label=""
                                             label="name"
                                             track-by="name"
                                >
                                    <template slot="option" slot-scope="props">
                                        <template v-if="props.option.type === 'group'">
                                            <div class="d-flex align-items-center">
                                                <div class="mr-2">
                                                    <Avatar v-if="props.option.avatar"
                                                            :username="props.option.name"
                                                            :src="props.option.avatar"
                                                            :size="30">
                                                    </Avatar>
                                                    <font-awesome-icon v-else :icon="['fa', 'users']" size="lg"  />
                                                </div>
                                                {{ props.option.name }}
                                            </div>
                                        </template>

                                        <template v-else>
                                            <div class="d-flex align-items-center">
                                                <div class="mr-2">
                                                    <Avatar
                                                        :username="props.option.name"
                                                        :src="props.option.avatar"
                                                        :size="30">
                                                    </Avatar>
                                                </div>
                                                {{ props.option.name }}
                                            </div>
                                        </template>
                                    </template>
                                </Multiselect>

                                <template v-if="roles.length">
                                    <span class="text-nowrap ml-sm-4">Add as</span>

                                    <b-dropdown
                                        ref="roleDropdown"
                                        class="dropdown--tertiary"
                                        variant="link"
                                        toggle-class="text-decoration-none"
                                        no-caret
                                    >
                                        <template #button-content>
                                            {{ newPermission.role.name }}
                                        </template>

                                        <b-dropdown-header>
                                            <ul class="modal-list">
                                                <li v-for="role in roles" :key="role.id" @click="newPermission.role = role; roleDropdown.hide()">
                                                    <h5 class="heading5 heading5--secondary">{{ role.name }}</h5>
                                                    <p>{{ role.description }}</p>
                                                </li>
                                            </ul>
                                        </b-dropdown-header>
                                    </b-dropdown>
                                </template>

                                <b-button @click="addPermission" variant="primary" size="md" :disabled="!newPermission.holder">Add</b-button>
                            </li>

                            <li v-for="(permission, index) in tracker.permissions" :key="index">

                                <div class="image-block image-block--rounded">
                                    <Avatar
                                        :username="permission.name"
                                        :src="permission.avatar"
                                        :size="30">
                                    </Avatar>
                                </div>

                                <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                                <b-dropdown v-if="roles.length" class="dropdown--tertiary modal-list" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template #button-content>
                                        <h5 class="heading5 heading5--secondary text-capitalize">{{permission.role.name}}</h5>
                                    </template>

                                    <b-dropdown-item v-for="(role, index) in roles" :key="index" @click="permission.role = role">
                                        <h5 class="heading5 heading5--secondary">{{role.name}}</h5>
                                        <p>{{role.description}}</p>
                                    </b-dropdown-item>
                                </b-dropdown>

                                <a @click="tracker.permissions.splice(index, 1)" class="remove-action" href="#">Remove</a>
                            </li>
                        </ul>

                        <template #modal-footer>
                            <PrimaryButton
                                caption="Save"
                                class="ml-3"
                                @click="showManagePermissionsModal = false"
                            />
                        </template>
                    </b-modal>
                </template>
            </b-col>
        </b-row>

        <ul class="rows-list rows-list--summary">
            <li v-for="(permission, index) in tracker.permissions" :key="index">

                <div class="image-block image-block--rounded">
                    <Avatar
                        :username="permission.name"
                        :src="permission.avatar"
                        :size="30"
                    />
                </div>

                <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>
                <h5 class="heading5 heading5--secondary text-capitalize" :id="'permission'+permission.id">
                    {{permission.role.name}}
                    <b-tooltip :target="'permission'+permission.id" placement="right" variant="light" :title="permission.role.description"></b-tooltip>
                </h5>


                <a v-if="canManageRoles" @click="tracker.permissions.splice(index, 1)" class="link-remove link" href="#"></a>
            </li>
        </ul>
    </div>
</template>

<style scoped>

</style>
