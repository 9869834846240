<script setup>
import {useStore} from "vue2-helpers/vuex";
import {ref, computed, inject, watch} from "vue";
import Avatar from "vue-avatar";
import VClamp from "vue-clamp";
import logoHelper from "@/helpers/logo";
import date from "@/helpers/date";
import ContactsModal from "@/components/contacts/ContactsModal";
import SelectCategories from "@/components/manage/SelectCategories.vue";
import SelectLocationModal from "@/components/manage/SelectLocation";
import startupsService from "@/services/startups.service";
import EditContact from "@/components/contacts/EditContact.vue";
import EcosystemsListModal from "@/components/ecosystems/EcosystemsListModal.vue";
import AddTags from "@/components/manage/AddTags.vue";
import accountTypes, {ecosystemTypes} from "@/constants/accountTypes";

const {commonData, mobileVisible} = defineProps(['commonData', 'mobileVisible']);
const emit = defineEmits(['reportIncorrectData','showFundingRounds']);

const getContactsForSidePanel = inject('getContactsForSidePanel');
const companyId = inject('companyId');
const companyType = inject('companyType');
const addCategoriesMethod = inject('addCategories', null);
const getTagsMethod = inject('getTags', null);
const addTagsMethod = inject('addTags', null);
const removeTagMethod = inject('removeTag', null);

const store = useStore();

const contacts = ref([]);
const contactsCount = ref(0);
const ecosystems = commonData.ecosystems;
const categories = ref(commonData.categories);
const tags = ref([]);
const displayedEcosystemsCount = ref(5);
const contactsModal = ref(null);
const selectCategoriesModal = ref(null);
const addLocationModal = ref(null);
const editContactModal = ref(null);
const ecosystemsListModal = ref(null);
const addTagsModal = ref(null);
const location = ref(commonData.location);

const isStartup = companyType === accountTypes.STARTUP;
const isEcosystem = companyType === accountTypes.ECOSYSTEM;
const tagsToAdd = computed(() => store.getters.tagsList.filter(tag => !tags.value.some(addedTag => addedTag.id === tag.id)));
const locationString = computed(() => [location.value?.city, location.value?.state, location.value?.country, location.value?.continent].filter(Boolean).join(', '));
const revenueRange = computed(() => commonData.revenue_range.replace('Less than', '<').replace('Greater than', '>'));
const canAddCategories = computed(() => isStartup && store.getters.isEnterprise && commonData.crunch_status === 2 && commonData.diffbot_status !== 1);

const mainContact = computed(() =>
        contacts.value.find(contact => contact.is_primary)
        || contacts.value.find(contact => contact.job_title === 'Founder')
        || contacts.value.find(contact => contact.job_title === 'President')
        || contacts.value.find(contact => contact.job_title === 'CEO')
        || (contacts.value.find(contact => contact.job_title?.includes('Founder') || contact.job_title?.includes('CEO') || contact.job_title?.includes('President')))
        || null
);

const infoUpdated = computed(() => commonData.last_crunchbase_sync_date ? `Updated ${date.decodeDate(commonData.last_crunchbase_sync_date)}` : '');

const providedBy = computed(() => {
    let string = "Data provided by ";

    if (commonData.provided_by === 'genai') {
        string += "Gen AI";
    } else if (commonData.provided_by === 'crunchbase') {
        string += "Crunchbase";
    } else if (commonData.provided_by === 'community') {
        string += "SwitchPitch community members";
    } else {
        string += "SwitchPitch";
    }

    return string;
});

watch(() => store.getters.isEnterprise, (value) => {
    if (value && isStartup) {
        getTags();
        store.dispatch('fetchTags');
    }
}, {immediate: true});


watch(() => commonData.contacts, (value) => {
    contacts.value = value;
    contactsCount.value = commonData.contacts_count;
}, {immediate: true});

async function addCategories(addedCategories) {
    const {data} = await addCategoriesMethod(addedCategories.map(item =>item.id));
    categories.value = data;
}

async function setLocation(newLocation) {
    const {data} = await startupsService.setLocation(companyId, {location: newLocation});
    location.value = data;
}

async function getTags() {
    const {data} = await getTagsMethod();
    tags.value = data;
}

async function addTags(addedTags) {
    await addTagsMethod(addedTags.map(item => item.id));
    emit('getLatestActivities');
    addTagsModal.value.close();
    await getTags();
}

async function removeTag(id) {
    if (confirm('Remove tag?')) {
        await removeTagMethod(id);
        emit('getLatestActivities');
        await getTags();
    }
}

async function getContacts() {
    const {data} = await getContactsForSidePanel();
    [contacts.value, contactsCount.value] = data;
}

function addContact() {
    editContactModal.value.open(0,
        companyType === accountTypes.STARTUP ? companyId : 0,
        companyType === accountTypes.ECOSYSTEM ? companyId : 0
    )
}
</script>

<template>
    <aside
        class="aside"
        :class="{'mobile-visible': mobileVisible}"
    >
        <div class="aside__card">
            <header class="aside__header">
                Overview

                <span class="icon-score" v-if="store.getters.isPremium && commonData.irl_score" :class="'icon-score--' + commonData.irl_score">
                    IRL Score: {{commonData.irl_score}}
                </span>
            </header>

            <div v-if="isEcosystem && commonData.type" class="ecosystem-panel-top">
                <span class="ecosystem-label">
                    {{ecosystemTypes[commonData.type]}}
                </span>
            </div>

            <div class="aside__content">
                <slot name="status"/>

                <p v-if="commonData.op_status === 'closed'" class="label-text label-text--inactive">This company is inactive</p>

    <!--            Main block-->
                <ul class="overview-list">
                    <li v-if="commonData.website.length">
                        <a :href="commonData.website" class="d-flex align-items-center" target="_blank">
                            <span class="overview-list__icon icon-globe"></span>
                            {{ commonData.url_domain }}
                        </a>
                    </li>

                    <li v-if="commonData.total_raised > 0">
                        <a
                            v-if="commonData.funding_rounds_count"
                            class="d-flex align-items-center"
                            @click="emit('showFundingRounds')"
                        >
                            <span class="overview-list__icon icon-funding icon-funding--light"></span>
                            {{stringHelper.formatNumber(commonData.total_raised)}} raised

                            <a class="link link--view" href="#"></a>
                        </a>

                        <span v-else class="d-flex align-items-center">
                            <span class="overview-list__icon icon-funding icon-funding--light"></span>
                            {{stringHelper.formatNumber(commonData.total_raised)}} raised

                            <a class="link link--view" href="#"></a>
                        </span>
                    </li>

                    <li v-if="commonData.revenue_range">
                        <span class="overview-list__icon icon-income icon-income--light"></span>
                        {{ revenueRange }} Annual Income
                    </li>

                    <li v-if="commonData.employees">
                        <span class="overview-list__icon icon-employees--light"></span>
                        {{ commonData.employees }} Employees
                    </li>
                    <li v-if="locationString" :class="{'embargoed-location': commonData.embargoed_country}">

                    <li v-if="locationString">
                        <span class="overview-list__icon icon-location--light"></span>
                        {{ locationString }}
                    </li>
                    <li v-if="commonData.embargoed_country">
                        <div class="embargoed-banner">
                            RTX Embargoed Country
                        </div>
                    </li>

                    <li v-else-if="isStartup && commonData.crunch_status === 2 && commonData.diffbot_status !== 1">
                        <a class="link" @click="addLocationModal.open()">+ Add Location</a>
                    </li>

                    <li v-if="commonData.stage">
                        <span class="overview-list__icon icon-startups icon-startups--light"></span>
                        {{ commonData.stage }}
                    </li>

                    <li v-if="commonData.founded">
                        <span class="overview-list__icon icon-founded icon-founded--light"></span>
                        Founded {{ commonData.founded }}
                    </li>
                </ul>

    <!--            Socials-->
                <ul v-if="commonData.linkedin_url || commonData.twitter_url || commonData.facebook_url" class="social-list">
                    <li v-if="commonData.linkedin_url">
                        <a target="_blank" :href="commonData.linkedin_url">
                            <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path fill="#0083b2" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                        </a>
                    </li>

                    <li v-if="commonData.twitter_url">
                        <a target="_blank" :href="commonData.twitter_url">
                            <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512">
                                <path fill="#0083b2" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
                            </svg>
                        </a>
                    </li>

                    <li v-if="commonData.facebook_url">
                        <a target="_blank" :href="commonData.facebook_url">
                            <font-awesome-icon :icon="['fab', 'facebook-square']" />
                        </a>
                    </li>
                </ul>

                <div
                    v-if="mainContact"
                    class="card-ceo"
                    @click="editContactModal.open(mainContact.id)"
                >
                    <div class="image-block image-block--rounded image-block--rounded--idk d-block">
                        <Avatar
                            :username="mainContact.name"
                            :src="mainContact.avatar"
                            :size="41"
                        />
                    </div>

                    <div>
                        <h5 class="heading5 mb-0">{{ mainContact.name }}</h5>
                        <p>{{ mainContact.job_title }}</p>
                    </div>

                    <a class="card-ceo__open"></a>
                </div>

    <!--            Taxonomy-->
                <div class="taxonomy-section" v-if="(isStartup && store.getters.isEnterprise) || categories.length || canAddCategories">
                    <h4 class="heading4 heading4--tertiary">Taxonomy</h4>

                    <h5
                        v-if="categories.length || canAddCategories"
                        class="heading5 heading5--secondary d-flex"
                    >
                        Categories

                        <a
                            v-if="canAddCategories"
                            class="link link--add ml-auto"
                            @click="selectCategoriesModal.open()"
                        >
                            Add
                        </a>
                    </h5>

                    <div class="mb-3">
                        <div v-if="categories.length" class="tags-list tags-list--light mb-1">
                            <span v-for="category in categories" :key="category.id" class="tags-list__tag" :title="category.name">{{ category.name }}</span>
                        </div>
                    </div>

                    <template v-if="isStartup && store.getters.isEnterprise">
                        <h5 class="heading5 heading5--secondary d-flex">
                            Tags

                            <a
                                v-if="tagsToAdd.length"
                                class="link link--add ml-auto"
                                @click="addTagsModal.open()"
                            >
                                Add
                            </a>
                        </h5>

                        <div class="mb-3">
                            <div v-if="tags.length" class="tags-list tags-list--light mb-1">
                                <span
                                    v-for="tag in tags"
                                    :key="tag.id"
                                    class="tags-list__tag"
                                    :title="tag.name"
                                >
                                    <a
                                        :id="'tag-' + tag.id"
                                        :href="'/startups?tag=' + tag.name"
                                        target="_blank"
                                    >
                                        {{ tag.name }}
                                    </a>

                                    <BTooltip
                                        :target="'tag-' + tag.id"
                                        placement="right"
                                        variant="light"
                                        :title="tag.description"
                                    />

                                    <a class="tags-list__tag__close" style="width: 16px !important;" @click="removeTag(tag.id)"></a>
                                </span>
                            </div>
                        </div>

                        <div v-if="commonData.keywords_with_count.length" class="accordion">
                            <header class="accordion__header" v-b-toggle.accordion-1 >
                                <h5 class="accordion__title heading5 heading5--secondary mb-0">Keywords</h5>
                            </header>

                            <b-collapse id="accordion-1" accordion="my-accordion" >
                                <div class="tags-list tags-list--light">
                                    <a
                                        v-for="keyword in commonData.keywords_with_count" :key="keyword.keyword"
                                        :href="'/startups?keywords=' + keyword.keyword"
                                        class="tags-list__tag"
                                        target="_blank"
                                    >
                                        <v-clamp autoresize :max-lines="1">{{ keyword.keyword }} ({{keyword.count}})</v-clamp>
                                    </a>
                                </div>
                            </b-collapse>
                        </div>
                    </template>
                </div>
            </div>
        </div>

<!--            Team-->
        <div class="aside__card">
            <header class="aside__header">
                Team
                <a class="aside__header__link" @click="addContact">Add Contact</a>

                <EditContact
                    ref="editContactModal"
                    @contactSaved="getContacts"
                />
            </header>

            <div class="aside__content aside__content--team-section">
                <ul v-if="contacts.length" class="aside__list aside__list__team">
                    <li
                        v-for="contact in contacts"
                        :key="contact.id"
                        @click="editContactModal.open(contact.id)"
                    >
                        <div>
                            <Avatar
                                :username="contact.name"
                                :src="contact.avatar"
                                :size="40"
                            />
                        </div>

                        <div>
                            <h5 class="heading5">{{ contact.name }}</h5>
                            <p>{{ contact.job_title }}</p>
                        </div>
                    </li>

                    <li v-if="contactsCount > contacts.length" class="aside__list__more">
                        <a @click="contactsModal.open()">+{{contactsCount - contacts.length}} more </a>
                    </li>
                </ul>

                <div v-else class="no-contacts-block">
                    <div>
                        No Team Members
                    </div>

                    <div>
                        <img src="../../images/core-user.svg">
                    </div>

                    <div>
                        <a class="link link--add" @click="addContact">Add Contact</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="aside__card" v-if="isStartup && ecosystems?.length">
            <header class="aside__header">
                Ecosystems
            </header>

            <div class="aside__content">
                <ul class="aside__list">
                    <li v-for="ecosystem in ecosystems.slice(0, displayedEcosystemsCount)" :key="ecosystem.id">
                        <div class="image-block">
                            <img :src="logoHelper.getLogo(ecosystem.logo_url)" alt=""/>
                        </div>
                        <div>
                            <a v-if="ecosystem.visible" :href="'/ecosystems/' + ecosystem.id" target="_blank">{{ ecosystem.name }}</a>
                            <span v-else>{{ ecosystem.name }}</span>
                        </div>
                    </li>

                    <li class="aside__list__more" v-if="displayedEcosystemsCount < ecosystems.length">
                        <a @click="ecosystemsListModal.open()">
                            + {{ecosystems.length - displayedEcosystemsCount}} more
                        </a>
                    </li>
                </ul>
            </div>

            <EcosystemsListModal ref="ecosystemsListModal"/>
        </div>


        <footer class="aside__footer">
            <p>
                {{providedBy}}

                <template v-if="commonData.has_gen_ai_fields">
                    and Gen AI
                </template>
            </p>

            <template v-if="infoUpdated">
                <p class="mb-4"><b>{{infoUpdated}}</b></p>
            </template>

            <p>Data incorrect? <a class="link link--normal" @click="emit('reportIncorrectData')">Contact us</a></p>
        </footer>

        <ContactsModal
            ref="contactsModal"
            @updated="getContacts"
        />

        <SelectCategories
            ref="selectCategoriesModal"
            :exclude-categories="categories.map(item => item.id)"
            @categoriesSelected="addCategories"
        />

        <AddTags
            ref="addTagsModal"
            :tags-list="tagsToAdd"
            @addTags="addTags"
        />

        <SelectLocationModal
            ref="addLocationModal"
            @locationSelected="setLocation"
        />

        <EditContact
            ref="editContactModal"
            @contactSaved="getContacts"
        />
    </aside>
</template>

<style scoped>
    .no-contacts-block {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &>div {
            margin: 15px 0;
        }

        > img {
            width: 24px;
            height: 24px;
        }
    }
</style>
