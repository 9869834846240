import EcosystemsSearch from "@/views/ecosystems/Search"
import MyEcosystems from '@/views/ecosystems/MyEcosystems'
import Ecosystem from '@/views/profile/EcosystemProfile.vue'
import EcosystemOld from '@/views/ecosystems/Ecosystem'
import Portfolio from '@/views/Portfolio'

export default [
    {
        path: '/ecosystems',
        component: EcosystemsSearch,
        meta: {
            access: ['enterprise'],
            title: 'Ecosystems',
            accountAccess: ['premium'],
        }
    },
    {
        path: '/ecosystems/my-ecosystems',
        component: MyEcosystems,
        meta: {
            access: ['enterprise'],
            title: 'Ecosystems'
        }
    },
    {
        path: '/ecosystems/:id',
        component: Ecosystem,
        meta: {
            access: ['enterprise']
        }
    },
    {
        path: '/ecosystems_old/:id',
        component: EcosystemOld,
        meta: {
            access: ['enterprise']
        }
    },
    {
        path: '/portfolios/:id',
        component: Portfolio,
        meta: {
            access: ['enterprise']
        }
    },
]
