<script setup>
import {onMounted, ref} from 'vue';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import pulseLoader from 'vue-spinner/src/PulseLoader.vue';
import startupsService from "@/services/startups.service";

const route = useRoute();
const router = useRouter();
const domain = route.query.domain;
const store = useStore();
const loading = ref(true);
const notFound = ref(false);

onMounted(async () => {
    if (store.getters.isLoggedIn) {
        const {data: id} = await startupsService.findByDomain(domain);

        if (id) {
            router.push('/startups/' + id);
        } else {
            notFound.value = true;
        }

        loading.value = false;
    } else {
        if (route.query.sso) {
            window.location.href=`${process.env.VUE_APP_API_ENDPOINT}/${route.query.sso}/login?returnTo=/startups/find-by-domain?domain=${domain}`;
        } else {
            store.state.pageNotFound = true;
        }
    }

});

function addStartup() {
    store.state.addStartupDomain = domain;
    router.push('/startups/my-startups');
}

</script>

<template>
    <div :class="{'page-404': notFound}">
        <pulse-loader v-if="loading" :loading="loading" :color="constants.spinnerColor"></pulse-loader>

        <template v-if="notFound">
            <h1 class="heading1">Startup with this domain not found.</h1>

            <p>Try to find it in <router-link to="/startups">search</router-link> or <a href="javascript:void(0)" @click="addStartup">add new profile</a></p>
        </template>
    </div>
</template>

<style scoped>
    .v-spinner {
        text-align: center;
    }
</style>
